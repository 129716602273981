import {
    GET_OFFERS_LOADING,
    GET_OFFERS_FULFILLED,
    GET_OFFERS_FAILED,

    CREATE_OFFER_LOADING,
    CREATE_OFFER_FULFILLED,
    CREATE_OFFER_FAILED,
    CREATE_OFFER_RESET,

    UPDATE_OFFER_LOADING,
    UPDATE_OFFER_FULFILLED,
    UPDATE_OFFER_FAILED,
    UPDATE_OFFER_RESET,

    ARCHIVE_OFFER_LOADING,
    ARCHIVE_OFFER_FULFILLED,
    ARCHIVE_OFFER_FAILED,
    ARCHIVE_OFFER_RESET,

    GET_OFFER_DETAILS_LOADING,
    GET_OFFER_DETAILS_FULFILLED,
    GET_OFFER_DETAILS_FAILED
} from '../types';

const initialState = {
    getOffersLoading: false,
    getOffersFulfilled: false,
    getOffersFailed: false,
    getOffersResponse: {},

    createOfferLoading: false,
    createOfferFulfilled: false,
    createOfferFailed: false,
    createOfferResponse: {},

    updateOfferLoading: false,
    updateOfferFulfilled: false,
    updateOfferFailed: false,
    updateOfferResponse: {},

    archiveOfferLoading: false,
    archiveOfferFulfilled: false,
    archiveOfferFailed: false,
    archiveOfferResponse: {},

    getOfferDetailsLoading: false,
    getOfferDetailsFulfilled: false,
    getOfferDetailsFailed: false,
    getOfferDetailsResponse: {}
};

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_OFFERS_LOADING:
        return {
            ...state,
            getOffersLoading: true,
            getOffersFulfilled: false,
            getOffersFailed: false,
        };

    case GET_OFFERS_FULFILLED: {
        const tempGetOffersResponse = {...state.getOffersResponse};
        tempGetOffersResponse.offset = action?.payload?.offset;
        tempGetOffersResponse.total = action?.payload?.total;
        tempGetOffersResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetOffersResponse.list = (tempGetOffersResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetOffersResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getOffersLoading: false,
            getOffersFulfilled: true,
            getOffersFailed: false,
            getOffersResponse: tempGetOffersResponse,
        };
    }

    case GET_OFFERS_FAILED:
        return {
            ...state,
            getOffersLoading: false,
            getOffersFulfilled: false,
            getOffersFailed: true,
        };


    case CREATE_OFFER_LOADING:
        return {
            ...state,
            createOfferLoading: true,
            createOfferFulfilled: false,
            createOfferFailed: false,
        };

    case CREATE_OFFER_FULFILLED:
        return {
            ...state,
            createOfferLoading: false,
            createOfferFulfilled: true,
            createOfferFailed: false,
            createOfferResponse: action?.payload,
        };

    case CREATE_OFFER_FAILED:
        return {
            ...state,
            createOfferLoading: false,
            createOfferFulfilled: false,
            createOfferFailed: true,
            createOfferResponse: action?.payload,
        };

    case CREATE_OFFER_RESET:
        return {
            ...state,
            createOfferLoading: initialState.createOfferLoading,
            createOfferFulfilled: initialState.createOfferFulfilled,
            createOfferFailed: initialState.createOfferFailed,
            createOfferResponse: initialState.createOfferResponse
        };


    case ARCHIVE_OFFER_LOADING:
        return {
            ...state,
            archiveOfferLoading: true,
            archiveOfferFulfilled: false,
            archiveOfferFailed: false,
        };

    case ARCHIVE_OFFER_FULFILLED: {
        const tempGetOffersResponse = {...state.getOffersResponse};
        tempGetOffersResponse.list = tempGetOffersResponse.list || [];
        const archivedOfferIndex = tempGetOffersResponse.list.findIndex(activity => (activity?._id===action?.payload?._id));
        if (archivedOfferIndex>=0) {
            tempGetOffersResponse.list.splice(archivedOfferIndex, 1);
        }

        return {
            ...state,
            archiveOfferLoading: false,
            archiveOfferFulfilled: true,
            archiveOfferFailed: false,
            archiveOfferResponse: action?.payload,
            getOffersResponse: tempGetOffersResponse
        };
    }

    case ARCHIVE_OFFER_FAILED:
        return {
            ...state,
            archiveOfferLoading: false,
            archiveOfferFulfilled: false,
            archiveOfferFailed: true,
            archiveOfferResponse: action?.payload,
        };

    case ARCHIVE_OFFER_RESET:
        return {
            ...state,
            archiveOfferLoading: initialState.archiveOfferLoading,
            archiveOfferFulfilled: initialState.archiveOfferFulfilled,
            archiveOfferFailed: initialState.archiveOfferFailed,
            archiveOfferResponse: initialState.archiveOfferResponse
        };


    case UPDATE_OFFER_LOADING:
        return {
            ...state,
            updateOfferLoading: true,
            updateOfferFulfilled: false,
            updateOfferFailed: false,
        };

    case UPDATE_OFFER_FULFILLED:
        return {
            ...state,
            updateOfferLoading: false,
            updateOfferFulfilled: true,
            updateOfferFailed: false,
            updateOfferResponse: action?.payload,
        };

    case UPDATE_OFFER_FAILED:
        return {
            ...state,
            updateOfferLoading: false,
            updateOfferFulfilled: false,
            updateOfferFailed: true,
            updateOfferResponse: action?.payload,
        };

    case UPDATE_OFFER_RESET:
        return {
            ...state,
            updateOfferLoading: initialState.updateOfferLoading,
            updateOfferFulfilled: initialState.updateOfferFulfilled,
            updateOfferFailed: initialState.updateOfferFailed,
            updateOfferResponse: initialState.updateOfferResponse
        };


    case GET_OFFER_DETAILS_LOADING:
        return {
            ...state,
            getOfferDetailsLoading: true,
            getOfferDetailsFulfilled: false,
            getOfferDetailsFailed: false,
        };

    case GET_OFFER_DETAILS_FULFILLED: {
        const tempGetOfferDetailsResponse = state.getOfferDetailsResponse || {};
        const offerId = action?.payload?._id;
        if (offerId) {
            tempGetOfferDetailsResponse[offerId] = action?.payload;
        }

        return {
            ...state,
            getOfferDetailsLoading: false,
            getOfferDetailsFulfilled: true,
            getOfferDetailsFailed: false,
            getOfferDetailsResponse: tempGetOfferDetailsResponse,
        };
    }

    case GET_OFFER_DETAILS_FAILED:
        return {
            ...state,
            getOfferDetailsLoading: false,
            getOfferDetailsFulfilled: false,
            getOfferDetailsFailed: true,
        };


    default:
        return state;
    }
};

export default activityReducer;
