import {
    ARCHIVE_SWITCH_FAILED,
    ARCHIVE_SWITCH_FULFILLED,
    ARCHIVE_SWITCH_LOADING,
    ARCHIVE_SWITCH_RESET,
    CREATE_SWITCH_FAILED,
    CREATE_SWITCH_FULFILLED,
    CREATE_SWITCH_LOADING,
    CREATE_SWITCH_RESET,
    GET_SWITCH_DETAILS_FAILED,
    GET_SWITCH_DETAILS_FULFILLED,
    GET_SWITCH_DETAILS_LOADING,
    GET_SWITCHES_FAILED,
    GET_SWITCHES_FULFILLED,
    GET_SWITCHES_LOADING,
    UPDATE_SWITCH_FAILED,
    UPDATE_SWITCH_FULFILLED,
    UPDATE_SWITCH_LOADING,
    UPDATE_SWITCH_RESET
} from '../types';

const initialState = {
    getSwitchesLoading: false,
    getSwitchesFulfilled: false,
    getSwitchesFailed: false,
    getSwitchesResponse: {},

    createSwitchLoading: false,
    createSwitchFulfilled: false,
    createSwitchFailed: false,
    createSwitchResponse: {},

    updateSwitchLoading: false,
    updateSwitchFulfilled: false,
    updateSwitchFailed: false,
    updateSwitchResponse: {},

    archiveSwitchLoading: false,
    archiveSwitchFulfilled: false,
    archiveSwitchFailed: false,
    archiveSwitchResponse: {},

    getSwitchDetailsLoading: false,
    getSwitchDetailsFulfilled: false,
    getSwitchDetailsFailed: false,
    getSwitchDetailsResponse: {}
};

const switchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SWITCHES_LOADING:
            return {
                ...state,
                getSwitchesLoading: true,
                getSwitchesFulfilled: false,
                getSwitchesFailed: false,
            };

        case GET_SWITCHES_FULFILLED:
            const tempGetSwitchesResponse = {...state.getSwitchesResponse};
            tempGetSwitchesResponse.offset = action?.payload?.offset;
            tempGetSwitchesResponse.total = action?.payload?.total;
            tempGetSwitchesResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetSwitchesResponse.list = (tempGetSwitchesResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetSwitchesResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getSwitchesLoading: false,
                getSwitchesFulfilled: true,
                getSwitchesFailed: false,
                getSwitchesResponse: tempGetSwitchesResponse,
            };

        case GET_SWITCHES_FAILED:
            return {
                ...state,
                getSwitchesLoading: false,
                getSwitchesFulfilled: false,
                getSwitchesFailed: true,
            };


        case CREATE_SWITCH_LOADING:
            return {
                ...state,
                createSwitchLoading: true,
                createSwitchFulfilled: false,
                createSwitchFailed: false,
            };

        case CREATE_SWITCH_FULFILLED:
            return {
                ...state,
                createSwitchLoading: false,
                createSwitchFulfilled: true,
                createSwitchFailed: false,
                createSwitchResponse: action?.payload,
            };

        case CREATE_SWITCH_FAILED:
            return {
                ...state,
                createSwitchLoading: false,
                createSwitchFulfilled: false,
                createSwitchFailed: true,
                createSwitchResponse: action?.payload,
            };

        case CREATE_SWITCH_RESET:
            return {
                ...state,
                createSwitchLoading: false,
                createSwitchFulfilled: false,
                createSwitchFailed: false,
                createSwitchResponse: {},
            };
        case ARCHIVE_SWITCH_LOADING:
            return {
                ...state,
                archiveSwitchLoading: true,
                archiveSwitchFulfilled: false,
                archiveSwitchFailed: false,
            };

        case ARCHIVE_SWITCH_FULFILLED: {
            const tempGetSwitchesResponse = {...state.getSwitchesResponse};
            tempGetSwitchesResponse.list = tempGetSwitchesResponse.list || [];
            const archivedSwitchIndex = tempGetSwitchesResponse.list.findIndex(killSwitch => (killSwitch?._id === action?.payload?._id));
            if (archivedSwitchIndex >= 0) {
                tempGetSwitchesResponse.list.splice(archivedSwitchIndex, 1);
            }

            return {
                ...state,
                archiveSwitchLoading: false,
                archiveSwitchFulfilled: true,
                archiveSwitchFailed: false,
                archiveSwitchResponse: action?.payload,
                getSwitchesResponse: tempGetSwitchesResponse
            };
        }

        case ARCHIVE_SWITCH_FAILED:
            return {
                ...state,
                archiveSwitchLoading: false,
                archiveSwitchFulfilled: false,
                archiveSwitchFailed: true,
                archiveSwitchResponse: action?.payload,
            };

        case ARCHIVE_SWITCH_RESET:
            return {
                ...state,
                archiveSwitchLoading: initialState.archiveSwitchLoading,
                archiveSwitchFulfilled: initialState.archiveSwitchFulfilled,
                archiveSwitchFailed: initialState.archiveSwitchFailed,
                archiveSwitchResponse: initialState.archiveSwitchResponse
            };


        case UPDATE_SWITCH_LOADING:
            return {
                ...state,
                updateSwitchLoading: true,
                updateSwitchFulfilled: false,
                updateSwitchFailed: false,
            };

        case UPDATE_SWITCH_FULFILLED:
            return {
                ...state,
                updateSwitchLoading: false,
                updateSwitchFulfilled: true,
                updateSwitchFailed: false,
                updateSwitchResponse: action?.payload,
            };

        case UPDATE_SWITCH_FAILED:
            return {
                ...state,
                updateSwitchLoading: false,
                updateSwitchFulfilled: false,
                updateSwitchFailed: true,
                updateSwitchResponse: action?.payload,
            };

        case UPDATE_SWITCH_RESET:
            return {
                ...state,
                updateSwitchLoading: initialState.updateSwitchLoading,
                updateSwitchFulfilled: initialState.updateSwitchFulfilled,
                updateSwitchFailed: initialState.updateSwitchFailed,
                updateSwitchResponse: initialState.updateSwitchResponse
            };


        case GET_SWITCH_DETAILS_LOADING:
            return {
                ...state,
                getSwitchDetailsLoading: true,
                getSwitchDetailsFulfilled: false,
                getSwitchDetailsFailed: false,
            };

        case GET_SWITCH_DETAILS_FULFILLED: {
            const tempGetSwitchDetailsResponse = state.getSwitchDetailsResponse || {};
            const killSwitchId = action?.payload?._id;
            if (killSwitchId) {
                tempGetSwitchDetailsResponse[killSwitchId] = action?.payload;
            }

            return {
                ...state,
                getSwitchDetailsLoading: false,
                getSwitchDetailsFulfilled: true,
                getSwitchDetailsFailed: false,
                getSwitchDetailsResponse: tempGetSwitchDetailsResponse,
            };
        }

        case GET_SWITCH_DETAILS_FAILED:
            return {
                ...state,
                getSwitchDetailsLoading: false,
                getSwitchDetailsFulfilled: false,
                getSwitchDetailsFailed: true,
            };

        default:
            return state;
    }
};

export default switchReducer;