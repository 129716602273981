//Libraries
import React from 'react';
import dynamic from 'next/dynamic';

//Constants & Utils
import { HLS_CONFIG } from '../../../constants/feedMediaConstants';

const ReactHlsPlayer = dynamic(import ('@panelist/react-hls-player'), { ssr: false });
export default function CustomHLSPlayer ({src}) {
    const playerRef = React.useRef();
    return (
            <ReactHlsPlayer
                playerRef={playerRef}
                src={src}
                autoPlay={false}
                controls={true}
                muted={true}
                width="100%"
                height="auto"
                config={HLS_CONFIG}
            />
    );
}