import {
    ARCHIVE_EXPERIMENT_GROUP_FAILED,
    ARCHIVE_EXPERIMENT_GROUP_FULFILLED,
    ARCHIVE_EXPERIMENT_GROUP_LOADING,
    ARCHIVE_EXPERIMENT_GROUP_RESET,
    CREATE_EXPERIMENT_GROUP_FAILED,
    CREATE_EXPERIMENT_GROUP_FULFILLED,
    CREATE_EXPERIMENT_GROUP_LOADING,
    CREATE_EXPERIMENT_GROUP_RESET,
    GET_EXPERIMENT_GROUP_DETAILS_FAILED,
    GET_EXPERIMENT_GROUP_DETAILS_FULFILLED,
    GET_EXPERIMENT_GROUP_DETAILS_LOADING,
    GET_EXPERIMENT_GROUPS_FAILED,
    GET_EXPERIMENT_GROUPS_FULFILLED,
    GET_EXPERIMENT_GROUPS_LOADING,
    UPDATE_EXPERIMENT_GROUP_FAILED,
    UPDATE_EXPERIMENT_GROUP_FULFILLED,
    UPDATE_EXPERIMENT_GROUP_LOADING,
    UPDATE_EXPERIMENT_GROUP_RESET
} from '../types';

const initialState = {
    getExperimentGroupsLoading: false,
    getExperimentGroupsFulfilled: false,
    getExperimentGroupsFailed: false,
    getExperimentGroupsResponse: {},

    createExperimentGroupLoading: false,
    createExperimentGroupFulfilled: false,
    createExperimentGroupFailed: false,
    createExperimentGroupResponse: {},

    updateExperimentGroupLoading: false,
    updateExperimentGroupFulfilled: false,
    updateExperimentGroupFailed: false,
    updateExperimentGroupResponse: {},

    archiveExperimentGroupLoading: false,
    archiveExperimentGroupFulfilled: false,
    archiveExperimentGroupFailed: false,
    archiveExperimentGroupResponse: {},

    getExperimentGroupDetailsLoading: false,
    getExperimentGroupDetailsFulfilled: false,
    getExperimentGroupDetailsFailed: false,
    getExperimentGroupDetailsResponse: {}
};

const experimentGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIMENT_GROUPS_LOADING:
            return {
                ...state,
                getExperimentGroupsLoading: true,
                getExperimentGroupsFulfilled: false,
                getExperimentGroupsFailed: false,
            };

        case GET_EXPERIMENT_GROUPS_FULFILLED:
            const tempGetExperimentGroupsResponse = {...state.getExperimentGroupsResponse};
            tempGetExperimentGroupsResponse.offset = action?.payload?.offset;
            tempGetExperimentGroupsResponse.total = action?.payload?.total;
            tempGetExperimentGroupsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetExperimentGroupsResponse.list = (tempGetExperimentGroupsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetExperimentGroupsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getExperimentGroupsLoading: false,
                getExperimentGroupsFulfilled: true,
                getExperimentGroupsFailed: false,
                getExperimentGroupsResponse: tempGetExperimentGroupsResponse,
            };

        case GET_EXPERIMENT_GROUPS_FAILED:
            return {
                ...state,
                getExperimentGroupsLoading: false,
                getExperimentGroupsFulfilled: false,
                getExperimentGroupsFailed: true,
            };


        case CREATE_EXPERIMENT_GROUP_LOADING:
            return {
                ...state,
                createExperimentGroupLoading: true,
                createExperimentGroupFulfilled: false,
                createExperimentGroupFailed: false,
            };

        case CREATE_EXPERIMENT_GROUP_FULFILLED:
            return {
                ...state,
                createExperimentGroupLoading: false,
                createExperimentGroupFulfilled: true,
                createExperimentGroupFailed: false,
                createExperimentGroupResponse: action?.payload,
            };

        case CREATE_EXPERIMENT_GROUP_FAILED:
            return {
                ...state,
                createExperimentGroupLoading: false,
                createExperimentGroupFulfilled: false,
                createExperimentGroupFailed: true,
                createExperimentGroupResponse: action?.payload,
            };
        case CREATE_EXPERIMENT_GROUP_RESET:
            return {
                ...state,
                createExperimentGroupLoading: false,
                createExperimentGroupFulfilled: false,
                createExperimentGroupFailed: false,
                createExperimentGroupResponse: {},
            };
        case ARCHIVE_EXPERIMENT_GROUP_LOADING:
            return {
                ...state,
                archiveExperimentGroupLoading: true,
                archiveExperimentGroupFulfilled: false,
                archiveExperimentGroupFailed: false,
            };

        case ARCHIVE_EXPERIMENT_GROUP_FULFILLED: {
            const tempGetExperimentGroupsResponse = {...state.getExperimentGroupsResponse};
            tempGetExperimentGroupsResponse.list = tempGetExperimentGroupsResponse.list || [];
            const archivedExperimentGroupIndex = tempGetExperimentGroupsResponse.list.findIndex(experimentGroup => (experimentGroup?._id === action?.payload?._id));
            if (archivedExperimentGroupIndex >= 0) {
                tempGetExperimentGroupsResponse.list.splice(archivedExperimentGroupIndex, 1);
            }

            return {
                ...state,
                archiveExperimentGroupLoading: false,
                archiveExperimentGroupFulfilled: true,
                archiveExperimentGroupFailed: false,
                archiveExperimentGroupResponse: action?.payload,
                getExperimentGroupsResponse: tempGetExperimentGroupsResponse
            };
        }

        case ARCHIVE_EXPERIMENT_GROUP_FAILED:
            return {
                ...state,
                archiveExperimentGroupLoading: false,
                archiveExperimentGroupFulfilled: false,
                archiveExperimentGroupFailed: true,
                archiveExperimentGroupResponse: action?.payload,
            };

        case ARCHIVE_EXPERIMENT_GROUP_RESET:
            return {
                ...state,
                archiveExperimentGroupLoading: initialState.archiveExperimentGroupLoading,
                archiveExperimentGroupFulfilled: initialState.archiveExperimentGroupFulfilled,
                archiveExperimentGroupFailed: initialState.archiveExperimentGroupFailed,
                archiveExperimentGroupResponse: initialState.archiveExperimentGroupResponse
            };


        case UPDATE_EXPERIMENT_GROUP_LOADING:
            return {
                ...state,
                updateExperimentGroupLoading: true,
                updateExperimentGroupFulfilled: false,
                updateExperimentGroupFailed: false,
            };

        case UPDATE_EXPERIMENT_GROUP_FULFILLED:
            return {
                ...state,
                updateExperimentGroupLoading: false,
                updateExperimentGroupFulfilled: true,
                updateExperimentGroupFailed: false,
                updateExperimentGroupResponse: action?.payload,
            };

        case UPDATE_EXPERIMENT_GROUP_FAILED:
            return {
                ...state,
                updateExperimentGroupLoading: false,
                updateExperimentGroupFulfilled: false,
                updateExperimentGroupFailed: true,
                updateExperimentGroupResponse: action?.payload,
            };

        case UPDATE_EXPERIMENT_GROUP_RESET:
            return {
                ...state,
                updateExperimentGroupLoading: initialState.updateExperimentGroupLoading,
                updateExperimentGroupFulfilled: initialState.updateExperimentGroupFulfilled,
                updateExperimentGroupFailed: initialState.updateExperimentGroupFailed,
                updateExperimentGroupResponse: initialState.updateExperimentGroupResponse
            };


        case GET_EXPERIMENT_GROUP_DETAILS_LOADING:
            return {
                ...state,
                getExperimentGroupDetailsLoading: true,
                getExperimentGroupDetailsFulfilled: false,
                getExperimentGroupDetailsFailed: false,
            };

        case GET_EXPERIMENT_GROUP_DETAILS_FULFILLED: {
            const tempGetExperimentGroupDetailsResponse = state.getExperimentGroupDetailsResponse || {};
            const experimentGroupId = action?.payload?._id;
            if (experimentGroupId) {
                tempGetExperimentGroupDetailsResponse[experimentGroupId] = action?.payload;
            }

            return {
                ...state,
                getExperimentGroupDetailsLoading: false,
                getExperimentGroupDetailsFulfilled: true,
                getExperimentGroupDetailsFailed: false,
                getExperimentGroupDetailsResponse: tempGetExperimentGroupDetailsResponse,
            };
        }

        case GET_EXPERIMENT_GROUP_DETAILS_FAILED:
            return {
                ...state,
                getExperimentGroupDetailsLoading: false,
                getExperimentGroupDetailsFulfilled: false,
                getExperimentGroupDetailsFailed: true,
            };
        default:
            return state;
    }
};

export default experimentGroupReducer;