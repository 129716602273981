import {
    GET_WIDGETS_LOADING,
    GET_WIDGETS_FULFILLED,
    GET_WIDGETS_FAILED,

    CREATE_WIDGET_LOADING,
    CREATE_WIDGET_FULFILLED,
    CREATE_WIDGET_FAILED,
    CREATE_WIDGET_RESET,

    UPDATE_WIDGET_LOADING,
    UPDATE_WIDGET_FULFILLED,
    UPDATE_WIDGET_FAILED,
    UPDATE_WIDGET_RESET,

    ARCHIVE_WIDGET_LOADING,
    ARCHIVE_WIDGET_FULFILLED,
    ARCHIVE_WIDGET_FAILED,
    ARCHIVE_WIDGET_RESET,

    GET_WIDGET_DETAILS_LOADING,
    GET_WIDGET_DETAILS_FULFILLED,
    GET_WIDGET_DETAILS_FAILED
} from '../types';

const initialState = {
    getWidgetsLoading: false,
    getWidgetsFulfilled: false,
    getWidgetsFailed: false,
    getWidgetsResponse: {},

    createWidgetLoading: false,
    createWidgetFulfilled: false,
    createWidgetFailed: false,
    createWidgetResponse: {},

    updateWidgetLoading: false,
    updateWidgetFulfilled: false,
    updateWidgetFailed: false,
    updateWidgetResponse: {},

    archiveWidgetLoading: false,
    archiveWidgetFulfilled: false,
    archiveWidgetFailed: false,
    archiveWidgetResponse: {},

    getWidgetDetailsLoading: false,
    getWidgetDetailsFulfilled: false,
    getWidgetDetailsFailed: false,
    getWidgetDetailsResponse: {}
};

const WidgetsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_WIDGETS_LOADING:
        return {
            ...state,
            getWidgetsLoading: true,
            getWidgetsFulfilled: false,
            getWidgetsFailed: false,
        };

    case GET_WIDGETS_FULFILLED: {
        const tempGetWidgetsResponse = {...state.getWidgetsResponse};
        tempGetWidgetsResponse.offset = action?.payload?.offset;
        tempGetWidgetsResponse.total = action?.payload?.total;
        tempGetWidgetsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetWidgetsResponse.list = (tempGetWidgetsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetWidgetsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getWidgetsLoading: false,
            getWidgetsFulfilled: true,
            getWidgetsFailed: false,
            getWidgetsResponse: tempGetWidgetsResponse,
        };
    }

    case GET_WIDGETS_FAILED:
        return {
            ...state,
            getWidgetsLoading: false,
            getWidgetsFulfilled: false,
            getWidgetsFailed: true,
        };


    case CREATE_WIDGET_LOADING:
        return {
            ...state,
            createWidgetLoading: true,
            createWidgetFulfilled: false,
            createWidgetFailed: false,
        };

    case CREATE_WIDGET_FULFILLED:
        return {
            ...state,
            createWidgetLoading: false,
            createWidgetFulfilled: true,
            createWidgetFailed: false,
            createWidgetResponse: action?.payload,
        };

    case CREATE_WIDGET_FAILED:
        return {
            ...state,
            createWidgetLoading: false,
            createWidgetFulfilled: false,
            createWidgetFailed: true,
            createWidgetResponse: action?.payload,
        };

    case CREATE_WIDGET_RESET:
        return {
            ...state,
            createWidgetLoading: initialState.createWidgetLoading,
            createWidgetFulfilled: initialState.createWidgetFulfilled,
            createWidgetFailed: initialState.createWidgetFailed,
            createWidgetResponse: initialState.createWidgetResponse
        };


    case ARCHIVE_WIDGET_LOADING:
        return {
            ...state,
            archiveWidgetLoading: true,
            archiveWidgetFulfilled: false,
            archiveWidgetFailed: false,
        };

    case ARCHIVE_WIDGET_FULFILLED: {
        const tempGetWidgetsResponse = {...state.getWidgetsResponse};
        tempGetWidgetsResponse.list = tempGetWidgetsResponse.list || [];
        const archivedWidgetIndex = tempGetWidgetsResponse.list.findIndex(modalDialog => (modalDialog?._id===action?.payload?._id));
        if (archivedWidgetIndex>=0) {
            tempGetWidgetsResponse.list.splice(archivedWidgetIndex, 1);
        }

        return {
            ...state,
            archiveWidgetLoading: false,
            archiveWidgetFulfilled: true,
            archiveWidgetFailed: false,
            archiveWidgetResponse: action?.payload,
            getWidgetsResponse: tempGetWidgetsResponse
        };
    }

    case ARCHIVE_WIDGET_FAILED:
        return {
            ...state,
            archiveWidgetLoading: false,
            archiveWidgetFulfilled: false,
            archiveWidgetFailed: true,
            archiveWidgetResponse: action?.payload,
        };

    case ARCHIVE_WIDGET_RESET:
        return {
            ...state,
            archiveWidgetLoading: initialState.archiveWidgetLoading,
            archiveWidgetFulfilled: initialState.archiveWidgetFulfilled,
            archiveWidgetFailed: initialState.archiveWidgetFailed,
            archiveWidgetResponse: initialState.archiveWidgetResponse
        };


    case UPDATE_WIDGET_LOADING:
        return {
            ...state,
            updateWidgetLoading: true,
            updateWidgetFulfilled: false,
            updateWidgetFailed: false,
        };

    case UPDATE_WIDGET_FULFILLED:
        return {
            ...state,
            updateWidgetLoading: false,
            updateWidgetFulfilled: true,
            updateWidgetFailed: false,
            updateWidgetResponse: action?.payload,
        };

    case UPDATE_WIDGET_FAILED:
        return {
            ...state,
            updateWidgetLoading: false,
            updateWidgetFulfilled: false,
            updateWidgetFailed: true,
            updateWidgetResponse: action?.payload,
        };

    case UPDATE_WIDGET_RESET:
        return {
            ...state,
            updateWidgetLoading: initialState.updateWidgetLoading,
            updateWidgetFulfilled: initialState.updateWidgetFulfilled,
            updateWidgetFailed: initialState.updateWidgetFailed,
            updateWidgetResponse: initialState.updateWidgetResponse
        };


    case GET_WIDGET_DETAILS_LOADING:
        return {
            ...state,
            getWidgetDetailsLoading: true,
            getWidgetDetailsFulfilled: false,
            getWidgetDetailsFailed: false,
        };

    case GET_WIDGET_DETAILS_FULFILLED: {
        const tempGetWidgetDetailsResponse = state.getWidgetDetailsResponse || {};
        const _id = action?.payload?._id;
        if (_id) {
            tempGetWidgetDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getWidgetDetailsLoading: false,
            getWidgetDetailsFulfilled: true,
            getWidgetDetailsFailed: false,
            getWidgetDetailsResponse: tempGetWidgetDetailsResponse,
        };
    }

    case GET_WIDGET_DETAILS_FAILED:
        return {
            ...state,
            getWidgetDetailsLoading: false,
            getWidgetDetailsFulfilled: false,
            getWidgetDetailsFailed: true,
            getWidgetDetailsResponse: action?.payload,
        };


    default:
        return state;
    }
};

export default WidgetsReducer;
