import {
    ARCHIVE_STORY_FAILED,
    ARCHIVE_STORY_FULFILLED,
    ARCHIVE_STORY_LOADING,
    ARCHIVE_STORY_RESET,
    CHANGE_STORY_STATE_FAILED,
    CHANGE_STORY_STATE_FULFILLED,
    CHANGE_STORY_STATE_LOADING,
    CHANGE_STORY_STATE_RESET,
    CREATE_STORY_FAILED,
    CREATE_STORY_FULFILLED,
    CREATE_STORY_LOADING,
    CREATE_STORY_RESET,
    GET_STORIES_FAILED,
    GET_STORIES_FULFILLED,
    GET_STORIES_LOADING,
    GET_STORY_DETAILS_FAILED,
    GET_STORY_DETAILS_FULFILLED,
    GET_STORY_DETAILS_LOADING,
    UPDATE_STORY_FAILED,
    UPDATE_STORY_FULFILLED,
    UPDATE_STORY_LOADING,
    UPDATE_STORY_RESET,
} from '../types';

const initialState = {
    getStoriesLoading: false,
    getStoriesFulfilled: false,
    getStoriesFailed: false,
    getStoriesResponse: {},

    createStoryLoading: false,
    createStoryFulfilled: false,
    createStoryFailed: false,
    createStoryResponse: {},

    updateStoryLoading: false,
    updateStoryFulfilled: false,
    updateStoryFailed: false,
    updateStoryResponse: {},

    archiveStoryLoading: false,
    archiveStoryFulfilled: false,
    archiveStoryFailed: false,
    archiveStoryResponse: {},

    getStoryDetailsLoading: false,
    getStoryDetailsFulfilled: false,
    getStoryDetailsFailed: false,
    getStoryDetailsResponse: {},

    changeStoryStateLoading: false,
    changeStoryStateFulfilled: false,
    changeStoryStateFailed: false,
    changeStoryStateResponse: {},
};

const StoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STORIES_LOADING:
            return {
                ...state,
                getStoriesLoading: true,
                getStoriesFulfilled: false,
                getStoriesFailed: false,
            };

        case GET_STORIES_FULFILLED: {
            const tempGetStoriesResponse = {...state.getStoriesResponse};
            tempGetStoriesResponse.offset = action?.payload?.offset;
            tempGetStoriesResponse.total = action?.payload?.total;
            tempGetStoriesResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetStoriesResponse.list = (tempGetStoriesResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetStoriesResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getStoriesLoading: false,
                getStoriesFulfilled: true,
                getStoriesFailed: false,
                getStoriesResponse: tempGetStoriesResponse,
            };
        }

        case GET_STORIES_FAILED:
            return {
                ...state,
                getStoriesLoading: false,
                getStoriesFulfilled: false,
                getStoriesFailed: true,
            };


        case CREATE_STORY_LOADING:
            return {
                ...state,
                createStoryLoading: true,
                createStoryFulfilled: false,
                createStoryFailed: false,
            };

        case CREATE_STORY_FULFILLED:
            return {
                ...state,
                createStoryLoading: false,
                createStoryFulfilled: true,
                createStoryFailed: false,
                createStoryResponse: action?.payload,
            };

        case CREATE_STORY_FAILED:
            return {
                ...state,
                createStoryLoading: false,
                createStoryFulfilled: false,
                createStoryFailed: true,
                createStoryResponse: action?.payload,
            };

        case CREATE_STORY_RESET:
            return {
                ...state,
                createStoryLoading: initialState.createStoryLoading,
                createStoryFulfilled: initialState.createStoryFulfilled,
                createStoryFailed: initialState.createStoryFailed,
                createStoryResponse: initialState.createStoryResponse
            };


        case ARCHIVE_STORY_LOADING:
            return {
                ...state,
                archiveStoryLoading: true,
                archiveStoryFulfilled: false,
                archiveStoryFailed: false,
            };

        case ARCHIVE_STORY_FULFILLED: {
            const tempGetStoriesResponse = {...state.getStoriesResponse};
            tempGetStoriesResponse.list = tempGetStoriesResponse.list || [];
            const archivedStoryIndex = tempGetStoriesResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedStoryIndex >= 0) {
                tempGetStoriesResponse.list.splice(archivedStoryIndex, 1);
            }

            const tempGetStoriesToReviewResponse = {...state.getStoriesToReviewResponse};
            tempGetStoriesToReviewResponse.list = tempGetStoriesToReviewResponse.list || [];
            const archivedStoryToReviewIndex = tempGetStoriesToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedStoryToReviewIndex >= 0) {
                tempGetStoriesToReviewResponse.list.splice(archivedStoryToReviewIndex, 1);
            }

            return {
                ...state,
                archiveStoryLoading: false,
                archiveStoryFulfilled: true,
                archiveStoryFailed: false,
                archiveStoryResponse: action?.payload,
                getStoriesResponse: tempGetStoriesResponse,
                getStoriesToReviewResponse: tempGetStoriesToReviewResponse
            };
        }

        case ARCHIVE_STORY_FAILED:
            return {
                ...state,
                archiveStoryLoading: false,
                archiveStoryFulfilled: false,
                archiveStoryFailed: true,
                archiveStoryResponse: action?.payload,
            };

        case ARCHIVE_STORY_RESET:
            return {
                ...state,
                archiveStoryLoading: initialState.archiveStoryLoading,
                archiveStoryFulfilled: initialState.archiveStoryFulfilled,
                archiveStoryFailed: initialState.archiveStoryFailed,
                archiveStoryResponse: initialState.archiveStoryResponse
            };


        case UPDATE_STORY_LOADING:
            return {
                ...state,
                updateStoryLoading: true,
                updateStoryFulfilled: false,
                updateStoryFailed: false,
            };

        case UPDATE_STORY_FULFILLED:
            return {
                ...state,
                updateStoryLoading: false,
                updateStoryFulfilled: true,
                updateStoryFailed: false,
                updateStoryResponse: action?.payload,
            };

        case UPDATE_STORY_FAILED:
            return {
                ...state,
                updateStoryLoading: false,
                updateStoryFulfilled: false,
                updateStoryFailed: true,
                updateStoryResponse: action?.payload,
            };

        case UPDATE_STORY_RESET:
            return {
                ...state,
                updateStoryLoading: initialState.updateStoryLoading,
                updateStoryFulfilled: initialState.updateStoryFulfilled,
                updateStoryFailed: initialState.updateStoryFailed,
                updateStoryResponse: initialState.updateStoryResponse
            };

        case GET_STORY_DETAILS_LOADING:
            return {
                ...state,
                getStoryDetailsLoading: true,
                getStoryDetailsFulfilled: false,
                getStoryDetailsFailed: false,
            };

        case GET_STORY_DETAILS_FULFILLED: {
            const tempGetStoryDetailsResponse = state.getStoryDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetStoryDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getStoryDetailsLoading: false,
                getStoryDetailsFulfilled: true,
                getStoryDetailsFailed: false,
                getStoryDetailsResponse: tempGetStoryDetailsResponse,
            };
        }

        case GET_STORY_DETAILS_FAILED:
            return {
                ...state,
                getStoryDetailsLoading: false,
                getStoryDetailsFulfilled: false,
                getStoryDetailsFailed: true,
                getStoryDetailsResponse: action?.payload,
            };


        case CHANGE_STORY_STATE_LOADING:
            return {
                ...state,
                changeStoryStateLoading: true,
                changeStoryStateFulfilled: false,
                changeStoryStateFailed: false,
            };

        case CHANGE_STORY_STATE_FULFILLED:
            return {
                ...state,
                changeStoryStateLoading: false,
                changeStoryStateFulfilled: true,
                changeStoryStateFailed: false,
                changeStoryStateResponse: action?.payload,
            };

        case CHANGE_STORY_STATE_FAILED:
            return {
                ...state,
                changeStoryStateLoading: false,
                changeStoryStateFulfilled: false,
                changeStoryStateFailed: true,
                changeStoryStateResponse: action?.payload,
            };

        case CHANGE_STORY_STATE_RESET:
            return {
                ...state,
                changeStoryStateLoading: initialState.changeStoryStateLoading,
                changeStoryStateFulfilled: initialState.changeStoryStateFulfilled,
                changeStoryStateFailed: initialState.changeStoryStateFailed,
                changeStoryStateResponse: initialState.changeStoryStateResponse
            };
        default:
            return state;
    }
};

export default StoriesReducer;
