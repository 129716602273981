import {
    ADD_ELEMENT_FAILED,
    ADD_ELEMENT_FULFILLED,
    ADD_ELEMENT_LOADING,
    ADD_ELEMENT_RESET,
    CREATE_CAMPAIGN_FAILED,
    CREATE_CAMPAIGN_FULFILLED,
    CREATE_CAMPAIGN_LOADING,
    CREATE_CAMPAIGN_RESET,
    GET_ALL_ELEMENTS_FAILED,
    GET_ALL_ELEMENTS_FULFILLED,
    GET_ALL_ELEMENTS_LOADING,
    GET_CAMPAIGN_CREATORS_FAILED,
    GET_CAMPAIGN_CREATORS_FULFILLED,
    GET_CAMPAIGN_CREATORS_LOADING,
    GET_CAMPAIGN_DETAILS_FAILED,
    GET_CAMPAIGN_DETAILS_FULFILLED,
    GET_CAMPAIGN_DETAILS_LOADING,
    GET_CAMPAIGNS_FAILED,
    GET_CAMPAIGNS_FULFILLED,
    GET_CAMPAIGNS_LOADING,
    GET_IN_REVIEW_CAMPAIGNS_FAILED,
    GET_IN_REVIEW_CAMPAIGNS_FULFILLED,
    GET_IN_REVIEW_CAMPAIGNS_LOADING,
    GET_NOT_IN_REVIEW_CAMPAIGNS_FAILED,
    GET_NOT_IN_REVIEW_CAMPAIGNS_FULFILLED,
    GET_NOT_IN_REVIEW_CAMPAIGNS_LOADING,
    PERFORM_CAMPAIGN_OPERATION_FAILED,
    PERFORM_CAMPAIGN_OPERATION_FULFILLED,
    PERFORM_CAMPAIGN_OPERATION_LOADING,
    PERFORM_CAMPAIGN_OPERATION_RESET,
    REMOVE_ELEMENT_FAILED,
    REMOVE_ELEMENT_FULFILLED,
    REMOVE_ELEMENT_LOADING,
    REMOVE_ELEMENT_RESET,
    UPDATE_CAMPAIGN_FAILED,
    UPDATE_CAMPAIGN_FULFILLED,
    UPDATE_CAMPAIGN_LOADING,
    UPDATE_CAMPAIGN_RESET
} from '../types';

const initialState = {
    getCampaignsLoading: false,
    getCampaignsFulfilled: false,
    getCampaignsFailed: false,
    getCampaignsResponse: {},

    getInReviewCampaignsLoading: false,
    getInReviewCampaignsFulfilled: false,
    getInReviewCampaignsFailed: false,
    getInReviewCampaignsResponse: {},

    getNotInReviewCampaignsLoading: false,
    getNotInReviewCampaignsFulfilled: false,
    getNotInReviewCampaignsFailed: false,
    getNotInReviewCampaignsResponse: {},

    createCampaignLoading: false,
    createCampaignFulfilled: false,
    createCampaignFailed: false,
    createCampaignResponse: {},

    updateCampaignLoading: false,
    updateCampaignFulfilled: false,
    updateCampaignFailed: false,
    updateCampaignResponse: {},

    getCampaignDetailsLoading: false,
    getCampaignDetailsFulfilled: false,
    getCampaignDetailsFailed: false,
    getCampaignDetailsResponse: {},

    getAllElementsLoading: false,
    getAllElementsFulfilled: false,
    getAllElementsFailed: false,
    getAllElementsResponse: {},

    getCampaignCreatorsLoading: false,
    getCampaignCreatorsFulfilled: false,
    getCampaignCreatorsFailed: false,
    getCampaignCreatorsResponse: {},

    addElementLoading: false,
    addElementFulfilled: false,
    addElementFailed: false,
    addElementResponse: {},

    removeElementLoading: false,
    removeElementFulfilled: false,
    removeElementFailed: false,
    removeElementResponse: {},

    performCampaignOperationLoading: false,
    performCampaignOperationFulfilled: false,
    performCampaignOperationFailed: false,
    performCampaignOperationResponse: false,
    performCampaignOperationType: null,

};

const CampaignReducer = (state = initialState, action) => {
    switch (action.type) {

    case GET_CAMPAIGNS_LOADING:
        return {
            ...state,
            getCampaignsLoading: true,
            getCampaignsFulfilled: false,
            getCampaignsFailed: false,
        };

    case GET_CAMPAIGNS_FULFILLED: {
        const tempGetCampaignsResponse = {
            ...state.getCampaignsResponse,
            offset: action?.payload?.offset,
            total: action?.payload?.total,
            hasMore: action?.payload?.hasMore
        };

        if (action?.payload?.reqOffset) {
            tempGetCampaignsResponse.list = (tempGetCampaignsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetCampaignsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getCampaignsLoading: false,
            getCampaignsFulfilled: true,
            getCampaignsFailed: false,
            getCampaignsResponse: tempGetCampaignsResponse,
        };
    }

    case GET_CAMPAIGNS_FAILED:
        return {
            ...state,
            getCampaignsLoading: false,
            getCampaignsFulfilled: false,
            getCampaignsFailed: true,
        };


    case GET_IN_REVIEW_CAMPAIGNS_LOADING:
        return {
            ...state,
            getInReviewCampaignsLoading: true,
            getInReviewCampaignsFulfilled: false,
            getInReviewCampaignsFailed: false,
        };

    case GET_IN_REVIEW_CAMPAIGNS_FULFILLED: {
        const tempGetInReviewCampaignsResponse = {
            ...state.getInReviewCampaignsResponse,
            offset: action?.payload?.offset,
            total: action?.payload?.total,
            hasMore: action?.payload?.hasMore
        };

        if (action?.payload?.reqOffset) {
            tempGetInReviewCampaignsResponse.list = (tempGetInReviewCampaignsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetInReviewCampaignsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getInReviewCampaignsLoading: false,
            getInReviewCampaignsFulfilled: true,
            getInReviewCampaignsFailed: false,
            getInReviewCampaignsResponse: tempGetInReviewCampaignsResponse,
        };
    }

    case GET_IN_REVIEW_CAMPAIGNS_FAILED:
        return {
            ...state,
            getInReviewCampaignsLoading: false,
            getInReviewCampaignsFulfilled: false,
            getInReviewCampaignsFailed: true,
        };


    case GET_NOT_IN_REVIEW_CAMPAIGNS_LOADING:
        return {
            ...state,
            getNotInReviewCampaignsLoading: true,
            getNotInReviewCampaignsFulfilled: false,
            getNotInReviewCampaignsFailed: false,
        };

    case GET_NOT_IN_REVIEW_CAMPAIGNS_FULFILLED: {
        const tempGetNotInReviewCampaignsResponse = {
            ...state.getNotInReviewCampaignsResponse,
            offset: action?.payload?.offset,
            total: action?.payload?.total,
            hasMore: action?.payload?.hasMore
        };

        if (action?.payload?.reqOffset) {
            tempGetNotInReviewCampaignsResponse.list = (tempGetNotInReviewCampaignsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetNotInReviewCampaignsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getNotInReviewCampaignsLoading: false,
            getNotInReviewCampaignsFulfilled: true,
            getNotInReviewCampaignsFailed: false,
            getNotInReviewCampaignsResponse: tempGetNotInReviewCampaignsResponse,
        };
    }

    case GET_NOT_IN_REVIEW_CAMPAIGNS_FAILED:
        return {
            ...state,
            getNotInReviewCampaignsLoading: false,
            getNotInReviewCampaignsFulfilled: false,
            getNotInReviewCampaignsFailed: true,
        };


    case CREATE_CAMPAIGN_LOADING:
        return {
            ...state,
            createCampaignLoading: true,
            createCampaignFulfilled: false,
            createCampaignFailed: false,
        };

    case CREATE_CAMPAIGN_FULFILLED:
        return {
            ...state,
            createCampaignLoading: false,
            createCampaignFulfilled: true,
            createCampaignFailed: false,
            createCampaignResponse: action?.payload,
        };

    case CREATE_CAMPAIGN_FAILED:
        return {
            ...state,
            createCampaignLoading: false,
            createCampaignFulfilled: false,
            createCampaignFailed: true,
            createCampaignResponse: action?.payload,
        };

    case CREATE_CAMPAIGN_RESET:
        return {
            ...state,
            createCampaignLoading: initialState.createCampaignLoading,
            createCampaignFulfilled: initialState.createCampaignFulfilled,
            createCampaignFailed: initialState.createCampaignFailed,
            createCampaignResponse: initialState.createCampaignResponse
        };

    case UPDATE_CAMPAIGN_LOADING:
        return {
            ...state,
            updateCampaignLoading: true,
            updateCampaignFulfilled: false,
            updateCampaignFailed: false,
        };

    case UPDATE_CAMPAIGN_FULFILLED: {
        const tempGetCampaignsResponse = {...state.getCampaignsResponse};
        tempGetCampaignsResponse.list = tempGetCampaignsResponse.list || [];
        const sentForReviewCampaignIndex = tempGetCampaignsResponse.list.findIndex(campaign => (campaign?._id === action?.payload?._id));
        if (sentForReviewCampaignIndex >= 0) {
            tempGetCampaignsResponse.list.splice(sentForReviewCampaignIndex, 1, action?.payload);
        }

        const tempGetCampaignDetailsResponse = {...state.getCampaignDetailsResponse};
        if (tempGetCampaignDetailsResponse && tempGetCampaignDetailsResponse[action?.payload?._id]) {
            tempGetCampaignDetailsResponse[action?.payload?._id] = action?.payload;
        }

        return {
            ...state,
            updateCampaignLoading: false,
            updateCampaignFulfilled: true,
            updateCampaignFailed: false,
            updateCampaignResponse: action?.payload,
            getCampaignsResponse: tempGetCampaignsResponse,
            getCampaignDetailsResponse: tempGetCampaignDetailsResponse
        };
    }

    case UPDATE_CAMPAIGN_FAILED:
        return {
            ...state,
            updateCampaignLoading: false,
            updateCampaignFulfilled: false,
            updateCampaignFailed: true,
            updateCampaignResponse: action?.payload,
        };

    case UPDATE_CAMPAIGN_RESET:
        return {
            ...state,
            updateCampaignLoading: initialState.updateCampaignLoading,
            updateCampaignFulfilled: initialState.updateCampaignFulfilled,
            updateCampaignFailed: initialState.updateCampaignFailed,
            updateCampaignResponse: initialState.updateCampaignResponse
        };


    case GET_CAMPAIGN_DETAILS_LOADING:
        return {
            ...state,
            getCampaignDetailsLoading: true,
            getCampaignDetailsFulfilled: false,
            getCampaignDetailsFailed: false,
        };

    case GET_CAMPAIGN_DETAILS_FULFILLED: {
        const tempGetCampaignDetailsResponse = {...state.getCampaignDetailsResponse};
        if (action?.payload?._id) {
            tempGetCampaignDetailsResponse[action?.payload?._id] = action?.payload;
        }

        return {
            ...state,
            getCampaignDetailsLoading: false,
            getCampaignDetailsFulfilled: true,
            getCampaignDetailsFailed: false,
            getCampaignDetailsResponse: tempGetCampaignDetailsResponse,
        };
    }

    case GET_CAMPAIGN_DETAILS_FAILED:
        return {
            ...state,
            getCampaignDetailsLoading: false,
            getCampaignDetailsFulfilled: false,
            getCampaignDetailsFailed: true,
            getCampaignDetailsResponse: action?.payload
        };

    case GET_ALL_ELEMENTS_LOADING:
        return {
            ...state,
            getAllElementsLoading: true,
            getAllElementsFulfilled: false,
            getAllElementsFailed: false,
        };

    case GET_ALL_ELEMENTS_FULFILLED: {
        const tempGetAllElementsResponse = {...state.getAllElementsResponse};
        if (action?.payload?._id) {
            tempGetAllElementsResponse[action?.payload?._id] = action?.payload;
        }

        return {
            ...state,
            getAllElementsLoading: false,
            getAllElementsFulfilled: true,
            getAllElementsFailed: false,
            getAllElementsResponse: tempGetAllElementsResponse,
        };
    }

    case GET_ALL_ELEMENTS_FAILED:
        return {
            ...state,
            getAllElementsLoading: false,
            getAllElementsFulfilled: false,
            getAllElementsFailed: true,
            getAllElementsResponse: action?.payload
        };

    case GET_CAMPAIGN_CREATORS_LOADING:
        return {
            ...state,
            getCampaignCreatorsLoading: true,
            getCampaignCreatorsFulfilled: false,
            getCampaignCreatorsFailed: false,
        };

    case GET_CAMPAIGN_CREATORS_FULFILLED:
        return {
            ...state,
            getCampaignCreatorsLoading: false,
            getCampaignCreatorsFulfilled: true,
            getCampaignCreatorsFailed: false,
            getCampaignCreatorsResponse: action?.payload
        };

    case GET_CAMPAIGN_CREATORS_FAILED:
        return {
            ...state,
            getCampaignCreatorsLoading: false,
            getCampaignCreatorsFulfilled: false,
            getCampaignCreatorsFailed: true,
            getCampaignCreatorsResponse: action?.payload
        };

    case ADD_ELEMENT_LOADING:
        return {
            ...state,
            addElementLoading: true,
            addElementFulfilled: false,
            addElementFailed: false
        };

    case ADD_ELEMENT_FULFILLED: {
        const tempGetCampaignDetailsResponse = {...state.getCampaignDetailsResponse};
        if (tempGetCampaignDetailsResponse && tempGetCampaignDetailsResponse[action?.payload?._id]) {
            tempGetCampaignDetailsResponse[action?.payload?._id] = action?.payload;
        }

        return {
            ...state,
            addElementLoading: false,
            addElementFulfilled: true,
            addElementFailed: false,
            addElementResponse: action?.payload,
            getCampaignDetailsResponse: tempGetCampaignDetailsResponse,
        };
    }

    case ADD_ELEMENT_FAILED:
        return {
            ...state,
            addElementLoading: false,
            addElementFulfilled: false,
            addElementFailed: true,
            addElementResponse: action?.payload
        };

    case ADD_ELEMENT_RESET:
        return {
            ...state,
            addElementLoading: initialState.addElementLoading,
            addElementFulfilled: initialState.addElementFulfilled,
            addElementFailed: initialState.addElementFailed,
            addElementResponse: initialState.addElementResponse
        };

    case REMOVE_ELEMENT_LOADING:
        return {
            ...state,
            removeElementLoading: true,
            removeElementFulfilled: false,
            removeElementFailed: false
        };

    case REMOVE_ELEMENT_FULFILLED: {
        const tempGetCampaignDetailsResponse = {...state.getCampaignDetailsResponse};
        if (tempGetCampaignDetailsResponse && tempGetCampaignDetailsResponse[action?.payload?._id]) {
            tempGetCampaignDetailsResponse[action?.payload?._id] = action?.payload;
        }

        return {
            ...state,
            removeElementLoading: false,
            removeElementFulfilled: true,
            removeElementFailed: false,
            removeElementResponse: action?.payload,
            getCampaignDetailsResponse: tempGetCampaignDetailsResponse,
        };
    }
    case REMOVE_ELEMENT_FAILED:
        return {
            ...state,
            removeElementLoading: false,
            removeElementFulfilled: false,
            removeElementFailed: true,
            removeElementResponse: action?.payload
        };

    case REMOVE_ELEMENT_RESET:
        return {
            ...state,
            removeElementLoading: initialState.removeElementLoading,
            removeElementFulfilled: initialState.removeElementFulfilled,
            removeElementFailed: initialState.removeElementFailed,
            removeElementResponse: initialState.removeElementResponse
        };

    case PERFORM_CAMPAIGN_OPERATION_LOADING:
        return {
            ...state,
            performCampaignOperationLoading: true,
            performCampaignOperationFulfilled: false,
            performCampaignOperationFailed: false,
            performCampaignOperationType: action?.payload?.operation,
        };

    case PERFORM_CAMPAIGN_OPERATION_FULFILLED: {
        const tempGetCampaignsResponse = {...state.getCampaignsResponse};
        tempGetCampaignsResponse.list = tempGetCampaignsResponse.list || [];
        const sentForReviewCampaignIndex = tempGetCampaignsResponse.list.findIndex(campaign => (campaign?._id === action?.payload?._id));

        if (sentForReviewCampaignIndex >= 0) {
            tempGetCampaignsResponse.list.splice(sentForReviewCampaignIndex, 1, action?.payload);
        }

        const tempGetCampaignDetailsResponse = {...state.getCampaignDetailsResponse};
        if (tempGetCampaignDetailsResponse) {
            tempGetCampaignDetailsResponse[action?.payload?._id] = action?.payload;
        }
        return {
            ...state,
            performCampaignOperationLoading: false,
            performCampaignOperationFulfilled: true,
            performCampaignOperationFailed: false,
            performCampaignOperationResponse: action?.payload,
            getCampaignsResponse: tempGetCampaignsResponse,
            getCampaignDetailsResponse: tempGetCampaignDetailsResponse
        };
    }
    case PERFORM_CAMPAIGN_OPERATION_FAILED:
        return {
            ...state,
            performCampaignOperationLoading: false,
            performCampaignOperationFulfilled: false,
            performCampaignOperationFailed: true,
            performCampaignOperationResponse: action?.payload,
            performCampaignOperationType: null
        };
    case PERFORM_CAMPAIGN_OPERATION_RESET:
        return {
            ...state,
            performCampaignOperationLoading: initialState.performCampaignOperationLoading,
            performCampaignOperationFulfilled: initialState.performCampaignOperationFulfilled,
            performCampaignOperationFailed: initialState.performCampaignOperationFailed,
            performCampaignOperationResponse: initialState.performCampaignOperationResponse
        };
    default:
        return state;
    }
};

export default CampaignReducer;
