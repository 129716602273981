import {
    GET_WIDGET_GROUPS_LOADING,
    GET_WIDGET_GROUPS_FULFILLED,
    GET_WIDGET_GROUPS_FAILED,

    CREATE_WIDGET_GROUP_LOADING,
    CREATE_WIDGET_GROUP_FULFILLED,
    CREATE_WIDGET_GROUP_FAILED,
    CREATE_WIDGET_GROUP_RESET,

    UPDATE_WIDGET_GROUP_LOADING,
    UPDATE_WIDGET_GROUP_FULFILLED,
    UPDATE_WIDGET_GROUP_FAILED,
    UPDATE_WIDGET_GROUP_RESET,

    ARCHIVE_WIDGET_GROUP_LOADING,
    ARCHIVE_WIDGET_GROUP_FULFILLED,
    ARCHIVE_WIDGET_GROUP_FAILED,
    ARCHIVE_WIDGET_GROUP_RESET,

    GET_WIDGET_GROUP_DETAILS_LOADING,
    GET_WIDGET_GROUP_DETAILS_FULFILLED,
    GET_WIDGET_GROUP_DETAILS_FAILED
} from '../types';

const initialState = {
    getWidgetGroupsLoading: false,
    getWidgetGroupsFulfilled: false,
    getWidgetGroupsFailed: false,
    getWidgetGroupsResponse: {},

    createWidgetGroupLoading: false,
    createWidgetGroupFulfilled: false,
    createWidgetGroupFailed: false,
    createWidgetGroupResponse: {},

    updateWidgetGroupLoading: false,
    updateWidgetGroupFulfilled: false,
    updateWidgetGroupFailed: false,
    updateWidgetGroupResponse: {},

    archiveWidgetGroupLoading: false,
    archiveWidgetGroupFulfilled: false,
    archiveWidgetGroupFailed: false,
    archiveWidgetGroupResponse: {},

    getWidgetGroupDetailsLoading: false,
    getWidgetGroupDetailsFulfilled: false,
    getWidgetGroupDetailsFailed: false,
    getWidgetGroupDetailsResponse: {}
};

const WidgetGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_WIDGET_GROUPS_LOADING:
        return {
            ...state,
            getWidgetGroupsLoading: true,
            getWidgetGroupsFulfilled: false,
            getWidgetGroupsFailed: false,
        };

    case GET_WIDGET_GROUPS_FULFILLED: {
        const tempGetWidgetGroupsResponse = {...state.getWidgetGroupsResponse};
        tempGetWidgetGroupsResponse.offset = action?.payload?.offset;
        tempGetWidgetGroupsResponse.total = action?.payload?.total;
        tempGetWidgetGroupsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetWidgetGroupsResponse.list = (tempGetWidgetGroupsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetWidgetGroupsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getWidgetGroupsLoading: false,
            getWidgetGroupsFulfilled: true,
            getWidgetGroupsFailed: false,
            getWidgetGroupsResponse: tempGetWidgetGroupsResponse,
        };
    }

    case GET_WIDGET_GROUPS_FAILED:
        return {
            ...state,
            getWidgetGroupsLoading: false,
            getWidgetGroupsFulfilled: false,
            getWidgetGroupsFailed: true,
        };


    case CREATE_WIDGET_GROUP_LOADING:
        return {
            ...state,
            createWidgetGroupLoading: true,
            createWidgetGroupFulfilled: false,
            createWidgetGroupFailed: false,
        };

    case CREATE_WIDGET_GROUP_FULFILLED:
        return {
            ...state,
            createWidgetGroupLoading: false,
            createWidgetGroupFulfilled: true,
            createWidgetGroupFailed: false,
            createWidgetGroupResponse: action?.payload,
        };

    case CREATE_WIDGET_GROUP_FAILED:
        return {
            ...state,
            createWidgetGroupLoading: false,
            createWidgetGroupFulfilled: false,
            createWidgetGroupFailed: true,
            createWidgetGroupResponse: action?.payload,
        };

    case CREATE_WIDGET_GROUP_RESET:
        return {
            ...state,
            createWidgetGroupLoading: initialState.createWidgetGroupLoading,
            createWidgetGroupFulfilled: initialState.createWidgetGroupFulfilled,
            createWidgetGroupFailed: initialState.createWidgetGroupFailed,
            createWidgetGroupResponse: initialState.createWidgetGroupResponse
        };


    case ARCHIVE_WIDGET_GROUP_LOADING:
        return {
            ...state,
            archiveWidgetGroupLoading: true,
            archiveWidgetGroupFulfilled: false,
            archiveWidgetGroupFailed: false,
        };

    case ARCHIVE_WIDGET_GROUP_FULFILLED: {
        const tempGetWidgetGroupsResponse = {...state.getWidgetGroupsResponse};
        tempGetWidgetGroupsResponse.list = tempGetWidgetGroupsResponse.list || [];
        const archivedWidgetGroupIndex = tempGetWidgetGroupsResponse.list.findIndex(modalDialog => (modalDialog?._id===action?.payload?._id));
        if (archivedWidgetGroupIndex>=0) {
            tempGetWidgetGroupsResponse.list.splice(archivedWidgetGroupIndex, 1);
        }

        return {
            ...state,
            archiveWidgetGroupLoading: false,
            archiveWidgetGroupFulfilled: true,
            archiveWidgetGroupFailed: false,
            archiveWidgetGroupResponse: action?.payload,
            getWidgetGroupsResponse: tempGetWidgetGroupsResponse
        };
    }

    case ARCHIVE_WIDGET_GROUP_FAILED:
        return {
            ...state,
            archiveWidgetGroupLoading: false,
            archiveWidgetGroupFulfilled: false,
            archiveWidgetGroupFailed: true,
            archiveWidgetGroupResponse: action?.payload,
        };

    case ARCHIVE_WIDGET_GROUP_RESET:
        return {
            ...state,
            archiveWidgetGroupLoading: initialState.archiveWidgetGroupLoading,
            archiveWidgetGroupFulfilled: initialState.archiveWidgetGroupFulfilled,
            archiveWidgetGroupFailed: initialState.archiveWidgetGroupFailed,
            archiveWidgetGroupResponse: initialState.archiveWidgetGroupResponse
        };


    case UPDATE_WIDGET_GROUP_LOADING:
        return {
            ...state,
            updateWidgetGroupLoading: true,
            updateWidgetGroupFulfilled: false,
            updateWidgetGroupFailed: false,
        };

    case UPDATE_WIDGET_GROUP_FULFILLED:
        return {
            ...state,
            updateWidgetGroupLoading: false,
            updateWidgetGroupFulfilled: true,
            updateWidgetGroupFailed: false,
            updateWidgetGroupResponse: action?.payload,
        };

    case UPDATE_WIDGET_GROUP_FAILED:
        return {
            ...state,
            updateWidgetGroupLoading: false,
            updateWidgetGroupFulfilled: false,
            updateWidgetGroupFailed: true,
            updateWidgetGroupResponse: action?.payload,
        };

    case UPDATE_WIDGET_GROUP_RESET:
        return {
            ...state,
            updateWidgetGroupLoading: initialState.updateWidgetGroupLoading,
            updateWidgetGroupFulfilled: initialState.updateWidgetGroupFulfilled,
            updateWidgetGroupFailed: initialState.updateWidgetGroupFailed,
            updateWidgetGroupResponse: initialState.updateWidgetGroupResponse
        };


    case GET_WIDGET_GROUP_DETAILS_LOADING:
        return {
            ...state,
            getWidgetGroupDetailsLoading: true,
            getWidgetGroupDetailsFulfilled: false,
            getWidgetGroupDetailsFailed: false,
        };

    case GET_WIDGET_GROUP_DETAILS_FULFILLED: {
        const tempGetWidgetGroupDetailsResponse = state.getWidgetGroupDetailsResponse || {};
        const _id = action?.payload?._id;
        if (_id) {
            tempGetWidgetGroupDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getWidgetGroupDetailsLoading: false,
            getWidgetGroupDetailsFulfilled: true,
            getWidgetGroupDetailsFailed: false,
            getWidgetGroupDetailsResponse: tempGetWidgetGroupDetailsResponse,
        };
    }

    case GET_WIDGET_GROUP_DETAILS_FAILED:
        return {
            ...state,
            getWidgetGroupDetailsLoading: false,
            getWidgetGroupDetailsFulfilled: false,
            getWidgetGroupDetailsFailed: true,
            getWidgetGroupDetailsResponse: action?.payload,
        };


    default:
        return state;
    }
};

export default WidgetGroupsReducer;
