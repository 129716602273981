// Libraries
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

// Components
import CenterModal from '../../layout/CenterModal';
import CustomCta from '../../elements/CustomCta';

// Actions
import {hideConfirmationModal} from '../../store/actions/globalActions';

export default function ConfirmationModal() {
    const dispatch = useDispatch();
    const globalData = useSelector((state) => state?.globalData);
    const {confirmationModalConfig} = globalData;

    const cancelModal = () => {
        dispatch(hideConfirmationModal());
    };

    return (
        <CenterModal show={true} onHide={cancelModal}>
            <div className='paddTop--l1 paddBottom--l1' style={{maxWidth: 500}}>
                <div className='sectionHeading color--primary marginBottom--l2'
                    style={confirmationModalConfig?.titleAlignment ? {textAlign: confirmationModalConfig?.titleAlignment} : null}>{confirmationModalConfig.title}</div>

                <div className='color--secondary text__align--center'>{confirmationModalConfig.description}</div>

                <div className='display--flex flex__justifyContent--center marginTop--l4'>
                    {!confirmationModalConfig?.hideScondaryCta && <CustomCta
                        className={!confirmationModalConfig?.hidePrimaryCta ? 'marginRight--l2' : ''}
                        type={'secondary'}
                        size={'big'}
                        text={confirmationModalConfig?.secondaryCtaText || 'No'}
                        onAction={() => {
                            if (confirmationModalConfig?.onSecondaryCtaAction) {
                                confirmationModalConfig.onSecondaryCtaAction();
                            }
                            cancelModal();
                        }}/>}

                    {!confirmationModalConfig?.hidePrimaryCta && <CustomCta
                        type={'primary'}
                        size={'big'}
                        text={confirmationModalConfig.primaryCtaText || 'Yes'}
                        onAction={() => {
                            if (confirmationModalConfig.onPrimaryCtaAction) {
                                confirmationModalConfig.onPrimaryCtaAction();
                            }
                            cancelModal();
                        }}/>}
                </div>
            </div>
        </CenterModal>
    );
}