import {
    ARCHIVE_FEATURE_FAILED,
    ARCHIVE_FEATURE_FULFILLED,
    ARCHIVE_FEATURE_LOADING,
    ARCHIVE_FEATURE_RESET,
    CREATE_FEATURE_FAILED,
    CREATE_FEATURE_FULFILLED,
    CREATE_FEATURE_LOADING,
    CREATE_FEATURE_RESET,
    GET_FEATURE_DETAILS_FAILED,
    GET_FEATURE_DETAILS_FULFILLED,
    GET_FEATURE_DETAILS_LOADING,
    GET_FEATURES_FAILED,
    GET_FEATURES_FULFILLED,
    GET_FEATURES_LOADING,
    UPDATE_FEATURE_FAILED,
    UPDATE_FEATURE_FULFILLED,
    UPDATE_FEATURE_LOADING,
    UPDATE_FEATURE_RESET
} from '../types';

const initialState = {
    getFeaturesLoading: false,
    getFeaturesFulfilled: false,
    getFeaturesFailed: false,
    getFeaturesResponse: {},

    createFeatureLoading: false,
    createFeatureFulfilled: false,
    createFeatureFailed: false,
    createFeatureResponse: {},

    updateFeatureLoading: false,
    updateFeatureFulfilled: false,
    updateFeatureFailed: false,
    updateFeatureResponse: {},

    archiveFeatureLoading: false,
    archiveFeatureFulfilled: false,
    archiveFeatureFailed: false,
    archiveFeatureResponse: {},

    getFeatureDetailsLoading: false,
    getFeatureDetailsFulfilled: false,
    getFeatureDetailsFailed: false,
    getFeatureDetailsResponse: {}
};

const featureReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FEATURES_LOADING:
            return {
                ...state,
                getFeaturesLoading: true,
                getFeaturesFulfilled: false,
                getFeaturesFailed: false,
            };

        case GET_FEATURES_FULFILLED:
            const tempGetFeaturesResponse = {...state.getFeaturesResponse};
            tempGetFeaturesResponse.offset = action?.payload?.offset;
            tempGetFeaturesResponse.total = action?.payload?.total;
            tempGetFeaturesResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetFeaturesResponse.list = (tempGetFeaturesResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetFeaturesResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getFeaturesLoading: false,
                getFeaturesFulfilled: true,
                getFeaturesFailed: false,
                getFeaturesResponse: tempGetFeaturesResponse,
            };

        case GET_FEATURES_FAILED:
            return {
                ...state,
                getFeaturesLoading: false,
                getFeaturesFulfilled: false,
                getFeaturesFailed: true,
            };


        case CREATE_FEATURE_LOADING:
            return {
                ...state,
                createFeatureLoading: true,
                createFeatureFulfilled: false,
                createFeatureFailed: false,
            };

        case CREATE_FEATURE_FULFILLED:
            return {
                ...state,
                createFeatureLoading: false,
                createFeatureFulfilled: true,
                createFeatureFailed: false,
                createFeatureResponse: action?.payload,
            };

        case CREATE_FEATURE_FAILED:
            return {
                ...state,
                createFeatureLoading: false,
                createFeatureFulfilled: false,
                createFeatureFailed: true,
                createFeatureResponse: action?.payload,
            };

        case CREATE_FEATURE_RESET:
            return {
                ...state,
                createFeatureLoading: initialState.createFeatureLoading,
                createFeatureFulfilled: initialState.createFeatureFulfilled,
                createFeatureFailed: initialState.createFeatureFailed,
                createFeatureResponse: initialState.createFeatureResponse
            };


        case ARCHIVE_FEATURE_LOADING:
            return {
                ...state,
                archiveFeatureLoading: true,
                archiveFeatureFulfilled: false,
                archiveFeatureFailed: false,
            };

        case ARCHIVE_FEATURE_FULFILLED: {
            const tempGetFeaturesResponse = {...state.getFeaturesResponse};
            tempGetFeaturesResponse.list = tempGetFeaturesResponse.list || [];
            const archivedFeatureIndex = tempGetFeaturesResponse.list.findIndex(activity => (activity?._id === action?.payload?._id));
            if (archivedFeatureIndex >= 0) {
                tempGetFeaturesResponse.list.splice(archivedFeatureIndex, 1);
            }

            return {
                ...state,
                archiveFeatureLoading: false,
                archiveFeatureFulfilled: true,
                archiveFeatureFailed: false,
                archiveFeatureResponse: action?.payload,
                getFeaturesResponse: tempGetFeaturesResponse
            };
        }

        case ARCHIVE_FEATURE_FAILED:
            return {
                ...state,
                archiveFeatureLoading: false,
                archiveFeatureFulfilled: false,
                archiveFeatureFailed: true,
                archiveFeatureResponse: action?.payload,
            };

        case ARCHIVE_FEATURE_RESET:
            return {
                ...state,
                archiveFeatureLoading: initialState.archiveFeatureLoading,
                archiveFeatureFulfilled: initialState.archiveFeatureFulfilled,
                archiveFeatureFailed: initialState.archiveFeatureFailed,
                archiveFeatureResponse: initialState.archiveFeatureResponse
            };


        case UPDATE_FEATURE_LOADING:
            return {
                ...state,
                updateFeatureLoading: true,
                updateFeatureFulfilled: false,
                updateFeatureFailed: false,
            };

        case UPDATE_FEATURE_FULFILLED:
            return {
                ...state,
                updateFeatureLoading: false,
                updateFeatureFulfilled: true,
                updateFeatureFailed: false,
                updateFeatureResponse: action?.payload,
            };

        case UPDATE_FEATURE_FAILED:
            return {
                ...state,
                updateFeatureLoading: false,
                updateFeatureFulfilled: false,
                updateFeatureFailed: true,
                updateFeatureResponse: action?.payload,
            };

        case UPDATE_FEATURE_RESET:
            return {
                ...state,
                updateFeatureLoading: initialState.updateFeatureLoading,
                updateFeatureFulfilled: initialState.updateFeatureFulfilled,
                updateFeatureFailed: initialState.updateFeatureFailed,
                updateFeatureResponse: initialState.updateFeatureResponse
            };


        case GET_FEATURE_DETAILS_LOADING:
            return {
                ...state,
                getFeatureDetailsLoading: true,
                getFeatureDetailsFulfilled: false,
                getFeatureDetailsFailed: false,
            };

        case GET_FEATURE_DETAILS_FULFILLED: {
            const tempGetFeatureDetailsResponse = state.getFeatureDetailsResponse || {};

            const activityId = action?.payload?._id;
            console.log(activityId);
            console.log('HERERE');
            if (activityId) {
                tempGetFeatureDetailsResponse[activityId] = action?.payload;
            }

            return {
                ...state,
                getFeatureDetailsLoading: false,
                getFeatureDetailsFulfilled: true,
                getFeatureDetailsFailed: false,
                getFeatureDetailsResponse: tempGetFeatureDetailsResponse,
            };
        }

        case GET_FEATURE_DETAILS_FAILED:
            return {
                ...state,
                getFeatureDetailsLoading: false,
                getFeatureDetailsFulfilled: false,
                getFeatureDetailsFailed: true,
            };


        default:
            return state;
    }
};

export default featureReducer;
