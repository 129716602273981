// Libraries
import React from 'react';
import Link from 'next/link';
import CustomInputStyleWrapper from '../layout/CustomInputStyleWrapper';

// Styles
import classes from './CustomCta.module.scss';

export default function CustomCta({
    className,
    type,
    text,
    leftIcon,
    rightIcon,
    link,
    onAction,
    isActive,
    autoHeight,
    isDisabled
}) {
    const renderCtaBody = () => {
        return (
            <CustomInputStyleWrapper
                className={
                    'display--flex flex__alignItems--center flex__justifyContent--center ' +
                    classes.cta + ' ' +
                    (classes['cta--' + type]) + ' ' +
                    (isActive ? classes.active : '') + ' ' +
                    (isDisabled ? classes.disabled : '') + ' ' +
                    className
                }
                borderHighlighted={isActive}
                onAction={() => {
                    if (onAction && !isDisabled) {
                        onAction();
                    }
                }}
                isRounded={true}
                autoHeight={autoHeight}
                isDisabled={isDisabled}
            >
                {leftIcon}
                <span className=''>{text}</span>
                {rightIcon}
            </CustomInputStyleWrapper>
        );
    };

    if (link) {
        return (
            <Link href={link}>
                <a>{renderCtaBody()}</a>
            </Link>
        );
    }

    return (
        renderCtaBody()
    );
}