import axios from 'axios';

const requestHandler = config => {
    const token = localStorage && localStorage.getItem('access_token');
    // const env = localStorage && localStorage.getItem('env') ? localStorage.getItem('env') : 'dev';

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
};

const responseHandler = response => {
    return response;
};

const errorHandler = error => {
    return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance) {
    axiosInstance.interceptors.request.use(
        (config) => requestHandler(config),
        (error) => errorHandler(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => responseHandler(response),
        (error) => errorHandler(error)
    );

    return axiosInstance;
}

export const hokPanelAxios = setupInterceptorsTo(axios.create({
    baseURL: process.env.NEXT_PUBLIC_HOK_API_BASE,
    timeout: 10000,
    headers: {},
    withCredentials: true
}));

export const famplayAxios = setupInterceptorsTo(axios.create({
    baseURL: process.env.NEXT_PUBLIC_FAMPLAY_API_BASE,
    timeout: 10000,
    headers: {},
    withCredentials: true
}));

export const famplayAdminAxios = setupInterceptorsTo(axios.create({
    baseURL: process.env.NEXT_PUBLIC_FAMPLAY_API_BASE,
    timeout: 10000,
    headers: {},
    withCredentials: true
}));

export const killSwitchAxios = setupInterceptorsTo(axios.create({
    baseURL: process.env.NEXT_PUBLIC_KILL_SWITCH_API_BASE,
    timeout: 10000,
    headers: {},
    withCredentials: true
}));