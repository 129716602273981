import {
    GET_NAMASPAY_PARTNERS_LOADING,
    GET_NAMASPAY_PARTNERS_FULFILLED,
    GET_NAMASPAY_PARTNERS_FAILED,
    GET_NAMASPAY_PARTNERS_RESET,
    CREATE_NAMASPAY_PARTNER_LOADING,
    CREATE_NAMASPAY_PARTNER_FULFILLED,
    CREATE_NAMASPAY_PARTNER_FAILED,
    CREATE_NAMASPAY_PARTNER_RESET,
    GET_NAMASPAY_PARTNER_DETAILS_LOADING,
    GET_NAMASPAY_PARTNER_DETAILS_FULFILLED,
    GET_NAMASPAY_PARTNER_DETAILS_FAILED,
    GET_NAMASPAY_PARTNER_DETAILS_RESET,
    ARCHIVE_NAMASPAY_PARTNER_LOADING,
    ARCHIVE_NAMASPAY_PARTNER_FULFILLED,
    ARCHIVE_NAMASPAY_PARTNER_FAILED,
    ARCHIVE_NAMASPAY_PARTNER_RESET,
    UPDATE_NAMASPAY_PARTNER_LOADING,
    UPDATE_NAMASPAY_PARTNER_FULFILLED,
    UPDATE_NAMASPAY_PARTNER_FAILED,
    UPDATE_NAMASPAY_PARTNER_RESET
} from '../types';

const initialState = {
    getPartnersLoading: false,
    getPartnersFulfilled: false,
    getPartnersFailed: false,
    getPartnersResponse: {},

    createPartnerLoading: false,
    createPartnerFulfilled: false,
    createPartnerFailed: false,
    createPartnerResponse: {},

    updatePartnerLoading: false,
    updatePartnerFulfilled: false,
    updatePartnerFailed: false,
    updatePartnerResponse: {},

    archivePartnerLoading: false,
    archivePartnerFulfilled: false,
    archivePartnerFailed: false,
    archivePartnerResponse: {},

    getPartnerDetailsLoading: false,
    getPartnerDetailsFulfilled: false,
    getPartnerDetailsFailed: false,
    getPartnerDetailsResponse: {}
};

const partnerReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_NAMASPAY_PARTNERS_LOADING:
        return {
            ...state,
            getPartnersLoading: true,
            getPartnersFulfilled: false,
            getPartnersFailed: false,
        };

    case GET_NAMASPAY_PARTNERS_FULFILLED: {
        const tempGetPartnersResponse = {...state.getPartnersResponse};
        tempGetPartnersResponse.offset = action?.payload?.offset;
        tempGetPartnersResponse.total = action?.payload?.total;
        tempGetPartnersResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetPartnersResponse.list = (tempGetPartnersResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetPartnersResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getPartnersLoading: false,
            getPartnersFulfilled: true,
            getPartnersFailed: false,
            getPartnersResponse: tempGetPartnersResponse,
        };
    }

    case GET_NAMASPAY_PARTNERS_FAILED:
        return {
            ...state,
            getPartnersLoading: false,
            getPartnersFulfilled: false,
            getPartnersFailed: true,
        };

    case GET_NAMASPAY_PARTNERS_RESET:
        return {
            ...state,
            getPartnersLoading: initialState.getPartnersLoading,
            getPartnersFulfilled: initialState.getPartnersFulfilled,
            getPartnersFailed: initialState.getPartnersFailed,
            getPartnersResponse: initialState.getPartnersResponse
        };

    case CREATE_NAMASPAY_PARTNER_LOADING:
        return {
            ...state,
            createPartnerLoading: true,
            createPartnerFulfilled: false,
            createPartnerFailed: false,
        };

    case CREATE_NAMASPAY_PARTNER_FULFILLED:
        return {
            ...state,
            createPartnerLoading: false,
            createPartnerFulfilled: true,
            createPartnerFailed: false,
            createPartnerResponse: action?.payload,
        };

    case CREATE_NAMASPAY_PARTNER_FAILED:
        return {
            ...state,
            createPartnerLoading: false,
            createPartnerFulfilled: false,
            createPartnerFailed: true,
            createPartnerResponse: action?.payload,
        };

    case CREATE_NAMASPAY_PARTNER_RESET:
        return {
            ...state,
            createPartnerLoading: initialState.createPartnerLoading,
            createPartnerFulfilled: initialState.createPartnerFulfilled,
            createPartnerFailed: initialState.createPartnerFailed,
            createPartnerResponse: initialState.createPartnerResponse
        };


    case ARCHIVE_NAMASPAY_PARTNER_LOADING:
        return {
            ...state,
            archivePartnerLoading: true,
            archivePartnerFulfilled: false,
            archivePartnerFailed: false,
        };

    case ARCHIVE_NAMASPAY_PARTNER_FULFILLED: {
        const tempGetPartnersResponse = {...state.getPartnersResponse};
        tempGetPartnersResponse.list = tempGetPartnersResponse.list || [];
        const archivedPartnerIndex = tempGetPartnersResponse.list.findIndex(activity => (activity?._id===action?.payload?._id));
        if (archivedPartnerIndex>=0) {
            tempGetPartnersResponse.list.splice(archivedPartnerIndex, 1);
        }

        return {
            ...state,
            archivePartnerLoading: false,
            archivePartnerFulfilled: true,
            archivePartnerFailed: false,
            archivePartnerResponse: action?.payload,
            getPartnersResponse: tempGetPartnersResponse
        };
    }

    case ARCHIVE_NAMASPAY_PARTNER_FAILED:
        return {
            ...state,
            archivePartnerLoading: false,
            archivePartnerFulfilled: false,
            archivePartnerFailed: true,
            archivePartnerResponse: action?.payload,
        };

    case ARCHIVE_NAMASPAY_PARTNER_RESET:
        return {
            ...state,
            archivePartnerLoading: initialState.archivePartnerLoading,
            archivePartnerFulfilled: initialState.archivePartnerFulfilled,
            archivePartnerFailed: initialState.archivePartnerFailed,
            archivePartnerResponse: initialState.archivePartnerResponse
        };


    case UPDATE_NAMASPAY_PARTNER_LOADING:
        return {
            ...state,
            updatePartnerLoading: true,
            updatePartnerFulfilled: false,
            updatePartnerFailed: false,
        };

    case UPDATE_NAMASPAY_PARTNER_FULFILLED:
        return {
            ...state,
            updatePartnerLoading: false,
            updatePartnerFulfilled: true,
            updatePartnerFailed: false,
            updatePartnerResponse: action?.payload,
        };

    case UPDATE_NAMASPAY_PARTNER_FAILED:
        return {
            ...state,
            updatePartnerLoading: false,
            updatePartnerFulfilled: false,
            updatePartnerFailed: true,
            updatePartnerResponse: action?.payload,
        };

    case UPDATE_NAMASPAY_PARTNER_RESET:
        return {
            ...state,
            updatePartnerLoading: initialState.updatePartnerLoading,
            updatePartnerFulfilled: initialState.updatePartnerFulfilled,
            updatePartnerFailed: initialState.updatePartnerFailed,
            updatePartnerResponse: initialState.updatePartnerResponse
        };


    case GET_NAMASPAY_PARTNER_DETAILS_LOADING:
        return {
            ...state,
            getPartnerDetailsLoading: true,
            getPartnerDetailsFulfilled: false,
            getPartnerDetailsFailed: false,
        };

    case GET_NAMASPAY_PARTNER_DETAILS_FULFILLED: {
        const tempGetPartnerDetailsResponse = state.getPartnerDetailsResponse || {};
        const partnerId = action?.payload?._id;
        if (partnerId) {
            tempGetPartnerDetailsResponse[partnerId] = action?.payload;
        }

        return {
            ...state,
            getPartnerDetailsLoading: false,
            getPartnerDetailsFulfilled: true,
            getPartnerDetailsFailed: false,
            getPartnerDetailsResponse: tempGetPartnerDetailsResponse,
        };
    }

    case GET_NAMASPAY_PARTNER_DETAILS_FAILED:
        return {
            ...state,
            getPartnerDetailsLoading: false,
            getPartnerDetailsFulfilled: false,
            getPartnerDetailsFailed: true,
        };

    case GET_NAMASPAY_PARTNER_DETAILS_RESET:
        return {
            ...state,
            getPartnerDetailsLoading: initialState.getPartnerDetailsLoading,
            getPartnerDetailsFulfilled: initialState.getPartnerDetailsFulfilled,
            getPartnerDetailsFailed: initialState.getPartnerDetailsFailed,
            getPartnerDetailsResponse: initialState.getPartnerDetailsResponse
        };
    default:
        return state;
    }
};

export default partnerReducer;
