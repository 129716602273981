import {
    ARCHIVE_BOT_FAILED,
    ARCHIVE_BOT_FULFILLED,
    ARCHIVE_BOT_LOADING,
    ARCHIVE_BOT_RESET,
    CREATE_BOT_FAILED,
    CREATE_BOT_FULFILLED,
    CREATE_BOT_LOADING,
    CREATE_BOT_RESET,
    GET_BOT_DETAILS_FAILED,
    GET_BOT_DETAILS_FULFILLED,
    GET_BOT_DETAILS_LOADING,
    GET_BOTS_FAILED,
    GET_BOTS_FULFILLED,
    GET_BOTS_LOADING,
    UPDATE_BOT_FAILED,
    UPDATE_BOT_FULFILLED,
    UPDATE_BOT_LOADING,
    UPDATE_BOT_RESET
} from '../types';

const initialState = {
    getBotsLoading: false,
    getBotsFulfilled: false,
    getBotsFailed: false,
    getBotsResponse: {},

    createBotLoading: false,
    createBotFulfilled: false,
    createBotFailed: false,
    createBotResponse: {},

    updateBotLoading: false,
    updateBotFulfilled: false,
    updateBotFailed: false,
    updateBotResponse: {},

    archiveBotLoading: false,
    archiveBotFulfilled: false,
    archiveBotFailed: false,
    archiveBotResponse: {},

    getBotDetailsLoading: false,
    getBotDetailsFulfilled: false,
    getBotDetailsFailed: false,
    getBotDetailsResponse: {}
};

const famBotReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOTS_LOADING:
            return {
                ...state,
                getBotsLoading: true,
                getBotsFulfilled: false,
                getBotsFailed: false,
            };

        case GET_BOTS_FULFILLED:
            const tempGetBotsResponse = {...state.getBotsResponse};
            tempGetBotsResponse.offset = action?.payload?.offset;
            tempGetBotsResponse.total = action?.payload?.total;
            tempGetBotsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetBotsResponse.bots = (tempGetBotsResponse?.bots || []).concat(action?.payload?.botsv || []);
            } else {
                tempGetBotsResponse.bots = action?.payload?.bots || [];
            }

            return {
                ...state,
                getBotsLoading: false,
                getBotsFulfilled: true,
                getBotsFailed: false,
                getBotsResponse: tempGetBotsResponse,
            };

        case GET_BOTS_FAILED:
            return {
                ...state,
                getBotsLoading: false,
                getBotsFulfilled: false,
                getBotsFailed: true,
            };


        case CREATE_BOT_LOADING:
            return {
                ...state,
                createBotLoading: true,
                createBotFulfilled: false,
                createBotFailed: false,
            };

        case CREATE_BOT_FULFILLED:
            return {
                ...state,
                createBotLoading: false,
                createBotFulfilled: true,
                createBotFailed: false,
                createBotResponse: action?.payload,
            };

        case CREATE_BOT_FAILED:
            return {
                ...state,
                createBotLoading: false,
                createBotFulfilled: false,
                createBotFailed: true,
                createBotResponse: action?.payload,
            };

        case CREATE_BOT_RESET:
            return {
                ...state,
                createBotLoading: initialState.createBotLoading,
                createBotFulfilled: initialState.createBotFulfilled,
                createBotFailed: initialState.createBotFailed,
                createBotResponse: initialState.createBotResponse
            };


        case ARCHIVE_BOT_LOADING:
            return {
                ...state,
                archiveBotLoading: true,
                archiveBotFulfilled: false,
                archiveBotFailed: false,
            };

        case ARCHIVE_BOT_FULFILLED: {
            const tempGetBotsResponse = {...state.getBotsResponse};
            tempGetBotsResponse.list = tempGetBotsResponse.list || [];
            const archivedBotIndex = tempGetBotsResponse.list.findIndex(bot => (bot?._id === action?.payload?._id));
            if (archivedBotIndex >= 0) {
                tempGetBotsResponse.list.splice(archivedBotIndex, 1);
            }

            return {
                ...state,
                archiveBotLoading: false,
                archiveBotFulfilled: true,
                archiveBotFailed: false,
                archiveBotResponse: action?.payload,
                getBotsResponse: tempGetBotsResponse
            };
        }

        case ARCHIVE_BOT_FAILED:
            return {
                ...state,
                archiveBotLoading: false,
                archiveBotFulfilled: false,
                archiveBotFailed: true,
                archiveBotResponse: action?.payload,
            };

        case ARCHIVE_BOT_RESET:
            return {
                ...state,
                archiveBotLoading: initialState.archiveBotLoading,
                archiveBotFulfilled: initialState.archiveBotFulfilled,
                archiveBotFailed: initialState.archiveBotFailed,
                archiveBotResponse: initialState.archiveBotResponse
            };


        case UPDATE_BOT_LOADING:
            return {
                ...state,
                updateBotLoading: true,
                updateBotFulfilled: false,
                updateBotFailed: false,
            };

        case UPDATE_BOT_FULFILLED:
            return {
                ...state,
                updateBotLoading: false,
                updateBotFulfilled: true,
                updateBotFailed: false,
                updateBotResponse: action?.payload,
            };

        case UPDATE_BOT_FAILED:
            return {
                ...state,
                updateBotLoading: false,
                updateBotFulfilled: false,
                updateBotFailed: true,
                updateBotResponse: action?.payload,
            };

        case UPDATE_BOT_RESET:
            return {
                ...state,
                updateBotLoading: initialState.updateBotLoading,
                updateBotFulfilled: initialState.updateBotFulfilled,
                updateBotFailed: initialState.updateBotFailed,
                updateBotResponse: initialState.updateBotResponse
            };


        case GET_BOT_DETAILS_LOADING:
            return {
                ...state,
                getBotDetailsLoading: true,
                getBotDetailsFulfilled: false,
                getBotDetailsFailed: false,
            };

        case GET_BOT_DETAILS_FULFILLED: {
            const tempGetBotDetailsResponse = state.getBotDetailsResponse || {};
            const activityId = action?.payload?._id;
            if (activityId) {
                tempGetBotDetailsResponse[activityId] = action?.payload;
            }

            return {
                ...state,
                getBotDetailsLoading: false,
                getBotDetailsFulfilled: true,
                getBotDetailsFailed: false,
                getBotDetailsResponse: tempGetBotDetailsResponse,
            };
        }

        case GET_BOT_DETAILS_FAILED:
            return {
                ...state,
                getBotDetailsLoading: false,
                getBotDetailsFulfilled: false,
                getBotDetailsFailed: true,
            };


        default:
            return state;
    }
};

export default famBotReducer;
