// Libraries
import React from 'react';

// Styles
import classes from '../styles/modals.module.scss';

export default function CenterModal(props) {
    const {show, onHide, heading, showCloseButton, noPadding} = props;

    return (
        <div className={classes.modalWrapper + ' ' + (show ? classes.show : '')}
            onClick={() => {
                onHide();
            }}>
            <div className={classes.centerModal} onClick={(e) => {
                e.stopPropagation();
            }}>
                {
                    (heading || showCloseButton) &&
                    <div
                        className={classes.centerModalPadd__x + ' ' + classes.centerModalPadd__y + ' display--flex flex__justifyContent--between flex__alignItems--center'}>
                        <div className='sectionHeading'>{heading}</div>
                        {
                            showCloseButton &&
                            <span className={classes.closeIcon + ' icon-close cursor--pointer marginLeft--l2'}
                                onClick={() => {
                                    onHide();
                                }}>
                            </span>
                        }
                    </div>
                }
                <div
                    className={classes.centerModalPadd__x + ' ' + ((heading || showCloseButton) ? classes.centerModalPadd__bottom : classes.centerModalPadd__y) + ' ' + (noPadding ? classes.centerModalNoPadding : '')}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
