import {
    GET_GLOBAL_GIFT_CARDS_FAILED,
    GET_GLOBAL_GIFT_CARDS_FULFILLED,
    GET_GLOBAL_GIFT_CARDS_LOADING,
    RESET_GLOBAL_GIFT_CARDS,
    SET_GLOBAL_GIFT_CARDS_FAILED,
    SET_GLOBAL_GIFT_CARDS_FULFILLED,
    SET_GLOBAL_GIFT_CARDS_LOADING,
    GET_GLOBAL_PARTNERSHIPS_FAILED,
    GET_GLOBAL_PARTNERSHIPS_FULFILLED,
    GET_GLOBAL_PARTNERSHIPS_LOADING,
    RESET_GLOBAL_PARTNERSHIPS,
    SET_GLOBAL_PARTNERSHIPS_FAILED,
    SET_GLOBAL_PARTNERSHIPS_FULFILLED,
    SET_GLOBAL_PARTNERSHIPS_LOADING
} from '../types';

const initialState = {
    setGlobalGiftCardsLoading: false,
    setGlobalGiftCardsFulfilled: false,
    setGlobalGiftCardsFailed: false,
    setGlobalGiftCardsResponse: null,
    getGlobalGiftCardsLoading: false,
    getGlobalGiftCardsFulfilled: false,
    getGlobalGiftCardsFailed: false,
    getGlobalGiftCardsResponse: {},
    setGlobalPartnershipsLoading: false,
    setGlobalPartnershipsFulfilled: false,
    setGlobalPartnershipsFailed: false,
    setGlobalPartnershipsResponse: null,
    getGlobalPartnershipsLoading: false,
    getGlobalPartnershipsFulfilled: false,
    getGlobalPartnershipsFailed: false,
    getGlobalPartnershipsResponse: {},
};

const allianceRavensReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GLOBAL_GIFT_CARDS_LOADING:
        return {
            ...state,
            setGlobalGiftCardsLoading: true,
            setGlobalGiftCardsFulfilled: false,
            setGlobalGiftCardsFailed: false,
            setGlobalGiftCardsResponse: null
        };

    case SET_GLOBAL_GIFT_CARDS_FULFILLED:
        return {
            ...state,
            setGlobalGiftCardsLoading: false,
            setGlobalGiftCardsFulfilled: true,
            setGlobalGiftCardsFailed: false,
            setGlobalGiftCardsResponse: action?.payload,
            getGlobalGiftCardsResponse: action?.payload,
        };

    case SET_GLOBAL_GIFT_CARDS_FAILED:
        return {
            ...state,
            setGlobalGiftCardsLoading: false,
            setGlobalGiftCardsFulfilled: false,
            setGlobalGiftCardsFailed: true,
            setGlobalGiftCardsResponse: action?.payload,
        };

    case RESET_GLOBAL_GIFT_CARDS:
        return {
            ...state,
            setGlobalGiftCardsLoading: false,
            setGlobalGiftCardsFulfilled: false,
            setGlobalGiftCardsFailed: false,
            setGlobalGiftCardsResponse: null
        };

    case GET_GLOBAL_GIFT_CARDS_LOADING:
        return {
            ...state,
            getGlobalGiftCardsLoading: true,
            getGlobalGiftCardsFulfilled: false,
            getGlobalGiftCardsFailed: false,
        };

    case GET_GLOBAL_GIFT_CARDS_FULFILLED:
        return {
            ...state,
            getGlobalGiftCardsLoading: false,
            getGlobalGiftCardsFulfilled: true,
            getGlobalGiftCardsFailed: false,
            getGlobalGiftCardsResponse: action?.payload,
            setGlobalGiftCardsResponse: action?.payload
        };

    case GET_GLOBAL_GIFT_CARDS_FAILED:
        return {
            ...state,
            getGlobalGiftCardsLoading: false,
            getGlobalGiftCardsFulfilled: false,
            getGlobalGiftCardsFailed: true,
        };

    case SET_GLOBAL_PARTNERSHIPS_LOADING:
        return {
            ...state,
            setGlobalPartnershipsLoading: true,
            setGlobalPartnershipsFulfilled: false,
            setGlobalPartnershipsFailed: false,
            setGlobalPartnershipsResponse: null
        };

    case SET_GLOBAL_PARTNERSHIPS_FULFILLED:
        return {
            ...state,
            setGlobalPartnershipsLoading: false,
            setGlobalPartnershipsFulfilled: true,
            setGlobalPartnershipsFailed: false,
            setGlobalPartnershipsResponse: action?.payload,
            getGlobalPartnershipsResponse: action?.payload,
        };

    case SET_GLOBAL_PARTNERSHIPS_FAILED:
        return {
            ...state,
            setGlobalPartnershipsLoading: false,
            setGlobalPartnershipsFulfilled: false,
            setGlobalPartnershipsFailed: true,
            setGlobalPartnershipsResponse: action?.payload,
        };

    case RESET_GLOBAL_PARTNERSHIPS:
        return {
            ...state,
            setGlobalPartnershipsLoading: false,
            setGlobalPartnershipsFulfilled: false,
            setGlobalPartnershipsFailed: false,
            setGlobalPartnershipsResponse: null
        };

    case GET_GLOBAL_PARTNERSHIPS_LOADING:
        return {
            ...state,
            getGlobalPartnershipsLoading: true,
            getGlobalPartnershipsFulfilled: false,
            getGlobalPartnershipsFailed: false,
        };

    case GET_GLOBAL_PARTNERSHIPS_FULFILLED:
        return {
            ...state,
            getGlobalPartnershipsLoading: false,
            getGlobalPartnershipsFulfilled: true,
            getGlobalPartnershipsFailed: false,
            getGlobalPartnershipsResponse: action?.payload,
        };

    case GET_GLOBAL_PARTNERSHIPS_FAILED:
        return {
            ...state,
            getGlobalPartnershipsLoading: false,
            getGlobalPartnershipsFulfilled: false,
            getGlobalPartnershipsFailed: true,
        };

    default:
        return state;
    }
};

export default allianceRavensReducer;
