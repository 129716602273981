import {
    GET_COHORTS_LOADING,
    GET_COHORTS_FULFILLED,
    GET_COHORTS_FAILED,

    SYNC_COHORTS_LOADING,
    SYNC_COHORTS_FULFILLED,
    SYNC_COHORTS_FAILED
} from '../types';

const initialState = {
    getCohortsLoading: false,
    getCohortsFulfilled: false,
    getCohortsFailed: false,
    getCohortsResponse: {},

    syncCohortsLoading: false,
    syncCohortsFulfilled: false,
    syncCohortsFailed: false,
    syncCohortsResponse: {}
};

const CohortsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_COHORTS_LOADING:
        return {
            ...state,
            getCohortsLoading: true,
            getCohortsFulfilled: false,
            getCohortsFailed: false,
        };

    case GET_COHORTS_FULFILLED: {
        const tempGetCohortsResponse = {...state.getCohortsResponse};
        tempGetCohortsResponse.offset = action?.payload?.offset;
        tempGetCohortsResponse.total = action?.payload?.total;
        tempGetCohortsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetCohortsResponse.list = (tempGetCohortsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetCohortsResponse.list = action?.payload?.list || [];
        }

        tempGetCohortsResponse?.list?.map(item => {
            item.value = item.slug || item.value;
            item.label = item.name || item.label;
        });

        return {
            ...state,
            getCohortsLoading: false,
            getCohortsFulfilled: true,
            getCohortsFailed: false,
            getCohortsResponse: tempGetCohortsResponse,
        };
    }

    case GET_COHORTS_FAILED:
        return {
            ...state,
            getCohortsLoading: false,
            getCohortsFulfilled: false,
            getCohortsFailed: true,
        };


    case SYNC_COHORTS_LOADING:
        return {
            ...state,
            syncCohortsLoading: true,
            syncCohortsFulfilled: false,
            syncCohortsFailed: false,
        };

    case SYNC_COHORTS_FULFILLED:
        return {
            ...state,
            syncCohortsLoading: false,
            syncCohortsFulfilled: true,
            syncCohortsFailed: false,
            syncCohortsResponse: action?.payload,
        };

    case SYNC_COHORTS_FAILED:
        return {
            ...state,
            syncCohortsLoading: false,
            syncCohortsFulfilled: false,
            syncCohortsFailed: true,
        };

    default:
        return state;
    }
};

export default CohortsReducer;
