import {
    GET_NAMASPAY_POC_MEMBERS_LOADING,
    GET_NAMASPAY_POC_MEMBERS_FULFILLED,
    GET_NAMASPAY_POC_MEMBERS_FAILED,

    INVITE_NAMASPAY_POC_MEMBERS_LOADING,
    INVITE_NAMASPAY_POC_MEMBERS_FULFILLED,
    INVITE_NAMASPAY_POC_MEMBERS_FAILED,
    INVITE_NAMASPAY_POC_MEMBERS_RESET,

    UPDATE_NAMASPAY_POC_MEMBER_DETAILS_LOADING,
    UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED,
    UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FAILED,
    UPDATE_NAMASPAY_POC_MEMBER_DETAILS_RESET,

    ARCHIVE_NAMASPAY_POC_MEMBER_LOADING,
    ARCHIVE_NAMASPAY_POC_MEMBER_FULFILLED,
    ARCHIVE_NAMASPAY_POC_MEMBER_FAILED,
    ARCHIVE_NAMASPAY_POC_MEMBER_RESET,

    GET_NAMASPAY_POC_MEMBER_DETAILS_LOADING,
    GET_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED,
    GET_NAMASPAY_POC_MEMBER_DETAILS_FAILED,

} from '../types';

const initialState = {
    getNamasPayPOCMembersLoading: false,
    getNamasPayPOCMembersFulfilled: false,
    getNamasPayPOCMembersFailed: false,
    getNamasPayPOCMembersResponse: {},

    inviteNamasPayPOCMembersLoading: false,
    inviteNamasPayPOCMembersFulfilled: false,
    inviteNamasPayPOCMembersFailed: false,
    inviteNamasPayPOCMembersResponse: {},

    updateNamasPayPOCMemberDetailsLoading: false,
    updateNamasPayPOCMemberDetailsFulfilled: false,
    updateNamasPayPOCMemberDetailsFailed: false,
    updateNamasPayPOCMemberDetailsResponse: {},

    archiveNamasPayPOCMemberLoading: false,
    archiveNamasPayPOCMemberFulfilled: false,
    archiveNamasPayPOCMemberFailed: false,
    archiveNamasPayPOCMemberResponse: {},

    getNamasPayPOCMemberDetailsLoading: false,
    getNamasPayPOCMemberDetailsFulfilled: false,
    getNamasPayPOCMemberDetailsFailed: false,
    getNamasPayPOCMemberDetailsResponse: {}
};

const namasPayPOCsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_NAMASPAY_POC_MEMBERS_LOADING:
        return {
            ...state,
            getNamasPayPOCMembersLoading: true,
            getNamasPayPOCMembersFulfilled: false,
            getNamasPayPOCMembersFailed: false,
        };

    case GET_NAMASPAY_POC_MEMBERS_FULFILLED: {
        const tempGetNamasPayPOCMembersResponse = {...state.getNamasPayPOCMembersResponse};
        tempGetNamasPayPOCMembersResponse.data = action?.payload;
        return {
            ...state,
            getNamasPayPOCMembersLoading: false,
            getNamasPayPOCMembersFulfilled: true,
            getNamasPayPOCMembersFailed: false,
            getNamasPayPOCMembersResponse: tempGetNamasPayPOCMembersResponse,
        };
    }
    case GET_NAMASPAY_POC_MEMBERS_FAILED:
        return {
            ...state,
            getNamasPayPOCMembersLoading: false,
            getNamasPayPOCMembersFulfilled: false,
            getNamasPayPOCMembersFailed: true,
        };

    case INVITE_NAMASPAY_POC_MEMBERS_LOADING:
        return {
            ...state,
            inviteNamasPayPOCMembersLoading: true,
            inviteNamasPayPOCMembersFulfilled: false,
            inviteNamasPayPOCMembersFailed: false,
        };

    case INVITE_NAMASPAY_POC_MEMBERS_FULFILLED:
        return {
            ...state,
            inviteNamasPayPOCMembersLoading: false,
            inviteNamasPayPOCMembersFulfilled: true,
            inviteNamasPayPOCMembersFailed: false,
            inviteNamasPayPOCMembersResponse: action?.payload,
        };

    case INVITE_NAMASPAY_POC_MEMBERS_FAILED:
        return {
            ...state,
            inviteNamasPayPOCMembersLoading: false,
            inviteNamasPayPOCMembersFulfilled: false,
            inviteNamasPayPOCMembersFailed: true,
            inviteNamasPayPOCMembersResponse: action?.payload,
        };

    case INVITE_NAMASPAY_POC_MEMBERS_RESET:
        return {
            ...state,
            inviteNamasPayPOCMembersLoading: initialState.inviteNamasPayPOCMembersLoading,
            inviteNamasPayPOCMembersFulfilled: initialState.inviteNamasPayPOCMembersFulfilled,
            inviteNamasPayPOCMembersFailed: initialState.inviteNamasPayPOCMembersFailed,
            inviteNamasPayPOCMembersResponse: initialState.inviteNamasPayPOCMembersResponse
        };

    case ARCHIVE_NAMASPAY_POC_MEMBER_LOADING:
        return {
            ...state,
            archiveNamasPayPOCMemberLoading: true,
            archiveNamasPayPOCMemberFulfilled: false,
            archiveNamasPayPOCMemberFailed: false,
        };

    case ARCHIVE_NAMASPAY_POC_MEMBER_FULFILLED: {
        const tempGetNamasPayPOCMembersResponse = {...state.getNamasPayPOCMembersResponse};
        tempGetNamasPayPOCMembersResponse.data = tempGetNamasPayPOCMembersResponse.data || [];
        const archivedMemberIndex = tempGetNamasPayPOCMembersResponse.data.findIndex(member => (member?.id===action?.payload?.id));
        if (archivedMemberIndex>=0) {
            tempGetNamasPayPOCMembersResponse.data.splice(archivedMemberIndex, 1);
        }

        const tempGetNamasPayPOCMemberDetailsResponse = {...state.getNamasPayPOCMemberDetailsResponse};

        if(tempGetNamasPayPOCMemberDetailsResponse[action?.payload?.id]) {
            delete tempGetNamasPayPOCMemberDetailsResponse[action?.payload?.id];
        }

        return {
            ...state,
            archiveNamasPayPOCMemberLoading: false,
            archiveNamasPayPOCMemberFulfilled: true,
            archiveNamasPayPOCMemberFailed: false,
            archiveNamasPayPOCMemberResponse: action?.payload,
            getNamasPayPOCMembersResponse: tempGetNamasPayPOCMembersResponse,
            getNamasPayPOCMemberDetailsResponse: tempGetNamasPayPOCMemberDetailsResponse
        };
    }

    case ARCHIVE_NAMASPAY_POC_MEMBER_FAILED:
        return {
            ...state,
            archiveNamasPayPOCMemberLoading: false,
            archiveNamasPayPOCMemberFulfilled: false,
            archiveNamasPayPOCMemberFailed: true,
            archiveNamasPayPOCMemberResponse: action?.payload,
        };

    case ARCHIVE_NAMASPAY_POC_MEMBER_RESET:
        return {
            ...state,
            archiveNamasPayPOCMemberLoading: initialState.archiveNamasPayPOCMemberLoading,
            archiveNamasPayPOCMemberFulfilled: initialState.archiveNamasPayPOCMemberFulfilled,
            archiveNamasPayPOCMemberFailed: initialState.archiveNamasPayPOCMemberFailed,
            archiveNamasPayPOCMemberResponse: initialState.archiveNamasPayPOCMemberResponse
        };


    case UPDATE_NAMASPAY_POC_MEMBER_DETAILS_LOADING:
        return {
            ...state,
            updateNamasPayPOCMemberDetailsLoading: true,
            updateNamasPayPOCMemberDetailsFulfilled: false,
            updateNamasPayPOCMemberDetailsFailed: false,
        };

    case UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED: {
        const tempGetNamasPayPOCMembersResponse = {...state.getNamasPayPOCMembersResponse};
        tempGetNamasPayPOCMembersResponse.data = tempGetNamasPayPOCMembersResponse.data || [];
        const archivedMemberIndex = tempGetNamasPayPOCMembersResponse.data.findIndex(member => (member?.id === action?.payload?.id));
        if (archivedMemberIndex >= 0) {
            tempGetNamasPayPOCMembersResponse.data.splice(archivedMemberIndex, 1, action?.payload);
        }

        const tempGetNamasPayPOCMemberDetailsResponse = {...state.getNamasPayPOCMemberDetailsResponse};

        if (tempGetNamasPayPOCMemberDetailsResponse[action?.payload?.id]) {
            tempGetNamasPayPOCMemberDetailsResponse[action?.payload?.id] = action?.payload;
        }
        return {
            ...state,
            updateNamasPayPOCMemberDetailsLoading: false,
            updateNamasPayPOCMemberDetailsFulfilled: true,
            updateNamasPayPOCMemberDetailsFailed: false,
            updateNamasPayPOCMemberDetailsResponse: action?.payload,
            getNamasPayPOCMembersResponse: tempGetNamasPayPOCMembersResponse,
            getNamasPayPOCMemberDetailsResponse: tempGetNamasPayPOCMemberDetailsResponse
        };
    }
    case UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FAILED:
        return {
            ...state,
            updateNamasPayPOCMemberDetailsLoading: false,
            updateNamasPayPOCMemberDetailsFulfilled: false,
            updateNamasPayPOCMemberDetailsFailed: true,
            updateNamasPayPOCMemberDetailsResponse: action?.payload,
        };

    case UPDATE_NAMASPAY_POC_MEMBER_DETAILS_RESET:
        return {
            ...state,
            updateNamasPayPOCMemberDetailsLoading: initialState.updateNamasPayPOCMemberDetailsLoading,
            updateNamasPayPOCMemberDetailsFulfilled: initialState.updateNamasPayPOCMemberDetailsFulfilled,
            updateNamasPayPOCMemberDetailsFailed: initialState.updateNamasPayPOCMemberDetailsFailed,
            updateNamasPayPOCMemberDetailsResponse: initialState.updateNamasPayPOCMemberDetailsResponse
        };


    case GET_NAMASPAY_POC_MEMBER_DETAILS_LOADING:
        return {
            ...state,
            getNamasPayPOCMemberDetailsLoading: true,
            getNamasPayPOCMemberDetailsFulfilled: false,
            getNamasPayPOCMemberDetailsFailed: false,
        };

    case GET_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED: {
        const tempGetNamasPayPOCMemberDetailsResponse = state.getNamasPayPOCMemberDetailsResponse || {};
        const memberId = action?.payload?.id;
        if (memberId) {
            tempGetNamasPayPOCMemberDetailsResponse[memberId] = action?.payload;
        }

        return {
            ...state,
            getNamasPayPOCMemberMemberDetailsLoading: false,
            getNamasPayPOCMemberMemberDetailsFulfilled: true,
            getNamasPayPOCMemberMemberDetailsFailed: false,
            getNamasPayPOCMemberMemberDetailsResponse: tempGetNamasPayPOCMemberDetailsResponse,
        };
    }

    case GET_NAMASPAY_POC_MEMBER_DETAILS_FAILED:
        return {
            ...state,
            getNamasPayPOCMemberMemberDetailsLoading: false,
            getNamasPayPOCMemberMemberDetailsFulfilled: false,
            getNamasPayPOCMemberMemberDetailsFailed: true,
        };


    default:
        return state;
    }
};

export default namasPayPOCsReducer;
