export const CAMPAIGNS_LISTING_LIMIT = 10;

export const campaignFilters = [
    {
        title: 'Sort by',
        identifier: 'sortBy',
        type: 'radio',
        categories: [
            {title: 'Latest', value: 'latest', isSelected: false},
            {title: 'Earliest', value: 'earliest', isSelected: false}
        ]
    },
    {
        title: 'By status',
        identifier: 'state',
        type: 'checkbox',
        isMulti: true,
        categories: [
            {title: 'Active', value: 'active', isSelected: false},
            {title: 'Building', value: 'build', isSelected: false},
            {title: 'Review', value: 'review', isSelected: false},
            {title: 'Inactive', value: 'inactive', isSelected: false},
            {title: 'Archive requested', value: 'request_archive', isSelected: false},
            {title: 'Deactivation requested', value: 'request_inactive', isSelected: false},
            {title: 'Active review', value: 'active_review', isSelected: false},
            {title: 'Active build', value: 'active_build', isSelected: false}
        ]
    }
];

export const CAMPAIGN_STATES = {
    BUILD: 'build',
    REVIEW: 'review',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    ARCHIVE: 'archive',
    REQUEST_ARCHIVE: 'request_archive',
    REQUEST_INACTIVE: 'request_inactive',
    ACTIVE_REVIEW: 'active_review',
    ACTIVE_BUILD: 'active_build'
};

export const CAMPAIGN_NOT_IN_REVIEW_STATES = [
    CAMPAIGN_STATES.BUILD, CAMPAIGN_STATES.ACTIVE, CAMPAIGN_STATES.INACTIVE, CAMPAIGN_STATES.ACTIVE_BUILD
];
export const CAMPAIGN_REVIEW_STATES = [
    CAMPAIGN_STATES.REVIEW, CAMPAIGN_STATES.ACTIVE_REVIEW, CAMPAIGN_STATES.REQUEST_INACTIVE,
];

export const ELEMENT_ACTION = {
    NEW: 'new',
    NEW_CAPS: 'New',
    CREATE: 'create',
    EDIT: 'edit',
    EDITED: 'Edited',
    DELETE: 'delete',
    DELETED: 'Deleted'
};

export const CAMPAIGN_OPERATIONS = {
    SEND_FOR_REVIEW: 'sendForReview',
    REQUEST_ARCHIVE: 'requestArchive',
    REQUEST_DEACTIVATE: 'requestDeactivate',
    ARCHIVE: 'archive',
    APPROVE: 'approve',
    DEACTIVATE: 'deactivate',
    REJECT_CHANGES: 'rejectChanges',
    REVOKE_REQUEST: 'rejectRequest'
};
