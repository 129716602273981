import {
    SHOW_LOADER,
    HIDE_LOADER,
    TOGGLE_LOADER,

    SHOW_TOAST,
    HIDE_TOAST,
    TOGGLE_TOAST,
    
    SHOW_CONFIRMATION_MODAL,
    HIDE_CONFIRMATION_MODAL
} from '../types';

export const showLoader = () =>
    async dispatch => {
        dispatch({ type: SHOW_LOADER });
    };

export const hideLoader = () =>
    async dispatch => {
        dispatch({ type: HIDE_LOADER });
    };

export const toggleLoader = () =>
    async dispatch => {
        dispatch({ type: TOGGLE_LOADER });
    };

export const showToast = ({status, message}) =>
    async dispatch => {
        dispatch({ type: SHOW_TOAST, payload: {status, message}});
    };

export const hideToast = () =>
    async dispatch => {
        dispatch({ type: HIDE_TOAST });
    };
    
export const ToggleToast = () =>
    async dispatch => {
        dispatch({ type: TOGGLE_TOAST });
    };

export const showConfirmationModal = (configuration) =>
    async dispatch => {
        dispatch({ type: SHOW_CONFIRMATION_MODAL, payload: configuration});
    };

export const hideConfirmationModal = () =>
    async dispatch => {
        dispatch({ type: HIDE_CONFIRMATION_MODAL });
    };

