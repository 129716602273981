// Libraries
import React, {createContext, useContext} from 'react';
import useFirebaseAuth from '../lib/firebase/useFirebaseAuth';

const authUserContext = createContext({
    authUser: null,
    loading: true,
    signInWithEmailAndPassword: async () => {
    },
    signOut: async () => {
    },
    resetPassword: async () => {
    },
    updatePassword: async () => {
    },
    reauthenticateWithCredential: async () => {
    },
    deleteUser: async () => {
    },
    updateDisplayName: async () => {
    }
});

export function AuthUserProvider({children}) {
    const auth = useFirebaseAuth();
    return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}

export const useAuth = () => useContext(authUserContext);