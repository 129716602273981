export const userTypeOptions = [{value: 'admin', label: 'Admin'}, {value: 'maker', label: 'Maker'}, {
    value: 'checker',
    label: 'Checker'
}];

export const envTypeOptions = [{value: 'dev', label: 'Dev'}, {value: 'live', label: 'Live'}];

export const PROFILE_PICTURE_UPLOAD_CONFIG = {
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    maxFileSize: 5 // in megabytes
};
export const USER_ROLES = {
    ADMIN: 'admin',
    CHECKER: 'checker',
    MAKER: 'maker'
};

export const userFilters = [
    {value: 'all', label: 'All', isSelected: true},
    {value: 'admin', label: 'Admin', isSelected: false},
    {value: 'maker', label: 'Maker', isSelected: false},
    {value: 'checker', label: 'Checker', isSelected: false}
];

