import {
    GET_MODAL_DIALOGS_LOADING,
    GET_MODAL_DIALOGS_FULFILLED,
    GET_MODAL_DIALOGS_FAILED,

    CREATE_MODAL_DIALOG_LOADING,
    CREATE_MODAL_DIALOG_FULFILLED,
    CREATE_MODAL_DIALOG_FAILED,
    CREATE_MODAL_DIALOG_RESET,

    UPDATE_MODAL_DIALOG_LOADING,
    UPDATE_MODAL_DIALOG_FULFILLED,
    UPDATE_MODAL_DIALOG_FAILED,
    UPDATE_MODAL_DIALOG_RESET,

    ARCHIVE_MODAL_DIALOG_LOADING,
    ARCHIVE_MODAL_DIALOG_FULFILLED,
    ARCHIVE_MODAL_DIALOG_FAILED,
    ARCHIVE_MODAL_DIALOG_RESET,

    GET_MODAL_DIALOG_DETAILS_LOADING,
    GET_MODAL_DIALOG_DETAILS_FULFILLED,
    GET_MODAL_DIALOG_DETAILS_FAILED
} from "../types";

const initialState = {
    getModalDialogsLoading: false,
    getModalDialogsFulfilled: false,
    getModalDialogsFailed: false,
    getModalDialogsResponse: {},

    createModalDialogLoading: false,
    createModalDialogFulfilled: false,
    createModalDialogFailed: false,
    createModalDialogResponse: {},

    updateModalDialogLoading: false,
    updateModalDialogFulfilled: false,
    updateModalDialogFailed: false,
    updateModalDialogResponse: {},

    archiveModalDialogLoading: false,
    archiveModalDialogFulfilled: false,
    archiveModalDialogFailed: false,
    archiveModalDialogResponse: {},

    getModalDialogDetailsLoading: false,
    getModalDialogDetailsFulfilled: false,
    getModalDialogDetailsFailed: false,
    getModalDialogDetailsResponse: {}
};

const ModalDialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MODAL_DIALOGS_LOADING:
            return {
                ...state,
                getModalDialogsLoading: true,
                getModalDialogsFulfilled: false,
                getModalDialogsFailed: false,
            };

        case GET_MODAL_DIALOGS_FULFILLED: {
            const tempGetModalDialogsResponse = {...state.getModalDialogsResponse};
            tempGetModalDialogsResponse.offset = action?.payload?.offset;
            tempGetModalDialogsResponse.total = action?.payload?.total;
            tempGetModalDialogsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetModalDialogsResponse.list = (tempGetModalDialogsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetModalDialogsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getModalDialogsLoading: false,
                getModalDialogsFulfilled: true,
                getModalDialogsFailed: false,
                getModalDialogsResponse: tempGetModalDialogsResponse,
            };
        }

        case GET_MODAL_DIALOGS_FAILED:
            return {
                ...state,
                getModalDialogsLoading: false,
                getModalDialogsFulfilled: false,
                getModalDialogsFailed: true,
            };


        case CREATE_MODAL_DIALOG_LOADING:
            return {
                ...state,
                createModalDialogLoading: true,
                createModalDialogFulfilled: false,
                createModalDialogFailed: false,
            };

        case CREATE_MODAL_DIALOG_FULFILLED:
            return {
                ...state,
                createModalDialogLoading: false,
                createModalDialogFulfilled: true,
                createModalDialogFailed: false,
                createModalDialogResponse: action?.payload,
            };

        case CREATE_MODAL_DIALOG_FAILED:
            return {
                ...state,
                createModalDialogLoading: false,
                createModalDialogFulfilled: false,
                createModalDialogFailed: true,
                createModalDialogResponse: action?.payload,
            };

        case CREATE_MODAL_DIALOG_RESET:
            return {
                ...state,
                createModalDialogLoading: initialState.createModalDialogLoading,
                createModalDialogFulfilled: initialState.createModalDialogFulfilled,
                createModalDialogFailed: initialState.createModalDialogFailed,
                createModalDialogResponse: initialState.createModalDialogResponse
            };


        case ARCHIVE_MODAL_DIALOG_LOADING:
            return {
                ...state,
                archiveModalDialogLoading: true,
                archiveModalDialogFulfilled: false,
                archiveModalDialogFailed: false,
            };

        case ARCHIVE_MODAL_DIALOG_FULFILLED: {
            const tempGetModalDialogsResponse = {...state.getModalDialogsResponse};
            tempGetModalDialogsResponse.list = tempGetModalDialogsResponse.list || [];
            const archivedModalDialogIndex = tempGetModalDialogsResponse.list.findIndex(modalDialog => (modalDialog?._id===action?.payload?._id));
            if (archivedModalDialogIndex>=0) {
                tempGetModalDialogsResponse.list.splice(archivedModalDialogIndex, 1);
            }

            return {
                ...state,
                archiveModalDialogLoading: false,
                archiveModalDialogFulfilled: true,
                archiveModalDialogFailed: false,
                archiveModalDialogResponse: action?.payload,
                getModalDialogsResponse: tempGetModalDialogsResponse
            };
        }

        case ARCHIVE_MODAL_DIALOG_FAILED:
            return {
                ...state,
                archiveModalDialogLoading: false,
                archiveModalDialogFulfilled: false,
                archiveModalDialogFailed: true,
                archiveModalDialogResponse: action?.payload,
            };

        case ARCHIVE_MODAL_DIALOG_RESET:
            return {
                ...state,
                archiveModalDialogLoading: initialState.archiveModalDialogLoading,
                archiveModalDialogFulfilled: initialState.archiveModalDialogFulfilled,
                archiveModalDialogFailed: initialState.archiveModalDialogFailed,
                archiveModalDialogResponse: initialState.archiveModalDialogResponse
            };


        case UPDATE_MODAL_DIALOG_LOADING:
            return {
                ...state,
                updateModalDialogLoading: true,
                updateModalDialogFulfilled: false,
                updateModalDialogFailed: false,
            };

        case UPDATE_MODAL_DIALOG_FULFILLED:
            return {
                ...state,
                updateModalDialogLoading: false,
                updateModalDialogFulfilled: true,
                updateModalDialogFailed: false,
                updateModalDialogResponse: action?.payload,
            };

        case UPDATE_MODAL_DIALOG_FAILED:
            return {
                ...state,
                updateModalDialogLoading: false,
                updateModalDialogFulfilled: false,
                updateModalDialogFailed: true,
                updateModalDialogResponse: action?.payload,
            };

        case UPDATE_MODAL_DIALOG_RESET:
            return {
                ...state,
                updateModalDialogLoading: initialState.updateModalDialogLoading,
                updateModalDialogFulfilled: initialState.updateModalDialogFulfilled,
                updateModalDialogFailed: initialState.updateModalDialogFailed,
                updateModalDialogResponse: initialState.updateModalDialogResponse
            };


        case GET_MODAL_DIALOG_DETAILS_LOADING:
            return {
                ...state,
                getModalDialogDetailsLoading: true,
                getModalDialogDetailsFulfilled: false,
                getModalDialogDetailsFailed: false,
            };

        case GET_MODAL_DIALOG_DETAILS_FULFILLED: {
            const tempGetModalDialogDetailsResponse = state.getModalDialogDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetModalDialogDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getModalDialogDetailsLoading: false,
                getModalDialogDetailsFulfilled: true,
                getModalDialogDetailsFailed: false,
                getModalDialogDetailsResponse: tempGetModalDialogDetailsResponse,
            };
        }

        case GET_MODAL_DIALOG_DETAILS_FAILED:
            return {
                ...state,
                getModalDialogDetailsLoading: false,
                getModalDialogDetailsFulfilled: false,
                getModalDialogDetailsFailed: true,
                getModalDialogDetailsResponse: action?.payload,
            };


        default:
            return state;
    }
};

export default ModalDialogReducer;
