import _, {isArray, isEqual, isObject, transform} from 'lodash';
import {
    EMAIL_NOT_FOUND,
    INVALID_EMAIL,
    INVALID_PASSWORD,
    NETWORK_REQUEST_FAILED,
    TOO_MANY_REQUESTS
} from '../constants/firebaseConstants';
import * as sanitizeHtml from 'sanitize-html';
import {cssNumericProperties, styleProperties} from '../constants/commonConstants';
import {CAMPAIGN_STATES} from '../constants/campaignConstants';
import {USER_ROLES} from '../constants/userConstants';
import firebase from '../lib/firebase';
import {CONTEST_STATES} from '../constants/contestConstants';

// Function to confirm if the API response is success or not
export const isApiResponseSuccess = (apiResponse) => {
    return apiResponse?.status === 200 && apiResponse?.data?.code === 200;
};

// Function to move an item from one index to another in an array
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const reorderInterList = (startList, endList, startIndex, endIndex) => {
    const resultStart = Array.from(startList);
    const [removed] = resultStart.splice(startIndex, 1);

    const resultEnd = Array.from(endList);
    resultEnd.splice(endIndex, 0, removed);

    return {resultStart, resultEnd};
};

// Function to toggle a filter from array of filter-categories.
export const toggleFilter = (filters = [], categoryIdentifier, filterValue, isMulti = false) => {
    const result = [...filters];
    let filterCategoryIndex = result.findIndex(filter => filter.identifier === categoryIdentifier);

    if (filterCategoryIndex < 0)
        return;

    let filterIndex = result[filterCategoryIndex].categories.findIndex(filter => filter.value === filterValue);

    if (filterIndex < 0)
        return;

    result[filterCategoryIndex].categories.map((filter, index) => {
        if (index === filterIndex) {
            filter.isSelected = !filter.isSelected;
        } else {
            if (!isMulti) {
                filter.isSelected = false;
            }
        }
    });

    return result;
};

// Function to reset an array of filter-categories
export const resetFilters = (filters = []) => {
    return filters.map(filter => {
        let tmpFilter = _.cloneDeep(filter);
        tmpFilter.categories = tmpFilter.categories.map(item => {
            return {...item, isSelected: false};
        });
        return tmpFilter;
    });
};

// Function to check whether any filter is applied
export const isFilterActive = (filters = []) => {
    return filters.filter(filterCategory =>
        (filterCategory.categories.filter(filter =>
            (filter.isSelected)).length > 0)).length > 0;
};

// Function to convert a string to snake case
export const convertToSnakeCase = (text) => {
    return text.replace(/ /g, '_').toLowerCase();
};

// Function to convert an object to a query string
export const serialize = function (obj) {
    if (!obj) {
        return '';
    }
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p) && ![undefined, null].includes(obj[p])) {
            if (Array.isArray(obj[p])) {
                obj[p]?.map(item => {
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(item));
                });
            } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
    return str.join('&');
};

// Function to replace characters in a string
export const replaceCharacters = (text, oldCharacters, newCharacters) => {
    if (!text) {
        return '';
    }
    return text.replace(new RegExp(oldCharacters, 'g'), newCharacters);
};


// Function to validate an email address
export const validateEmail = (email) => {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(email);
};

// Function to validate an url
export const validateURL = (url) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
};

export const validatePhoneNumber = (phoneNumber) => {
    return /^[6-9]\d{9}$/.test(phoneNumber);
};

// Custom error messages based on error object returned by firebase auth.
export const showCustomFirebaseError = ({code}) => {
    switch (code) {
    case NETWORK_REQUEST_FAILED:
        return 'Network connection error.';
    case EMAIL_NOT_FOUND:
        return 'Email address does not have permission.';
    case INVALID_PASSWORD:
        return 'Wrong password.';
    case TOO_MANY_REQUESTS:
        return 'Access to this account has been temporarily disabled.';
    case INVALID_EMAIL:
        return 'Invalid email address or password.';
    default:
        return 'Unknown error occurred.';
    }
};

// Custom campaign status text based on status of campaign and whether viewer is checker or not.
export const getContestStatusAsText = (state, isChecker) => {
    switch (state) {
    case CONTEST_STATES.BUILD:
        return 'Building';
    case CONTEST_STATES.REVIEW:
        return isChecker ? 'Pending approval' : 'In review';
    case CONTEST_STATES.ACTIVE:
        return 'Active';
    case CONTEST_STATES.REQUEST_ARCHIVE:
        return 'Pending archive';
    case CONTEST_STATES.REQUEST_INACTIVE:
        return 'Pending deactivation';
    case CONTEST_STATES.INACTIVE:
        return 'Inactive';
    case CONTEST_STATES.ARCHIVE:
        return 'Archived';
    case CONTEST_STATES.ACTIVE_BUILD:
        return 'Active build';
    case CONTEST_STATES.ACTIVE_REVIEW:
        return isChecker ? 'Pending approval' : 'In review';
    case CONTEST_STATES.DELETE:
        return 'Deletion review';
    default:
        return '';
    }
};

// Custom campaign status text based on status of campaign and whether viewer is checker or not.
export const getCampaignStatusAsText = (state, isChecker) => {
    switch (state) {
    case CAMPAIGN_STATES.BUILD:
        return 'Building';
    case CAMPAIGN_STATES.REVIEW:
        return isChecker ? 'Pending approval' : 'In review';
    case CAMPAIGN_STATES.ACTIVE:
        return 'Active';
    case CAMPAIGN_STATES.REQUEST_ARCHIVE:
        return 'Pending archive';
    case CAMPAIGN_STATES.REQUEST_INACTIVE:
        return 'Pending deactivation';
    case CAMPAIGN_STATES.INACTIVE:
        return 'Inactive';
    case CAMPAIGN_STATES.ARCHIVE:
        return 'Archived';
    case CAMPAIGN_STATES.ACTIVE_BUILD:
        return 'Active build';
    case CAMPAIGN_STATES.ACTIVE_REVIEW:
        return 'Active review';
    default:
        return '';
    }
};

// Method to sanitize the html based on the configuration
export const sanitize = (
    html,
    configuration = {allowedTags: [], allowedAttributes: []}
) => {
    return sanitizeHtml(html, configuration);
};

// Method to extract CSS key, values from an object
export const extractStylesFromObject = (obj) => {
    if (!obj) {
        return {};
    }

    const newObj = {...obj};
    for (const key of Object.keys(newObj)) {
        if (!styleProperties.includes(key)) {
            delete newObj[key];
        }
    }

    return newObj;
};

// Method to scale numeric css properties
export const resizeCssProperties = (properties, scale = 1) => {
    const newProperties = {...properties};

    for (const key of Object.keys(newProperties)) {
        if (cssNumericProperties.includes(key)) {
            newProperties[key] = resizeCssValue(newProperties[key], scale);
        }
    }

    return newProperties;
};

// Method to scale numeric css value
export const resizeCssValue = (value, scale) => {
    if (!value || ['auto', '0', 0].includes(value) || value.toString().indexOf('%') >= 0) {
        return value;
    }

    if (value.toString().indexOf(' ') >= 0) {
        let splitValue = value.split(' ');
        splitValue.map((val, index) => {
            if (val.toString().indexOf('px') >= 0) {
                splitValue[index] = (parseInt(val, 10) * scale) + 'px';
            } else {
                splitValue[index] = parseInt(val, 10) * scale;
            }
        });
        value = splitValue.join(' ');
    } else {
        if (value.toString().indexOf('px') >= 0) {
            value = (parseInt(value, 10) * scale) + 'px';
        } else {
            value = parseInt(value, 10) * scale;
        }
    }

    return value;
};

export const isUserAdmin = (userObj = {}) => {
    return userObj?.customClaims?.roles?.findIndex(item => item?.name === USER_ROLES.ADMIN) >= 0;
};

export const isUserChecker = (userObj = {}) => {
    return userObj?.customClaims?.roles?.findIndex(item => item?.name === USER_ROLES.ADMIN) >= 0 ||
        userObj?.customClaims?.roles?.findIndex(item => item?.name === USER_ROLES.CHECKER) >= 0;
};

export const getUserRole = (userObj = {}) => {
    if (isUserAdmin(userObj))
        return USER_ROLES.ADMIN;

    if (isUserChecker(userObj))
        return USER_ROLES.CHECKER;

    return USER_ROLES.MAKER;
};

export const getUserEnv = (userObj = {}) => {
    let isLive = userObj?.customClaims?.envs?.live;
    return isLive ? 'live' : 'dev';
};

export const createEnvObj = (env) => {
    const tempEnvs = {live: false, dev: true, stag: false};

    if (env === 'live') {
        tempEnvs.live = true;
        tempEnvs.dev = true;
        tempEnvs.stag = true;
    } else {
        tempEnvs.live = false;
        tempEnvs.dev = true;
        tempEnvs.stag = false;
    }
    return tempEnvs;
};


export const createEnvString = (envObj) => {
    const {live} = envObj;
    if (live) {
        return 'live';
    } else {
        return 'dev';
    }


};

// Method to check if an url is an image url
export const isImageUrl = (url) => {
    if (!url?.length)
        return false;

    const extension = url.split('.')[url.split('.').length - 1];
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
};

// Method to Copy any text to clipboard without displaying input
export const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0px';
    textArea.style.left = '0px';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0px';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
    }

    document.body.removeChild(textArea);
};

//Check for expired firebase token.
export const hasFirebaseTokenExpired = (response) => {
    return ['auth/id-token-expired', 'INVALID_TOKEN'].includes(response?.data?.code);
};

//Generate firebase idToken and repeat failed request.
export const rerunAction = (dispatch, action, req = {}) => {
    firebase.auth().currentUser.getIdToken()
        .then((userToken) => {
            localStorage.setItem('access_token', userToken);
            dispatch(action(req));
        })
        .catch((error) => {
            const {code, message} = error;
            console.error(code + ' ' + message);
        });

};


// Find difference between two objects
export const difference = (origObj, newObj, keysIgnored = []) => {
    function changes(newObj, origObj) {
        let arrayIndexCounter = 0;
        return transform(newObj, function (result, value, key) {
            // console.log(key, value, origObj[key]);
            if (!isEqual(value, origObj[key])) {
                let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
                result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value;
            }
        });
    }

    // console.log(origObj, newObj, keysIgnored);
    let diffObj = changes(newObj, origObj); //Create diff object
    for (let i = 0; i < keysIgnored.length; i++) {
        delete diffObj[keysIgnored[i]];
    }
    return Object.keys(diffObj).length;    //return keys count in diff object. 0 for equal objects.
};


// Function to sort users as per their last login time. (Invited users are kept at last e.g. without metadata?.lastSignInTime)
export const sortMembers = (membersArr) => {
    return membersArr.sort((a, b) => {
        if (a?.metadata?.lastSignInTime && b?.metadata?.lastSignInTime)
            return -1;
        else if (a?.metadata?.lastSignInTime)
            return -1;
        else if (b?.metadata?.lastSignInTime)
            return 1;
        return 0;
    });
};

// Turn array into comma separated sentence
export const toListSentence = (arr = []) => arr.length < 3 ?
    arr.join(' and ') :
    `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`;

// Function to convert seconds in A days: B hr: C min: D sec format
export const convertSecondsToTime = (sec) => {
    const days = Math.floor(sec / (24 * 60 * 60));                          // get days
    const hours = Math.floor((sec % (24 * 60 * 60)) / 3600);                // get hours
    const minutes = Math.floor((sec % (24 * 60 * 60) % (60 * 60)) / 60);    // get minutes
    const seconds = sec % (24 * 60 * 60) % (60 * 60) % 60;                     // get seconds

    if (sec < 0)
        return '0s';
    else if (days >= 1)
        return `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''}`;
    else if (hours >= 1)
        return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''}`;
    else
        return `${minutes > 0 ? `${minutes}m` : ''} ${seconds > 0 ? `${seconds}s` : ''}`;
};


export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
