// Libraries
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// Redux actions
import {hideToast} from '../store/actions/globalActions';

// Constants
import {toastTimer} from '../constants/toastConstants';

// Styles
import classes from './Toast.module.scss';


const renderIcon = (status) => {
    switch (status) {
    case 'error':
        return <img alt='' className={classes.statusIcon} src={'/assets/images/error-toast.png'}/>;
    default:
        return <img alt='' className={classes.statusIcon} src={'/assets/images/success-toast.png'}/>;

    }
};
export default function Toast() {
    const globalData = useSelector((state) => state?.globalData);
    const {toastStatus, toastMessage, isToastVisible} = globalData;
    const dispatch = useDispatch();

    useEffect(() => {
        if (isToastVisible) {
            setTimeout(function () {
                dispatch(hideToast());
            }, toastTimer);
        }
    }, [isToastVisible]);

    return (
        <div
            className={classes.toast + ' ' + (isToastVisible ? classes.show : '') + ' ' + classes[toastStatus]}>
            <div className={classes.content}>
                {renderIcon(toastStatus)}
                {toastMessage}
            </div>
        </div>
    );
}