// Libraries
import Head from 'next/head';
import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import {Provider, useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

// Styles
import '../styles/_globals.scss';

// Components
import Layout from '../layout/Layout';
import Loader from '../elements/Loader';
import Toast from '../elements/Toast';
import ConfirmationModal from '../modals/common/ConfirmationModal';

// Utilities
import {AuthUserProvider} from '../context/AuthUserContext';
import {store, wrapper} from '../store/store';


// Sentry configuration
Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    sendDefaultPii: false,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    beforeSend(event) {
        if (event?.message?.indexOf('[IGNORE THIS ERROR]') !== -1) {
            return null;
        }
        return event;
    }
});


function MyApp({Component, pageProps}) {
    const router = useRouter();
    const globalData = useSelector((state) => state?.globalData);
    const {isLoaderVisible, isToastVisible, isConfirmationModalVisible} = globalData;

    // To handle the dynamic paths on direct landing or on page refresh
    useEffect(() => {
        if (location.hash)
            router.replace(location.hash.replace('#!', '')).then();
    }, []);

    return (
        <AuthUserProvider>
            <Provider store={store}>
                <Layout>
                    <Head>
                        <title>Hand of King</title>
                        <meta name='viewport' content='width=device-width, initial-scale=1'/>
                    </Head>

                    <Component {...pageProps} />

                    {isLoaderVisible && <Loader/>}

                    {isConfirmationModalVisible && <ConfirmationModal/>}

                    {isToastVisible && <Toast/>}
                </Layout>
            </Provider>
        </AuthUserProvider>
    );
}

export default wrapper.withRedux(MyApp);
