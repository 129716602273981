import {
    ARCHIVE_EXPERIMENT_FAILED,
    ARCHIVE_EXPERIMENT_FULFILLED,
    ARCHIVE_EXPERIMENT_LOADING,
    ARCHIVE_EXPERIMENT_RESET,
    CREATE_EXPERIMENT_FAILED,
    CREATE_EXPERIMENT_FULFILLED,
    CREATE_EXPERIMENT_LOADING,
    CREATE_EXPERIMENT_RESET,
    GET_EXPERIMENT_DETAILS_FAILED,
    GET_EXPERIMENT_DETAILS_FULFILLED,
    GET_EXPERIMENT_DETAILS_LOADING,
    GET_EXPERIMENTS_FAILED,
    GET_EXPERIMENTS_FULFILLED,
    GET_EXPERIMENTS_LOADING,
    UPDATE_EXPERIMENT_FAILED,
    UPDATE_EXPERIMENT_FULFILLED,
    UPDATE_EXPERIMENT_LOADING,
    UPDATE_EXPERIMENT_RESET
} from '../types';

const initialState = {
    getExperimentsLoading: false,
    getExperimentsFulfilled: false,
    getExperimentsFailed: false,
    getExperimentsResponse: {},

    createExperimentLoading: false,
    createExperimentFulfilled: false,
    createExperimentFailed: false,
    createExperimentResponse: {},

    updateExperimentLoading: false,
    updateExperimentFulfilled: false,
    updateExperimentFailed: false,
    updateExperimentResponse: {},

    archiveExperimentLoading: false,
    archiveExperimentFulfilled: false,
    archiveExperimentFailed: false,
    archiveExperimentResponse: {},

    getExperimentDetailsLoading: false,
    getExperimentDetailsFulfilled: false,
    getExperimentDetailsFailed: false,
    getExperimentDetailsResponse: {}
};

const experimentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIMENTS_LOADING:
            return {
                ...state,
                getExperimentsLoading: true,
                getExperimentsFulfilled: false,
                getExperimentsFailed: false,
            };

        case GET_EXPERIMENTS_FULFILLED:
            const tempGetExperimentsResponse = {...state.getExperimentsResponse};
            tempGetExperimentsResponse.offset = action?.payload?.offset;
            tempGetExperimentsResponse.total = action?.payload?.total;
            tempGetExperimentsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetExperimentsResponse.list = (tempGetExperimentsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetExperimentsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getExperimentsLoading: false,
                getExperimentsFulfilled: true,
                getExperimentsFailed: false,
                getExperimentsResponse: tempGetExperimentsResponse,
            };

        case GET_EXPERIMENTS_FAILED:
            return {
                ...state,
                getExperimentsLoading: false,
                getExperimentsFulfilled: false,
                getExperimentsFailed: true,
            };


        case CREATE_EXPERIMENT_LOADING:
            return {
                ...state,
                createExperimentLoading: true,
                createExperimentFulfilled: false,
                createExperimentFailed: false,
            };

        case CREATE_EXPERIMENT_FULFILLED:
            return {
                ...state,
                createExperimentLoading: false,
                createExperimentFulfilled: true,
                createExperimentFailed: false,
                createExperimentResponse: action?.payload,
            };

        case CREATE_EXPERIMENT_FAILED:
            return {
                ...state,
                createExperimentLoading: false,
                createExperimentFulfilled: false,
                createExperimentFailed: true,
                createExperimentResponse: action?.payload,
            };
        case CREATE_EXPERIMENT_RESET:
            return {
                ...state,
                createExperimentLoading: false,
                createExperimentFulfilled: false,
                createExperimentFailed: false,
                createExperimentResponse: {},
            };
        case ARCHIVE_EXPERIMENT_LOADING:
            return {
                ...state,
                archiveExperimentLoading: true,
                archiveExperimentFulfilled: false,
                archiveExperimentFailed: false,
            };

        case ARCHIVE_EXPERIMENT_FULFILLED: {
            const tempGetExperimentsResponse = {...state.getExperimentsResponse};
            tempGetExperimentsResponse.list = tempGetExperimentsResponse.list || [];
            const archivedExperimentIndex = tempGetExperimentsResponse.list.findIndex(experiment => (experiment?._id === action?.payload?._id));
            if (archivedExperimentIndex >= 0) {
                tempGetExperimentsResponse.list.splice(archivedExperimentIndex, 1);
            }

            return {
                ...state,
                archiveExperimentLoading: false,
                archiveExperimentFulfilled: true,
                archiveExperimentFailed: false,
                archiveExperimentResponse: action?.payload,
                getExperimentsResponse: tempGetExperimentsResponse
            };
        }

        case ARCHIVE_EXPERIMENT_FAILED:
            return {
                ...state,
                archiveExperimentLoading: false,
                archiveExperimentFulfilled: false,
                archiveExperimentFailed: true,
                archiveExperimentResponse: action?.payload,
            };

        case ARCHIVE_EXPERIMENT_RESET:
            return {
                ...state,
                archiveExperimentLoading: initialState.archiveExperimentLoading,
                archiveExperimentFulfilled: initialState.archiveExperimentFulfilled,
                archiveExperimentFailed: initialState.archiveExperimentFailed,
                archiveExperimentResponse: initialState.archiveExperimentResponse
            };


        case UPDATE_EXPERIMENT_LOADING:
            return {
                ...state,
                updateExperimentLoading: true,
                updateExperimentFulfilled: false,
                updateExperimentFailed: false,
            };

        case UPDATE_EXPERIMENT_FULFILLED:
            return {
                ...state,
                updateExperimentLoading: false,
                updateExperimentFulfilled: true,
                updateExperimentFailed: false,
                updateExperimentResponse: action?.payload,
            };

        case UPDATE_EXPERIMENT_FAILED:
            return {
                ...state,
                updateExperimentLoading: false,
                updateExperimentFulfilled: false,
                updateExperimentFailed: true,
                updateExperimentResponse: action?.payload,
            };

        case UPDATE_EXPERIMENT_RESET:
            return {
                ...state,
                updateExperimentLoading: initialState.updateExperimentLoading,
                updateExperimentFulfilled: initialState.updateExperimentFulfilled,
                updateExperimentFailed: initialState.updateExperimentFailed,
                updateExperimentResponse: initialState.updateExperimentResponse
            };


        case GET_EXPERIMENT_DETAILS_LOADING:
            return {
                ...state,
                getExperimentDetailsLoading: true,
                getExperimentDetailsFulfilled: false,
                getExperimentDetailsFailed: false,
            };

        case GET_EXPERIMENT_DETAILS_FULFILLED: {
            const tempGetExperimentDetailsResponse = state.getExperimentDetailsResponse || {};
            const experimentId = action?.payload?._id;
            if (experimentId) {
                tempGetExperimentDetailsResponse[experimentId] = action?.payload;
            }

            return {
                ...state,
                getExperimentDetailsLoading: false,
                getExperimentDetailsFulfilled: true,
                getExperimentDetailsFailed: false,
                getExperimentDetailsResponse: tempGetExperimentDetailsResponse,
            };
        }

        case GET_EXPERIMENT_DETAILS_FAILED:
            return {
                ...state,
                getExperimentDetailsLoading: false,
                getExperimentDetailsFulfilled: false,
                getExperimentDetailsFailed: true,
            };
        default:
            return state;
    }
};

export default experimentReducer;