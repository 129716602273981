import {
    GET_USER_DETAILS_LOADING,
    GET_USER_DETAILS_RESET,
    GET_USER_DETAILS_FULFILLED,
    GET_USER_DETAILS_FAILED,

    UPDATE_USER_DETAILS_LOADING,
    UPDATE_USER_DETAILS_FULFILLED,
    UPDATE_USER_DETAILS_FAILED,
    UPDATE_USER_DETAILS_RESET,

    USER_LOGOUT_LOADING,
    USER_LOGOUT_FULFILLED,
    USER_LOGOUT_FAILED,
    USER_LOGOUT_RESET,

    UPLOAD_PROFILE_PICTURE_LOADING,
    UPLOAD_PROFILE_PICTURE_FULFILLED,
    UPLOAD_PROFILE_PICTURE_FAILED,
    UPLOAD_PROFILE_PICTURE_RESET,


} from '../types';

const initialState = {
    getUserDetailsLoading: false,
    getUserDetailsFulfilled: false,
    getUserDetailsFailed: false,
    getUserDetailsResponse: {},
    updateUserDetailsLoading: false,
    updateUserDetailsFulfilled: false,
    updateUserDetailsFailed: false,
    updateUserDetailsResponse: {},
    userLogoutLoading: false,
    userLogoutFulfilled: false,
    userLogoutFailed: false,
    userLogoutResponse: {},
    uploadProfilePictureLoading: false,
    uploadProfilePictureFulfilled: false,
    uploadProfilePictureFailed: false,
    uploadProfilePictureResponse: {},

};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_USER_DETAILS_LOADING:
        return {
            ...state,
            getUserDetailsLoading: true,
            getUserDetailsFulfilled: false,
            getUserDetailsFailed: false,
        };

    case GET_USER_DETAILS_FULFILLED:
        return {
            ...state,
            getUserDetailsLoading: false,
            getUserDetailsFulfilled: true,
            getUserDetailsResponse: action?.payload,
        };
    case GET_USER_DETAILS_FAILED:
        return {
            ...state,
            getUserDetailsLoading: false,
            getUserDetailsFulfilled: false,
            getUserDetailsFailed: true,
            getUserDetailsResponse: action?.payload

        };
    case GET_USER_DETAILS_RESET:
        return {
            ...state,
            getUserDetailsLoading: initialState.getUserDetailsLoading,
            getUserDetailsFulfilled: initialState.getUserDetailsFulfilled,
            getUserDetailsFailed: initialState.getUserDetailsFailed,
            getUserDetailsResponse: initialState.getUserDetailsResponse
        };
    case UPDATE_USER_DETAILS_LOADING:
        return {
            ...state,
            updateUserDetailsLoading: true,
            updateUserDetailsFulfilled: false,
            updateUserDetailsFailed: false,
        };

    case UPDATE_USER_DETAILS_FULFILLED:
        return {
            ...state,
            updateUserDetailsLoading: false,
            updateUserDetailsFulfilled: true,
            updateUserDetailsFailed: false,
            updateUserDetailsResponse: action?.payload,
            getUserDetailsResponse: action?.payload,
        };
    case UPDATE_USER_DETAILS_FAILED:
        return {
            ...state,
            updateUserDetailsLoading: false,
            updateUserDetailsFulfilled: false,
            updateUserDetailsFailed: true,
            updateUserDetailsResponse: action?.payload

        };
    case UPDATE_USER_DETAILS_RESET:
        return {
            ...state,
            updateUserDetailsLoading: initialState.updateUserDetailsLoading,
            updateUserDetailsFulfilled: initialState.updateUserDetailsFulfilled,
            updateUserDetailsFailed: initialState.updateUserDetailsFailed,
            updateUserDetailsResponse: initialState.updateUserDetailsResponse
        };
    case USER_LOGOUT_LOADING:
        return {
            ...state,
            userLogoutLoading: true,
            userLogoutFulfilled: false,
            userLogoutFailed: false,
        };

    case USER_LOGOUT_FULFILLED:
        return {
            ...state,
            userLogoutLoading: false,
            userLogoutFulfilled: true,
            userLogoutFailed: false,
        };
    case USER_LOGOUT_FAILED:
        return {
            ...state,
            userLogoutLoading: false,
            userLogoutFulfilled: false,
            userLogoutFailed: true,
        };
    case USER_LOGOUT_RESET:
        return {
            ...state,
            userLogoutLoading: initialState.userLogoutLoading,
            userLogoutFulfilled: initialState.userLogoutFulfilled,
            userLogoutFailed: initialState.userLogoutFailed,
            getUserDetailsResponse: initialState.getUserDetailsResponse
        };


    case UPLOAD_PROFILE_PICTURE_LOADING:
        return {
            ...state,
            uploadProfilePictureLoading: true,
            uploadProfilePictureFulfilled: false,
            uploadProfilePictureFailed: false,
        };
    case UPLOAD_PROFILE_PICTURE_FULFILLED: {
        const tempGetUserDetailsResponse = {...state.getUserDetailsResponse};
        tempGetUserDetailsResponse.photoURL = action?.payload;
        return {
            ...state,
            uploadProfilePictureLoading: false,
            uploadProfilePictureFulfilled: true,
            uploadProfilePictureFailed: false,
            uploadProfilePictureResponse: action?.payload,
            getUserDetailsResponse: tempGetUserDetailsResponse
        };
    }
    case UPLOAD_PROFILE_PICTURE_FAILED:

        return {
            ...state,
            uploadProfilePictureLoading: false,
            uploadProfilePictureFulfilled: false,
            uploadProfilePictureFailed: true,
            uploadProfilePictureResponse: action?.payload,

        };
    case UPLOAD_PROFILE_PICTURE_RESET:

        return {
            ...state,
            uploadProfilePictureLoading: initialState.uploadProfilePictureLoading,
            uploadProfilePictureFulfilled: initialState.uploadProfilePictureFulfilled,
            uploadProfilePictureFailed: initialState.uploadProfilePictureFailed,
            uploadProfilePictureResponse: initialState.uploadProfilePictureResponse,

        };
    default:
        return state;
    }
};

export default userReducer;