import {
    ARCHIVE_MISSION_FAILED,
    ARCHIVE_MISSION_FULFILLED,
    ARCHIVE_MISSION_LOADING,
    ARCHIVE_MISSION_RESET,
    CHANGE_MISSION_STATE_FAILED,
    CHANGE_MISSION_STATE_FULFILLED,
    CHANGE_MISSION_STATE_LOADING,
    CHANGE_MISSION_STATE_RESET,
    CREATE_MISSION_FAILED,
    CREATE_MISSION_FULFILLED,
    CREATE_MISSION_LOADING,
    CREATE_MISSION_RESET,
    GET_MISSION_ARENAS_FAILED,
    GET_MISSION_ARENAS_FULFILLED,
    GET_MISSION_ARENAS_LOADING,
    GET_MISSION_DETAILS_FAILED,
    GET_MISSION_DETAILS_FULFILLED,
    GET_MISSION_DETAILS_LOADING,
    GET_MISSION_LEADERBOARD_FAILED,
    GET_MISSION_LEADERBOARD_FULFILLED,
    GET_MISSION_LEADERBOARD_LOADING,
    GET_MISSIONS_FAILED,
    GET_MISSIONS_FULFILLED,
    GET_MISSIONS_LOADING,
    GET_MISSIONS_TO_REVIEW_FAILED,
    GET_MISSIONS_TO_REVIEW_FULFILLED,
    GET_MISSIONS_TO_REVIEW_LOADING,
    UPDATE_MISSION_FAILED,
    UPDATE_MISSION_FULFILLED,
    UPDATE_MISSION_LOADING,
    UPDATE_MISSION_RESET
} from '../types';

const initialState = {
    getMissionsLoading: false,
    getMissionsFulfilled: false,
    getMissionsFailed: false,
    getMissionsResponse: {},

    getMissionArenasLoading: false,
    getMissionArenasFulfilled: false,
    getMissionArenasFailed: false,
    getMissionArenasResponse: [],

    getMissionLeaderboardLoading: false,
    getMissionLeaderboardFulfilled: false,
    getMissionLeaderboardFailed: false,
    getMissionLeaderboardResponse: {},

    getMissionsToReviewLoading: false,
    getMissionsToReviewFulfilled: false,
    getMissionsToReviewFailed: false,
    getMissionsToReviewResponse: {},

    createMissionLoading: false,
    createMissionFulfilled: false,
    createMissionFailed: false,
    createMissionResponse: {},

    updateMissionLoading: false,
    updateMissionFulfilled: false,
    updateMissionFailed: false,
    updateMissionResponse: {},

    archiveMissionLoading: false,
    archiveMissionFulfilled: false,
    archiveMissionFailed: false,
    archiveMissionResponse: {},

    getMissionDetailsLoading: false,
    getMissionDetailsFulfilled: false,
    getMissionDetailsFailed: false,
    getMissionDetailsResponse: {},

    changeMissionStateLoading: false,
    changeMissionStateFulfilled: false,
    changeMissionStateFailed: false,
    changeMissionStateResponse: {}
};

const MissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MISSIONS_LOADING:
            return {
                ...state,
                getMissionsLoading: true,
                getMissionsFulfilled: false,
                getMissionsFailed: false,
            };

        case GET_MISSIONS_FULFILLED: {
            const tempGetMissionsResponse = {...state.getMissionsResponse};
            tempGetMissionsResponse.offset = action?.payload?.offset;
            tempGetMissionsResponse.total = action?.payload?.total;
            tempGetMissionsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetMissionsResponse.list = (tempGetMissionsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetMissionsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getMissionsLoading: false,
                getMissionsFulfilled: true,
                getMissionsFailed: false,
                getMissionsResponse: tempGetMissionsResponse,
            };
        }

        case GET_MISSIONS_FAILED:
            return {
                ...state,
                getMissionsLoading: false,
                getMissionsFulfilled: false,
                getMissionsFailed: true,
            };


        case GET_MISSION_ARENAS_LOADING:
            return {
                ...state,
                getMissionArenasLoading: true,
                getMissionArenasFulfilled: false,
                getMissionArenasFailed: false,
            };

        case GET_MISSION_ARENAS_FULFILLED:
            return {
                ...state,
                getMissionArenasLoading: false,
                getMissionArenasFulfilled: true,
                getMissionArenasFailed: false,
                getMissionArenasResponse: action?.payload || [],
            };

        case GET_MISSION_ARENAS_FAILED:
            return {
                ...state,
                getMissionArenasLoading: false,
                getMissionArenasFulfilled: false,
                getMissionArenasFailed: true,
            };


        case GET_MISSION_LEADERBOARD_LOADING:
            return {
                ...state,
                getMissionLeaderboardLoading: true,
                getMissionLeaderboardFulfilled: false,
                getMissionLeaderboardFailed: false,
            };

        case GET_MISSION_LEADERBOARD_FULFILLED: {
            const tempGetMissionLeaderboardResponse = {...state.getMissionLeaderboardResponse};
            if (action?.payload?.contestId && action?.payload?.arena) {
                if(tempGetMissionLeaderboardResponse?.[action?.payload?.contestId] && tempGetMissionLeaderboardResponse?.[action?.payload?.contestId]?.[action?.payload?.arena]) {
                    tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena] = {...tempGetMissionLeaderboardResponse?.[action?.payload?.contestId]?.[action?.payload?.arena]};
                }
                else{
                    if(!tempGetMissionLeaderboardResponse[action?.payload?.contestId]) {
                        tempGetMissionLeaderboardResponse[action?.payload?.contestId] = {};
                    }
                    tempGetMissionLeaderboardResponse[action?.payload?.contestId][action.payload.arena]={};
                }
                tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].offset = action?.payload?.offset;
                tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].total = action?.payload?.total;
                tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].hasMore = action?.payload?.hasMore;

                if (action?.payload?.reqOffset) {
                    tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].list = (tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena]?.list || []).concat(action?.payload?.list || []);
                } else {
                    tempGetMissionLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].list = action?.payload?.list || [];
                }
            }

            return {
                ...state,
                getMissionLeaderboardLoading: false,
                getMissionLeaderboardFulfilled: true,
                getMissionLeaderboardFailed: false,
                getMissionLeaderboardResponse: tempGetMissionLeaderboardResponse,
            };
        }

        case GET_MISSION_LEADERBOARD_FAILED:
            return {
                ...state,
                getMissionLeaderboardLoading: false,
                getMissionLeaderboardFulfilled: false,
                getMissionLeaderboardFailed: true,
            };


        case GET_MISSIONS_TO_REVIEW_LOADING:
            return {
                ...state,
                getMissionsToReviewLoading: true,
                getMissionsToReviewFulfilled: false,
                getMissionsToReviewFailed: false,
            };

        case GET_MISSIONS_TO_REVIEW_FULFILLED: {
            const tempGetMissionsToReviewResponse = {...state.getMissionsToReviewResponse};
            tempGetMissionsToReviewResponse.offset = action?.payload?.offset;
            tempGetMissionsToReviewResponse.total = action?.payload?.total;
            tempGetMissionsToReviewResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetMissionsToReviewResponse.list = (tempGetMissionsToReviewResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetMissionsToReviewResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getMissionsToReviewLoading: false,
                getMissionsToReviewFulfilled: true,
                getMissionsToReviewFailed: false,
                getMissionsToReviewResponse: tempGetMissionsToReviewResponse,
            };
        }

        case GET_MISSIONS_TO_REVIEW_FAILED:
            return {
                ...state,
                getMissionsToReviewLoading: false,
                getMissionsToReviewFulfilled: false,
                getMissionsToReviewFailed: true,
            };


        case CREATE_MISSION_LOADING:
            return {
                ...state,
                createMissionLoading: true,
                createMissionFulfilled: false,
                createMissionFailed: false,
            };

        case CREATE_MISSION_FULFILLED:
            return {
                ...state,
                createMissionLoading: false,
                createMissionFulfilled: true,
                createMissionFailed: false,
                createMissionResponse: action?.payload,
            };

        case CREATE_MISSION_FAILED:
            return {
                ...state,
                createMissionLoading: false,
                createMissionFulfilled: false,
                createMissionFailed: true,
                createMissionResponse: action?.payload,
            };

        case CREATE_MISSION_RESET:
            return {
                ...state,
                createMissionLoading: initialState.createMissionLoading,
                createMissionFulfilled: initialState.createMissionFulfilled,
                createMissionFailed: initialState.createMissionFailed,
                createMissionResponse: initialState.createMissionResponse
            };


        case ARCHIVE_MISSION_LOADING:
            return {
                ...state,
                archiveMissionLoading: true,
                archiveMissionFulfilled: false,
                archiveMissionFailed: false,
            };

        case ARCHIVE_MISSION_FULFILLED: {
            const tempGetMissionsResponse = {...state.getMissionsResponse};
            tempGetMissionsResponse.list = tempGetMissionsResponse.list || [];
            const archivedMissionIndex = tempGetMissionsResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedMissionIndex >= 0) {
                tempGetMissionsResponse.list.splice(archivedMissionIndex, 1);
            }

            const tempGetMissionsToReviewResponse = {...state.getMissionsToReviewResponse};
            tempGetMissionsToReviewResponse.list = tempGetMissionsToReviewResponse.list || [];
            const archivedMissionToReviewIndex = tempGetMissionsToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedMissionToReviewIndex >= 0) {
                tempGetMissionsToReviewResponse.list.splice(archivedMissionToReviewIndex, 1);
            }

            return {
                ...state,
                archiveMissionLoading: false,
                archiveMissionFulfilled: true,
                archiveMissionFailed: false,
                archiveMissionResponse: action?.payload,
                getMissionsResponse: tempGetMissionsResponse,
                getMissionsToReviewResponse: tempGetMissionsToReviewResponse
            };
        }

        case ARCHIVE_MISSION_FAILED:
            return {
                ...state,
                archiveMissionLoading: false,
                archiveMissionFulfilled: false,
                archiveMissionFailed: true,
                archiveMissionResponse: action?.payload,
            };

        case ARCHIVE_MISSION_RESET:
            return {
                ...state,
                archiveMissionLoading: initialState.archiveMissionLoading,
                archiveMissionFulfilled: initialState.archiveMissionFulfilled,
                archiveMissionFailed: initialState.archiveMissionFailed,
                archiveMissionResponse: initialState.archiveMissionResponse
            };


        case UPDATE_MISSION_LOADING:
            return {
                ...state,
                updateMissionLoading: true,
                updateMissionFulfilled: false,
                updateMissionFailed: false,
            };

        case UPDATE_MISSION_FULFILLED:
            return {
                ...state,
                updateMissionLoading: false,
                updateMissionFulfilled: true,
                updateMissionFailed: false,
                updateMissionResponse: action?.payload,
            };

        case UPDATE_MISSION_FAILED:
            return {
                ...state,
                updateMissionLoading: false,
                updateMissionFulfilled: false,
                updateMissionFailed: true,
                updateMissionResponse: action?.payload,
            };

        case UPDATE_MISSION_RESET:
            return {
                ...state,
                updateMissionLoading: initialState.updateMissionLoading,
                updateMissionFulfilled: initialState.updateMissionFulfilled,
                updateMissionFailed: initialState.updateMissionFailed,
                updateMissionResponse: initialState.updateMissionResponse
            };


        case GET_MISSION_DETAILS_LOADING:
            return {
                ...state,
                getMissionDetailsLoading: true,
                getMissionDetailsFulfilled: false,
                getMissionDetailsFailed: false,
            };

        case GET_MISSION_DETAILS_FULFILLED: {
            const tempGetMissionDetailsResponse = state.getMissionDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetMissionDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getMissionDetailsLoading: false,
                getMissionDetailsFulfilled: true,
                getMissionDetailsFailed: false,
                getMissionDetailsResponse: tempGetMissionDetailsResponse,
            };
        }

        case GET_MISSION_DETAILS_FAILED:
            return {
                ...state,
                getMissionDetailsLoading: false,
                getMissionDetailsFulfilled: false,
                getMissionDetailsFailed: true,
                getMissionDetailsResponse: action?.payload,
            };


        case CHANGE_MISSION_STATE_LOADING:
            return {
                ...state,
                changeMissionStateLoading: true,
                changeMissionStateFulfilled: false,
                changeMissionStateFailed: false,
            };

        case CHANGE_MISSION_STATE_FULFILLED:
            return {
                ...state,
                changeMissionStateLoading: false,
                changeMissionStateFulfilled: true,
                changeMissionStateFailed: false,
                changeMissionStateResponse: action?.payload,
            };

        case CHANGE_MISSION_STATE_FAILED:
            return {
                ...state,
                changeMissionStateLoading: false,
                changeMissionStateFulfilled: false,
                changeMissionStateFailed: true,
                changeMissionStateResponse: action?.payload,
            };

        case CHANGE_MISSION_STATE_RESET:
            return {
                ...state,
                changeMissionStateLoading: initialState.changeMissionStateLoading,
                changeMissionStateFulfilled: initialState.changeMissionStateFulfilled,
                changeMissionStateFailed: initialState.changeMissionStateFailed,
                changeMissionStateResponse: initialState.changeMissionStateResponse
            };


        default:
            return state;
    }
};

export default MissionsReducer;
