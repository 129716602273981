import {
    GET_BRANDS_LOADING,
    GET_BRANDS_FULFILLED,
    GET_BRANDS_FAILED,

    CREATE_BRAND_LOADING,
    CREATE_BRAND_FULFILLED,
    CREATE_BRAND_FAILED,
    CREATE_BRAND_RESET,
    
    UPDATE_BRAND_LOADING,
    UPDATE_BRAND_FULFILLED,
    UPDATE_BRAND_FAILED,
    UPDATE_BRAND_RESET,
    
    ARCHIVE_BRAND_LOADING,
    ARCHIVE_BRAND_FULFILLED,
    ARCHIVE_BRAND_FAILED,
    ARCHIVE_BRAND_RESET,

    GET_BRAND_DETAILS_LOADING,
    GET_BRAND_DETAILS_FULFILLED,
    GET_BRAND_DETAILS_FAILED
} from '../types';

const initialState = {
    getBrandsLoading: false,
    getBrandsFulfilled: false,
    getBrandsFailed: false,
    getBrandsResponse: {},

    createBrandLoading: false,
    createBrandFulfilled: false,
    createBrandFailed: false,
    createBrandResponse: {},

    updateBrandLoading: false,
    updateBrandFulfilled: false,
    updateBrandFailed: false,
    updateBrandResponse: {},

    archiveBrandLoading: false,
    archiveBrandFulfilled: false,
    archiveBrandFailed: false,
    archiveBrandResponse: {},

    getBrandDetailsLoading: false,
    getBrandDetailsFulfilled: false,
    getBrandDetailsFailed: false,
    getBrandDetailsResponse: {}
};

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_BRANDS_LOADING:
        return {
            ...state,
            getBrandsLoading: true,
            getBrandsFulfilled: false,
            getBrandsFailed: false,
        };

    case GET_BRANDS_FULFILLED: {
        const tempGetBrandsResponse = {...state.getBrandsResponse};
        tempGetBrandsResponse.offset = action?.payload?.offset;
        tempGetBrandsResponse.total = action?.payload?.total;
        tempGetBrandsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetBrandsResponse.list = (tempGetBrandsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetBrandsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getBrandsLoading: false,
            getBrandsFulfilled: true,
            getBrandsFailed: false,
            getBrandsResponse: tempGetBrandsResponse,
        };
    }

    case GET_BRANDS_FAILED:
        return {
            ...state,
            getBrandsLoading: false,
            getBrandsFulfilled: false,
            getBrandsFailed: true,
        };


    case CREATE_BRAND_LOADING:
        return {
            ...state,
            createBrandLoading: true,
            createBrandFulfilled: false,
            createBrandFailed: false,
        };

    case CREATE_BRAND_FULFILLED:
        return {
            ...state,
            createBrandLoading: false,
            createBrandFulfilled: true,
            createBrandFailed: false,
            createBrandResponse: action?.payload,
        };

    case CREATE_BRAND_FAILED:
        return {
            ...state,
            createBrandLoading: false,
            createBrandFulfilled: false,
            createBrandFailed: true,
            createBrandResponse: action?.payload,
        };

    case CREATE_BRAND_RESET:
        return {
            ...state,
            createBrandLoading: initialState.createBrandLoading,
            createBrandFulfilled: initialState.createBrandFulfilled,
            createBrandFailed: initialState.createBrandFailed,
            createBrandResponse: initialState.createBrandResponse
        };


    case ARCHIVE_BRAND_LOADING:
        return {
            ...state,
            archiveBrandLoading: true,
            archiveBrandFulfilled: false,
            archiveBrandFailed: false,
        };

    case ARCHIVE_BRAND_FULFILLED: {
        const tempGetBrandsResponse = {...state.getBrandsResponse};
        tempGetBrandsResponse.list = tempGetBrandsResponse.list || [];
        const archivedBrandIndex = tempGetBrandsResponse.list.findIndex(activity => (activity?._id===action?.payload?._id));
        if (archivedBrandIndex>=0) {
            tempGetBrandsResponse.list.splice(archivedBrandIndex, 1);
        }

        return {
            ...state,
            archiveBrandLoading: false,
            archiveBrandFulfilled: true,
            archiveBrandFailed: false,
            archiveBrandResponse: action?.payload,
            getBrandsResponse: tempGetBrandsResponse
        };
    }

    case ARCHIVE_BRAND_FAILED:
        return {
            ...state,
            archiveBrandLoading: false,
            archiveBrandFulfilled: false,
            archiveBrandFailed: true,
            archiveBrandResponse: action?.payload,
        };

    case ARCHIVE_BRAND_RESET:
        return {
            ...state,
            archiveBrandLoading: initialState.archiveBrandLoading,
            archiveBrandFulfilled: initialState.archiveBrandFulfilled,
            archiveBrandFailed: initialState.archiveBrandFailed,
            archiveBrandResponse: initialState.archiveBrandResponse
        };


    case UPDATE_BRAND_LOADING:
        return {
            ...state,
            updateBrandLoading: true,
            updateBrandFulfilled: false,
            updateBrandFailed: false,
        };

    case UPDATE_BRAND_FULFILLED:
        return {
            ...state,
            updateBrandLoading: false,
            updateBrandFulfilled: true,
            updateBrandFailed: false,
            updateBrandResponse: action?.payload,
        };

    case UPDATE_BRAND_FAILED:
        return {
            ...state,
            updateBrandLoading: false,
            updateBrandFulfilled: false,
            updateBrandFailed: true,
            updateBrandResponse: action?.payload,
        };

    case UPDATE_BRAND_RESET:
        return {
            ...state,
            updateBrandLoading: initialState.updateBrandLoading,
            updateBrandFulfilled: initialState.updateBrandFulfilled,
            updateBrandFailed: initialState.updateBrandFailed,
            updateBrandResponse: initialState.updateBrandResponse
        };


    case GET_BRAND_DETAILS_LOADING:
        return {
            ...state,
            getBrandDetailsLoading: true,
            getBrandDetailsFulfilled: false,
            getBrandDetailsFailed: false,
        };

    case GET_BRAND_DETAILS_FULFILLED: {
        const tempGetBrandDetailsResponse = state.getBrandDetailsResponse || {};
        const activityId = action?.payload?._id;
        if (activityId) {
            tempGetBrandDetailsResponse[activityId] = action?.payload;
        }

        return {
            ...state,
            getBrandDetailsLoading: false,
            getBrandDetailsFulfilled: true,
            getBrandDetailsFailed: false,
            getBrandDetailsResponse: tempGetBrandDetailsResponse,
        };
    }

    case GET_BRAND_DETAILS_FAILED:
        return {
            ...state,
            getBrandDetailsLoading: false,
            getBrandDetailsFulfilled: false,
            getBrandDetailsFailed: true,
        };


    default:
        return state;
    }
};

export default brandReducer;
