export const defaultApiErrorMessage = 'Server side error occurred';

export const styleProperties = [
    'fontSize',
    'fontFamily',
    'width',
    'height',
    'minHeight',
    'color',
    'backgroundColor',
    'backgroundImage',
    'backgroundSize',
    'backgroundPosition',
    'backgroundRepeat',
    'padding',
    'paddingTop',
    'paddingLeft',
    'paddingRight',
    'paddingBottom',
    'borderRadius',
    'marginTop',
    'marginLeft',
    'marginRight',
    'marginBottom',
    'display',
    'flex',
    'grid-gap',
    'grid-template-columns',
    'grid-column',
    'alignItems',
    'justifyContent',
    'flexFlow',
    'textAlign',
    'position',
    'top',
    'left',
    'right',
    'bottom',
    'textDecoration',
    'fontStyle',
    'borderStyle',
    'borderWidth',
    'borderColor',
    'overflow'
];

export const cssNumericProperties = ['width', 'height', 'minHeight', 'minWidth', 'fontSize', 'padding', 'paddingTop', 'paddingLeft', 'paddingRight', 'paddingBottom', 'margin', 'marginLeft', 'marginRight', 'marginTop', 'marginBottom', 'borderRadius', 'borderWidth', 'top', 'bottom', 'left', 'right'];

export const serverControlledKeys = ['__v', 'date_modified'];