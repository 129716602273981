export const ACTIVITIES_LISTING_LIMIT = 50;

export const ACTIVITY_TYPES = {
    GAME: 'game',
    TRANSACTION: 'transaction',
};

export const ACTIVITY_SUBTYPES = {
    SINGLE_PLAYER: 'singleplayer',
    MULTI_PLAYER: 'multiplayer',
};

export const ACTIVITY_TYPE_OPTIONS = [
    {value: ACTIVITY_TYPES.GAME, label: 'Game'}
];

export const ACTIVITY_SUBTYPE_OPTIONS = [
    {value: ACTIVITY_SUBTYPES.SINGLE_PLAYER, label: 'Single-player'},
    {value: ACTIVITY_SUBTYPES.MULTI_PLAYER, label: 'Multi-player'}
];

export const ACTIVITY_STATES = {
    ACTIVE: 'active',
    DISABLE: 'disable',
    ARCHIVE: 'archive'
};

export const ATTRIBUTE_TYPES = {
    NUMBER: 'number',
    STRING: 'string'
};

export const ATTRIBUTE_TYPE_OPTIONS = [
    {value: ATTRIBUTE_TYPES.NUMBER, label: 'Number'},
    {value: ATTRIBUTE_TYPES.STRING, label: 'String'},
];

export const attributeTypeOptions = [
    {value: 'number', label: 'Number'},
    {value: 'string', label: 'String'},
    {value: 'boolean', label: 'Boolean'}
];

export const attributeObjEmptyState = {
    name: '',
    description: '',
    type: '',
    labelInfo: {
        prefix: '',
        suffix: ''
    }
};

export const activityObjEmptyState = {
    name: '',
    description: '',
    syncDelay: '',
    attributes: []
};

export const missionTargetTimeOptions = [
    {value: 'variable', label: 'Variable'},
    // {value: 'fixed', label: 'Fixed'},
];

export const missionTargetDurationOptions = [
    {value: 'day', label: 'Days'},
    {value: 'week', label: 'Weeks'},
    {value: 'month', label: 'Months'},
];

export const ACTIVITY_AGGREGATION_TYPES = {
    SUM: 'sum',
    COUNT: 'count',
    MAX: 'max',
    MIN: 'min',
    LAST: 'last'
};

export const ACTIVITY_AGGREGATION_TYPES_OPTIONS = [
    {value: ACTIVITY_AGGREGATION_TYPES.SUM, label: 'Sum'},
    {value: ACTIVITY_AGGREGATION_TYPES.COUNT, label: 'Count'}
];


export const TARGET_FILTER_ID_TYPES = {
    TRANSACTION_ID: 'transaction_id',
    MODE: 'mode',// {type: String, required: true},
    PAYEE_TYPE: 'payee_type',// {type: String, required: true},
    PAYER_TYPE: 'payer_type',// {type: String, required: true},
    TRANSACTION_TYPE: 'transaction_type',// {type: String, required: true},
    MERCHANT_CATEGORY_CODE: 'merchant_category_code',//{type: String},
    MERCHANT_ID: 'merchant_id',// {type: String},
    CHANNEL: 'channel',// {type: String},
    SUB_MODE: 'sub_mode',// {type: String},
    AMOUNT: 'amount',// {type: Number, required: true},
    SOURCE_TYPE: 'source_type',// {type: String},
    CARD_NETWORK: 'card_network',// {type: String},
    CARD_TYPE: 'card_type',// {type: String},
    MONEY_REQUEST_TYPE: 'money_request_type',//: {type: String},
};

export const TARGET_FILTER_ID_OPTIONS = [
    {value: TARGET_FILTER_ID_TYPES.TRANSACTION_ID, label: 'TRANSACTION_ID'},
    {value: TARGET_FILTER_ID_TYPES.MODE, label: 'MODE'}, // {type: String, required: true},
    {value: TARGET_FILTER_ID_TYPES.PAYEE_TYPE, label: 'PAYEE_TYPE'},// {type: String, required: true},
    {value: TARGET_FILTER_ID_TYPES.PAYER_TYPE, label: 'PAYER_TYPE'},// {type: String, required: true},
    {value: TARGET_FILTER_ID_TYPES.TRANSACTION_TYPE, label: 'TRANSACTION_TYPE'},// {type: String, required: true},
    {value: TARGET_FILTER_ID_TYPES.MERCHANT_CATEGORY_CODE, label: 'MERCHANT_CATEGORY_CODE'},//{type: String},
    {value: TARGET_FILTER_ID_TYPES.MERCHANT_ID, label: 'MERCHANT_ID'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.CHANNEL, label: 'CHANNEL'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.SUB_MODE, label: 'SUB_MODE'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.AMOUNT, label: 'AMOUNT'},// {type: Number, required: true},
    {value: TARGET_FILTER_ID_TYPES.SOURCE_TYPE, label: 'SOURCE_TYPE'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.CARD_NETWORK, label: 'CARD_NETWORK'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.CARD_TYPE, label: 'CARD_TYPE'},// {type: String},
    {value: TARGET_FILTER_ID_TYPES.MONEY_REQUEST_TYPE, label: 'MONEY_REQUEST_TYPE'}//: {type: String},
];

export const TARGET_FILTER_OPERATOR_TYPES = {
    EQ: 'eq', // string, number single value
    GT: 'gt', //number single value
    LT: 'lt',// number single value
    GTE: 'gte', //number single value
    LTE: 'lte', //number single value
    IN: 'in', //string,number [array]
    NIN: 'nin', //string, number [array]
    RANGE: 'range', //number [[1,200],[400,600]]
};

export const TARGET_FILTER_OPERATOR_OPTIONS = [
    {value: TARGET_FILTER_OPERATOR_TYPES.EQ, label: 'Equal', dataType: ['number', 'string'], isArray: false}, // string, number single value
    {value: TARGET_FILTER_OPERATOR_TYPES.GT, label: 'Greater than', dataType: ['number'], isArray: false}, //number single value
    {value: TARGET_FILTER_OPERATOR_TYPES.LT, label: 'Less than', dataType: ['number'], isArray: false},// number single value
    {value: TARGET_FILTER_OPERATOR_TYPES.GTE, label: 'Greater than or equal', dataType: ['number'], isArray: false}, //number single value
    {value: TARGET_FILTER_OPERATOR_TYPES.LTE, label: 'Less than or equal', dataType: ['number'], isArray: false}, //number single value
    {value: TARGET_FILTER_OPERATOR_TYPES.IN, label: 'Present in', dataType: ['number', 'string'], isArray: false}, //string,number [array]
    {value: TARGET_FILTER_OPERATOR_TYPES.NIN, label: 'Not present in', dataType: ['number', 'string'], isArray: false}, //string, number [array]
    {value: TARGET_FILTER_OPERATOR_TYPES.RANGE, label: 'Range', dataType: ['number'], isArray: false}, //number [[1,200],[400,600]]
];

export const TARGET_TYPES = {
    RECURRING: 'recurring',
    ONCE: 'once',
    MAX: 'max'
};

export const PAISE_PER_RUPEE = 100;

export const GAME_PROVIDERS = {
    GAMEZOP: 'gamezop',
    INTERNAL: 'internal'
};

export const MULTIPLAYER_USER_PER_CLUSTER_OPTIONS = [
    {value: 2, label: '2'},
    {value: 4, label: '4'}
];

export const ACTIVITY_TAGS = {
    QUICK_BATTLES: 'quick-battles',
    CHAMPIONSHIPS: 'championships',
    MULTIPLAYER: 'multiplayer',
    SINGLEPLAYER: 'singleplayer',
    TRENDING: 'trending',
    LEADERBOARD: 'leaderboard',
    EASY: 'easy',
    MEDIUM: 'medium',
    DIFFICULT: 'difficult'
};

export const DEFAULT_ACTIVITY_RULES = [
    'Famcoins used for joining the contest can only be refunded in case the contest is cancelled',
    'Once joined, you will get limited free replay chances',
    'You\'ll get an option to either watch an ad or pay with Famcoins for every additional replay',
    'Any score made after contest end time will not be counted',
    'You will be eligible for rewards only if you\'re in the winning range',
    'All coin winnings will be auto credited while cash winnings will have to be claimed from \'All winnings\' tab on rewards page',
    'FamPay has the right to restrict any user from participating in case of foul play'
];

export const ACTIVITY_TAG_OPTIONS = Object.entries(ACTIVITY_TAGS).map(([k, v]) => ({
    value: v,
    label: v
}));

export const emptyActivityObj = {
    type: ACTIVITY_TYPES.GAME,
    tags: [],
    tagsPriority: {},
    params: {rules: DEFAULT_ACTIVITY_RULES}
};

export const ACTIVITIES_FILTERS = [
    {
        title: 'Sort by',
        identifier: 'sortBy',
        type: 'radio',
        categories: [
            {title: 'Latest', value: 'latest', isSelected: false},
            {title: 'Earliest', value: 'earliest', isSelected: false}
        ]
    },
    {
        title: 'By subtype',
        identifier: 'subtype',
        type: 'checkbox',
        isMulti: true,
        categories: [
            {title: 'Singleplayer', value: ACTIVITY_SUBTYPES.SINGLE_PLAYER, isSelected: false},
            {title: 'Multiplayer', value: ACTIVITY_SUBTYPES.MULTI_PLAYER, isSelected: false}
        ]
    },
    {
        title: 'By tag',
        identifier: 'tags',
        type: 'checkbox',
        isMulti: true,
        categories: [
            {title: 'Quick Battles', value: ACTIVITY_TAGS.QUICK_BATTLES, isSelected: false},
            {title: 'Championships', value: ACTIVITY_TAGS.CHAMPIONSHIPS, isSelected: false},
            {title: 'Trending', value: ACTIVITY_TAGS.TRENDING, isSelected: false},
            {title: 'Leaderboard', value: ACTIVITY_TAGS.LEADERBOARD, isSelected: false},
            {title: 'Easy', value: ACTIVITY_TAGS.EASY, isSelected: false},
            {title: 'Medium', value: ACTIVITY_TAGS.MEDIUM, isSelected: false},
            {title: 'Difficult', value: ACTIVITY_TAGS.DIFFICULT, isSelected: false},
            {title: 'Singleplayer', value: ACTIVITY_TAGS.SINGLEPLAYER, isSelected: false},
            {title: 'Multiplayer', value: ACTIVITY_TAGS.MULTIPLAYER, isSelected: false}
        ]
    }
];