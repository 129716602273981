import {FEED_MEDIA_UPLOAD_CONFIG, MEDIA_TYPE_EXT_MAP, MEDIA_TYPES} from '../constants/feedMediaConstants';

export const isLottie = (fileUrl) => {
    return fileUrl?.toLowerCase()?.indexOf('.json') >= 0;
};

export const isHLS = (fileUrl) => {
    return fileUrl?.toLowerCase()?.indexOf('.m3u8') >= 0;
};

export const isMp4 = (fileUrl) => {
    return fileUrl?.toLowerCase()?.indexOf('.mp4') >= 0;
};

export const isVideo = (fileUrl) => {
    const fileExt = fileUrl?.toLowerCase()?.split('.')?.[fileUrl?.split('.')?.length - 1];
    return ['mp4', 'm3u8'].includes(fileExt);
};

export const getFeedMediaUploadConfig = (allowedMediaType = [MEDIA_TYPES.EXT, MEDIA_TYPES.MP4, MEDIA_TYPES.LOTTIE]) => {
    const accept = allowedMediaType.map(mediaType => (MEDIA_TYPE_EXT_MAP[mediaType])).join(',');
    return {...FEED_MEDIA_UPLOAD_CONFIG, accept};
};
