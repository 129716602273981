import {
    GET_MODAL_DIALOG_GROUPS_LOADING,
    GET_MODAL_DIALOG_GROUPS_FULFILLED,
    GET_MODAL_DIALOG_GROUPS_FAILED,

    CREATE_MODAL_DIALOG_GROUP_LOADING,
    CREATE_MODAL_DIALOG_GROUP_FULFILLED,
    CREATE_MODAL_DIALOG_GROUP_FAILED,
    CREATE_MODAL_DIALOG_GROUP_RESET,

    UPDATE_MODAL_DIALOG_GROUP_LOADING,
    UPDATE_MODAL_DIALOG_GROUP_FULFILLED,
    UPDATE_MODAL_DIALOG_GROUP_FAILED,
    UPDATE_MODAL_DIALOG_GROUP_RESET,

    ARCHIVE_MODAL_DIALOG_GROUP_LOADING,
    ARCHIVE_MODAL_DIALOG_GROUP_FULFILLED,
    ARCHIVE_MODAL_DIALOG_GROUP_FAILED,
    ARCHIVE_MODAL_DIALOG_GROUP_RESET,

    GET_MODAL_DIALOG_GROUP_DETAILS_LOADING,
    GET_MODAL_DIALOG_GROUP_DETAILS_FULFILLED,
    GET_MODAL_DIALOG_GROUP_DETAILS_FAILED,

    ADD_MODAL_DIALOG_TO_GROUP_LOADING,
    ADD_MODAL_DIALOG_TO_GROUP_FULFILLED,
    ADD_MODAL_DIALOG_TO_GROUP_FAILED,
    ADD_MODAL_DIALOG_TO_GROUP_RESET,

    REMOVE_MODAL_DIALOG_FROM_GROUP_LOADING,
    REMOVE_MODAL_DIALOG_FROM_GROUP_FULFILLED,
    REMOVE_MODAL_DIALOG_FROM_GROUP_FAILED,
    REMOVE_MODAL_DIALOG_FROM_GROUP_RESET,

    ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_LOADING,
    ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FULFILLED,
    ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FAILED,
    ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_RESET,

    REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_LOADING,
    REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FULFILLED,
    REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FAILED,
    REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_RESET,
} from '../types';

const initialState = {
    getModalDialogGroupsLoading: false,
    getModalDialogGroupsFulfilled: false,
    getModalDialogGroupsFailed: false,
    getModalDialogGroupsResponse: {},

    createModalDialogGroupLoading: false,
    createModalDialogGroupFulfilled: false,
    createModalDialogGroupFailed: false,
    createModalDialogGroupResponse: {},

    updateModalDialogGroupLoading: false,
    updateModalDialogGroupFulfilled: false,
    updateModalDialogGroupFailed: false,
    updateModalDialogGroupResponse: {},

    archiveModalDialogGroupLoading: false,
    archiveModalDialogGroupFulfilled: false,
    archiveModalDialogGroupFailed: false,
    archiveModalDialogGroupResponse: {},

    getModalDialogGroupDetailsLoading: false,
    getModalDialogGroupDetailsFulfilled: false,
    getModalDialogGroupDetailsFailed: false,
    getModalDialogGroupDetailsResponse: {},

    addModalDialogToGroupLoading: false,
    addModalDialogToGroupFulfilled: false,
    addModalDialogToGroupFailed: false,
    addModalDialogToGroupResponse: {},

    removeModalDialogFromGroupLoading: false,
    removeModalDialogFromGroupFulfilled: false,
    removeModalDialogFromGroupFailed: false,
    removeModalDialogFromGroupResponse: {},

    addModalDialogGroupToCampaignLoading: false,
    addModalDialogGroupToCampaignFulfilled: false,
    addModalDialogGroupToCampaignFailed: false,
    addModalDialogGroupToCampaignResponse: {},

    removeModalDialogGroupFromCampaignLoading: false,
    removeModalDialogGroupFromCampaignFulfilled: false,
    removeModalDialogGroupFromCampaignFailed: false,
    removeModalDialogGroupFromCampaignResponse: {},
};

const ModalDialogGroupReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_MODAL_DIALOG_GROUPS_LOADING:
        return {
            ...state,
            getModalDialogGroupsLoading: true,
            getModalDialogGroupsFulfilled: false,
            getModalDialogGroupsFailed: false,
        };

    case GET_MODAL_DIALOG_GROUPS_FULFILLED: {
        const tempGetModalDialogGroupsResponse = {...state.getModalDialogGroupsResponse};
        tempGetModalDialogGroupsResponse.offset = action?.payload?.offset;
        tempGetModalDialogGroupsResponse.total = action?.payload?.total;
        tempGetModalDialogGroupsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetModalDialogGroupsResponse.list = (tempGetModalDialogGroupsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetModalDialogGroupsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getModalDialogGroupsLoading: false,
            getModalDialogGroupsFulfilled: true,
            getModalDialogGroupsFailed: false,
            getModalDialogGroupsResponse: tempGetModalDialogGroupsResponse,
        };
    }

    case GET_MODAL_DIALOG_GROUPS_FAILED:
        return {
            ...state,
            getModalDialogGroupsLoading: false,
            getModalDialogGroupsFulfilled: false,
            getModalDialogGroupsFailed: true,
        };


    case CREATE_MODAL_DIALOG_GROUP_LOADING:
        return {
            ...state,
            createModalDialogGroupLoading: true,
            createModalDialogGroupFulfilled: false,
            createModalDialogGroupFailed: false,
        };

    case CREATE_MODAL_DIALOG_GROUP_FULFILLED:
        return {
            ...state,
            createModalDialogGroupLoading: false,
            createModalDialogGroupFulfilled: true,
            createModalDialogGroupFailed: false,
            createModalDialogGroupResponse: action?.payload,
        };

    case CREATE_MODAL_DIALOG_GROUP_FAILED:
        return {
            ...state,
            createModalDialogGroupLoading: false,
            createModalDialogGroupFulfilled: false,
            createModalDialogGroupFailed: true,
            createModalDialogGroupResponse: action?.payload,
        };

    case CREATE_MODAL_DIALOG_GROUP_RESET:
        return {
            ...state,
            createModalDialogGroupLoading: initialState.createModalDialogGroupLoading,
            createModalDialogGroupFulfilled: initialState.createModalDialogGroupFulfilled,
            createModalDialogGroupFailed: initialState.createModalDialogGroupFailed,
            createModalDialogGroupResponse: initialState.createModalDialogGroupResponse
        };


    case ARCHIVE_MODAL_DIALOG_GROUP_LOADING:
        return {
            ...state,
            archiveModalDialogGroupLoading: true,
            archiveModalDialogGroupFulfilled: false,
            archiveModalDialogGroupFailed: false,
        };

    case ARCHIVE_MODAL_DIALOG_GROUP_FULFILLED: {
        const tempGetModalDialogGroupsResponse = {...state.getModalDialogGroupsResponse};
        tempGetModalDialogGroupsResponse.list = tempGetModalDialogGroupsResponse.list || [];
        const archivedModalDialogGroupIndex = tempGetModalDialogGroupsResponse.list.findIndex(modalDialogGroup => (modalDialogGroup?._id===action?.payload?._id));
        if (archivedModalDialogGroupIndex>=0) {
            tempGetModalDialogGroupsResponse.list.splice(archivedModalDialogGroupIndex, 1);
        }

        return {
            ...state,
            archiveModalDialogGroupLoading: false,
            archiveModalDialogGroupFulfilled: true,
            archiveModalDialogGroupFailed: false,
            archiveModalDialogGroupResponse: action?.payload,
            getModalDialogGroupsResponse: tempGetModalDialogGroupsResponse
        };
    }

    case ARCHIVE_MODAL_DIALOG_GROUP_FAILED:
        return {
            ...state,
            archiveModalDialogGroupLoading: false,
            archiveModalDialogGroupFulfilled: false,
            archiveModalDialogGroupFailed: true,
            archiveModalDialogGroupResponse: action?.payload,
        };

    case ARCHIVE_MODAL_DIALOG_GROUP_RESET:
        return {
            ...state,
            archiveModalDialogGroupLoading: initialState.archiveModalDialogGroupLoading,
            archiveModalDialogGroupFulfilled: initialState.archiveModalDialogGroupFulfilled,
            archiveModalDialogGroupFailed: initialState.archiveModalDialogGroupFailed,
            archiveModalDialogGroupResponse: initialState.archiveModalDialogGroupResponse
        };


    case UPDATE_MODAL_DIALOG_GROUP_LOADING:
        return {
            ...state,
            updateModalDialogGroupLoading: true,
            updateModalDialogGroupFulfilled: false,
            updateModalDialogGroupFailed: false,
        };

    case UPDATE_MODAL_DIALOG_GROUP_FULFILLED:
        return {
            ...state,
            updateModalDialogGroupLoading: false,
            updateModalDialogGroupFulfilled: true,
            updateModalDialogGroupFailed: false,
            updateModalDialogGroupResponse: action?.payload,
        };

    case UPDATE_MODAL_DIALOG_GROUP_FAILED:
        return {
            ...state,
            updateModalDialogGroupLoading: false,
            updateModalDialogGroupFulfilled: false,
            updateModalDialogGroupFailed: true,
            updateModalDialogGroupResponse: action?.payload,
        };

    case UPDATE_MODAL_DIALOG_GROUP_RESET:
        return {
            ...state,
            updateModalDialogGroupLoading: initialState.updateModalDialogGroupLoading,
            updateModalDialogGroupFulfilled: initialState.updateModalDialogGroupFulfilled,
            updateModalDialogGroupFailed: initialState.updateModalDialogGroupFailed,
            updateModalDialogGroupResponse: initialState.updateModalDialogGroupResponse
        };


    case GET_MODAL_DIALOG_GROUP_DETAILS_LOADING:
        return {
            ...state,
            getModalDialogGroupDetailsLoading: true,
            getModalDialogGroupDetailsFulfilled: false,
            getModalDialogGroupDetailsFailed: false,
        };

    case GET_MODAL_DIALOG_GROUP_DETAILS_FULFILLED: {
        const tempGetModalDialogGroupDetailsResponse = {
            ...state.getModalDialogGroupDetailsResponse
        };
        const _id = action?.payload?._id;
        if (_id) {
            tempGetModalDialogGroupDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getModalDialogGroupDetailsLoading: false,
            getModalDialogGroupDetailsFulfilled: true,
            getModalDialogGroupDetailsFailed: false,
            getModalDialogGroupDetailsResponse: tempGetModalDialogGroupDetailsResponse,
        };
    }

    case GET_MODAL_DIALOG_GROUP_DETAILS_FAILED:
        return {
            ...state,
            getModalDialogGroupDetailsLoading: false,
            getModalDialogGroupDetailsFulfilled: false,
            getModalDialogGroupDetailsFailed: true,
            getModalDialogGroupDetailsResponse: action?.payload,
        };


    case ADD_MODAL_DIALOG_TO_GROUP_LOADING:
        return {
            ...state,
            addModalDialogToGroupLoading: true,
            addModalDialogToGroupFulfilled: false,
            addModalDialogToGroupFailed: false,
        };

    case ADD_MODAL_DIALOG_TO_GROUP_FULFILLED:
        return {
            ...state,
            addModalDialogToGroupLoading: false,
            addModalDialogToGroupFulfilled: true,
            addModalDialogToGroupFailed: false,
            addModalDialogToGroupResponse: action?.payload,
        };

    case ADD_MODAL_DIALOG_TO_GROUP_FAILED:
        return {
            ...state,
            addModalDialogToGroupLoading: false,
            addModalDialogToGroupFulfilled: false,
            addModalDialogToGroupFailed: true,
            addModalDialogToGroupResponse: action?.payload,
        };

    case ADD_MODAL_DIALOG_TO_GROUP_RESET:
        return {
            ...state,
            addModalDialogToGroupLoading: initialState.addModalDialogToGroupLoading,
            addModalDialogToGroupFulfilled: initialState.addModalDialogToGroupFulfilled,
            addModalDialogToGroupFailed: initialState.addModalDialogToGroupFailed,
            addModalDialogToGroupResponse: initialState.addModalDialogToGroupResponse
        };


    case REMOVE_MODAL_DIALOG_FROM_GROUP_LOADING:
        return {
            ...state,
            removeModalDialogFromGroupLoading: true,
            removeModalDialogFromGroupFulfilled: false,
            removeModalDialogFromGroupFailed: false,
        };

    case REMOVE_MODAL_DIALOG_FROM_GROUP_FULFILLED:
        return {
            ...state,
            removeModalDialogFromGroupLoading: false,
            removeModalDialogFromGroupFulfilled: true,
            removeModalDialogFromGroupFailed: false,
            removeModalDialogFromGroupResponse: action?.payload,
        };

    case REMOVE_MODAL_DIALOG_FROM_GROUP_FAILED:
        return {
            ...state,
            removeModalDialogFromGroupLoading: false,
            removeModalDialogFromGroupFulfilled: false,
            removeModalDialogFromGroupFailed: true,
            removeModalDialogFromGroupResponse: action?.payload,
        };

    case REMOVE_MODAL_DIALOG_FROM_GROUP_RESET:
        return {
            ...state,
            removeModalDialogFromGroupLoading: initialState.removeModalDialogFromGroupLoading,
            removeModalDialogFromGroupFulfilled: initialState.removeModalDialogFromGroupFulfilled,
            removeModalDialogFromGroupFailed: initialState.removeModalDialogFromGroupFailed,
            removeModalDialogFromGroupResponse: initialState.removeModalDialogFromGroupResponse
        };


    case ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_LOADING:
        return {
            ...state,
            addModalDialogGroupToCampaignLoading: true,
            addModalDialogGroupToCampaignFulfilled: false,
            addModalDialogGroupToCampaignFailed: false,
        };

    case ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FULFILLED:
        return {
            ...state,
            addModalDialogGroupToCampaignLoading: false,
            addModalDialogGroupToCampaignFulfilled: true,
            addModalDialogGroupToCampaignFailed: false,
            addModalDialogGroupToCampaignResponse: action?.payload,
        };

    case ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FAILED:
        return {
            ...state,
            addModalDialogGroupToCampaignLoading: false,
            addModalDialogGroupToCampaignFulfilled: false,
            addModalDialogGroupToCampaignFailed: true,
            addModalDialogGroupToCampaignResponse: action?.payload,
        };

    case ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_RESET:
        return {
            ...state,
            addModalDialogGroupToCampaignLoading: initialState.addModalDialogGroupToCampaignLoading,
            addModalDialogGroupToCampaignFulfilled: initialState.addModalDialogGroupToCampaignFulfilled,
            addModalDialogGroupToCampaignFailed: initialState.addModalDialogGroupToCampaignFailed,
            addModalDialogGroupToCampaignResponse: initialState.addModalDialogGroupToCampaignResponse
        };


    case REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_LOADING:
        return {
            ...state,
            removeModalDialogGroupFromCampaignLoading: true,
            removeModalDialogGroupFromCampaignFulfilled: false,
            removeModalDialogGroupFromCampaignFailed: false,
        };

    case REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FULFILLED:
        return {
            ...state,
            removeModalDialogGroupFromCampaignLoading: false,
            removeModalDialogGroupFromCampaignFulfilled: true,
            removeModalDialogGroupFromCampaignFailed: false,
            removeModalDialogGroupFromCampaignResponse: action?.payload,
        };

    case REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FAILED:
        return {
            ...state,
            removeModalDialogGroupFromCampaignLoading: false,
            removeModalDialogGroupFromCampaignFulfilled: false,
            removeModalDialogGroupFromCampaignFailed: true,
            removeModalDialogGroupFromCampaignResponse: action?.payload,
        };

    case REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_RESET:
        return {
            ...state,
            removeModalDialogGroupFromCampaignLoading: initialState.removeModalDialogGroupFromCampaignLoading,
            removeModalDialogGroupFromCampaignFulfilled: initialState.removeModalDialogGroupFromCampaignFulfilled,
            removeModalDialogGroupFromCampaignFailed: initialState.removeModalDialogGroupFromCampaignFailed,
            removeModalDialogGroupFromCampaignResponse: initialState.removeModalDialogGroupFromCampaignResponse
        };


    default:
        return state;
    }
};

export default ModalDialogGroupReducer;
