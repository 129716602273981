// Libraries
import React, {useState} from 'react';
import {Popover} from 'react-tiny-popover';

// Styles
import classes from './CustomPopover.module.scss';

export default function CustomPopover(props) {
    const {content, positions, align, padding, isDisabled} = props;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <div onMouseLeave={() => {
            setIsPopoverOpen(false);
        }}>
            <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                positions={positions ? positions : ['bottom', 'top', 'left', 'right']} // preferred positions by priority
                align={align ? align : 'end'}
                padding={padding ? padding : 0}
                content={
                    <div className={classes.dropdownMenuWrapper}>
                        {content}
                    </div>
                }
            >
                <div onClick={(event) => {
                    if (!isDisabled) {
                        event.stopPropagation();
                        event.preventDefault();
                        setIsPopoverOpen(prevState => (!prevState));
                    }
                }}>
                    {props.children}
                </div>
            </Popover>
        </div>
    );
}