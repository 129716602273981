// Libraries
import React from 'react';

// Styles
import classes from './Layout.module.scss';

function Layout(props) {
    return (
        <main className={classes.mainLayout}>{props.children}</main>
    );
}

export default Layout;