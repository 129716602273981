import {
    GET_GLOBAL_ADS_FAILED,
    GET_GLOBAL_ADS_FULFILLED,
    GET_GLOBAL_ADS_LOADING,
    RESET_GLOBAL_ADS,
    SET_GLOBAL_ADS_FAILED,
    SET_GLOBAL_ADS_FULFILLED,
    SET_GLOBAL_ADS_LOADING
} from '../types';

const initialState = {
    setGlobalAdsLoading: false,
    setGlobalAdsFulfilled: false,
    setGlobalAdsFailed: false,
    setGlobalAdsResponse: null,
    getGlobalAdsLoading: false,
    getGlobalAdsFulfilled: false,
    getGlobalAdsFailed: false,
    getGlobalAdsResponse: {},
};

const MasterOfCoinReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GLOBAL_ADS_LOADING:
        return {
            ...state,
            setGlobalAdsLoading: true,
            setGlobalAdsFulfilled: false,
            setGlobalAdsFailed: false,
            setGlobalAdsResponse: null
        };

    case SET_GLOBAL_ADS_FULFILLED:
        return {
            ...state,
            setGlobalAdsLoading: false,
            setGlobalAdsFulfilled: true,
            setGlobalAdsFailed: false,
            setGlobalAdsResponse: action?.payload,
            getGlobalAdsResponse: action?.payload,
        };

    case SET_GLOBAL_ADS_FAILED:
        return {
            ...state,
            setGlobalAdsLoading: false,
            setGlobalAdsFulfilled: false,
            setGlobalAdsFailed: true,
            setGlobalAdsResponse: action?.payload,
        };

    case RESET_GLOBAL_ADS:
        return {
            ...state,
            setGlobalAdsLoading: false,
            setGlobalAdsFulfilled: false,
            setGlobalAdsFailed: false,
            setGlobalAdsResponse: null
        };

    case GET_GLOBAL_ADS_LOADING:
        return {
            ...state,
            getGlobalAdsLoading: true,
            getGlobalAdsFulfilled: false,
            getGlobalAdsFailed: false,
        };

    case GET_GLOBAL_ADS_FULFILLED:
        return {
            ...state,
            getGlobalAdsLoading: false,
            getGlobalAdsFulfilled: true,
            getGlobalAdsFailed: false,
            getGlobalAdsResponse: action?.payload,
        };

    case GET_GLOBAL_ADS_FAILED:
        return {
            ...state,
            getGlobalAdsLoading: false,
            getGlobalAdsFulfilled: false,
            getGlobalAdsFailed: true,
        };

    default:
        return state;
    }
};

export default MasterOfCoinReducer;
