// Libraries
import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';

//Components
import CustomHLSPlayer from '../../modals/element-builder/AssetSelectionModal/CustomHLSPlayer';

// Constants & Utils
import { isHLS, isLottie, isMp4 } from '../../utils/feedMediaUtils';

export default function FeedImage({className, style, feedMedia, coverFullSize, backgroundSize}) {
    const [key, setKey] = useState(0);
    useEffect(() => {
        setKey(prevState => (prevState + 1));
    }, [feedMedia]);

    return (
        isHLS(feedMedia?.url)
            ?
            <div className={'position--relative ' + (className || '')}>
                <CustomHLSPlayer
                    src={feedMedia?.url}
                />
            </div>
            :
            isMp4(feedMedia?.url)
                ?
                <div className={'position--relative ' + (className || '')}>
                    <video className='width--full' src={feedMedia?.url} muted={true} autoPlay={false} controls={true}
                        style={{
                            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%'
                        }}/>
                </div>
                :
                isLottie(feedMedia?.url)
                    ?
                    <div className={'position--relative ' + className} style={style}>
                        <Lottie
                            key={key}
                            style={coverFullSize ? {position: 'absolute', top: 0, left: 0, bottom: 0, right: 0} : null}
                            options={{
                                loop: true,
                                autoplay: true,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                },
                                path: feedMedia?.url
                            }}
                        />
                    </div>
                    :
                    coverFullSize
                        ?
                        <div className={className} style={{
                            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                            backgroundSize: backgroundSize || 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: 'url(\'' + feedMedia?.url + '\')'
                        }}></div>
                        :
                        <img alt='' className={className} style={style} src={feedMedia?.url}/>
    );
}
