export const FEED_MEDIA_LISTING_LIMIT = 10;

export const ASSET_TYPES = {
    ELEMENT: 'element',
    CONTEST: 'contest',
    PRODUCT: 'product',
    BRAND: 'brand',
};

export const ASSET_TYPES_OPTIONS = [
    { value: ASSET_TYPES.ELEMENT, label: 'Element' },
    { value: ASSET_TYPES.CONTEST, label: 'Contest' },
    { value: ASSET_TYPES.PRODUCT, label: 'Product' },
    { value: ASSET_TYPES.BRAND, label: 'Brand' },
];

export const MEDIA_TYPES = {
    LOTTIE: 'lottie',
    EXT: 'ext',
    MP4: 'mp4',
    HLS: 'hls',
};

export const MEDIA_TYPE_OPTIONS = [
    { value: '', label: 'All' },
    { value: MEDIA_TYPES.EXT, label: 'Image' },
    { value: MEDIA_TYPES.LOTTIE, label: 'Lottie' },
    { value: MEDIA_TYPES.MP4, label: 'Video (mp4)' },
    { value: MEDIA_TYPES.HLS, label: 'Video (m3u8)' },
];

export const MEDIA_TYPE_EXT_MAP = {
    lottie: '.json',
    ext: 'image/*',
    mp4: '.mp4',
};

export const FEED_MEDIA_UPLOAD_CONFIG = {
    readAs: 'DataURL',
    accept: 'image/*,.json,.mp4',
    multiple: false,
    maxFileSize: 500, // in MB
};

export const ASSETS_FILTERS = [
    {
        title: 'Image type',
        identifier: 'imageType',
        type: 'radio',
        isMulti: false,
        categories: [
            { title: 'Image', value: MEDIA_TYPES.EXT, isSelected: false },
            { title: 'Lottie', value: MEDIA_TYPES.LOTTIE, isSelected: false },
            { title: 'MP4', value: MEDIA_TYPES.MP4, isSelected: false },
            { title: 'M3U8', value: MEDIA_TYPES.HLS, isSelected: false },
            { title: 'All', value: '', isSelected: false },
        ],
    },
];

export const HLS_CONFIG = {
    autoStartLoad: false,
    progressive: true,
    maxLoadingDelay: 4,
    minAutoBitrate: 0,
    lowLatencyMode: true,
};
