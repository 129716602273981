import {
    ARCHIVE_FEED_MEDIA_FAILED,
    ARCHIVE_FEED_MEDIA_FULFILLED,
    ARCHIVE_FEED_MEDIA_LOADING,
    ARCHIVE_FEED_MEDIA_RESET,
    CREATE_FEED_MEDIA_FAILED,
    CREATE_FEED_MEDIA_FULFILLED,
    CREATE_FEED_MEDIA_LOADING,
    CREATE_FEED_MEDIA_RESET,
    GET_FEED_MEDIA_FAILED,
    GET_FEED_MEDIA_FULFILLED,
    GET_FEED_MEDIA_LOADING,
    GET_MEDIA_TASKS_LOADING,
    GET_MEDIA_TASKS_FULFILLED,
    GET_MEDIA_TASKS_FAILED,
    GET_MEDIA_TASKS_RESET
} from '../types';

const initialState = {
    getFeedImagesLoading: false,
    getFeedImagesFulfilled: false,
    getFeedImagesFailed: false,
    getFeedImagesResponse: {},

    createFeedImageLoading: false,
    createFeedImageFulfilled: false,
    createFeedImageFailed: false,
    createFeedImageResponse: {},

    archiveFeedImageLoading: false,
    archiveFeedImageFulfilled: false,
    archiveFeedImageFailed: false,
    archiveFeedImageResponse: {},

    getMediaTasksLoading: false,
    getMediaTasksFulfilled: false,
    getMediaTasksFailed: false,
    getMediaTasksResponse: {},

};

const FeedMediaReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_FEED_MEDIA_LOADING:
        return {
            ...state,
            getFeedImagesLoading: true,
            getFeedImagesFulfilled: false,
            getFeedImagesFailed: false,
        };

    case GET_FEED_MEDIA_FULFILLED: {
        const tempGetFeedImagesResponse = {...state.getFeedImagesResponse};
        tempGetFeedImagesResponse.offset = action?.payload?.offset;
        tempGetFeedImagesResponse.total = action?.payload?.total;
        tempGetFeedImagesResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetFeedImagesResponse.list = (tempGetFeedImagesResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetFeedImagesResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getFeedImagesLoading: false,
            getFeedImagesFulfilled: true,
            getFeedImagesFailed: false,
            getFeedImagesResponse: tempGetFeedImagesResponse,
        };
    }

    case GET_FEED_MEDIA_FAILED:
        return {
            ...state,
            getFeedImagesLoading: false,
            getFeedImagesFulfilled: false,
            getFeedImagesFailed: true,
        };


    case CREATE_FEED_MEDIA_LOADING:
        return {
            ...state,
            createFeedImageLoading: true,
            createFeedImageFulfilled: false,
            createFeedImageFailed: false,
        };

    case CREATE_FEED_MEDIA_FULFILLED:
        return {
            ...state,
            createFeedImageLoading: false,
            createFeedImageFulfilled: true,
            createFeedImageFailed: false,
            createFeedImageResponse: action?.payload,
        };

    case CREATE_FEED_MEDIA_FAILED:
        return {
            ...state,
            createFeedImageLoading: false,
            createFeedImageFulfilled: false,
            createFeedImageFailed: true,
            createFeedImageResponse: action?.payload,
        };

    case CREATE_FEED_MEDIA_RESET:
        return {
            ...state,
            createFeedImageLoading: initialState.createFeedImageLoading,
            createFeedImageFulfilled: initialState.createFeedImageFulfilled,
            createFeedImageFailed: initialState.createFeedImageFailed,
            createFeedImageResponse: initialState.createFeedImageResponse
        };


    case ARCHIVE_FEED_MEDIA_LOADING:
        return {
            ...state,
            archiveFeedImageLoading: true,
            archiveFeedImageFulfilled: false,
            archiveFeedImageFailed: false,
        };

    case ARCHIVE_FEED_MEDIA_FULFILLED: {
        const tempGetFeedImagesResponse = {...state.getFeedImagesResponse};
        tempGetFeedImagesResponse.list = tempGetFeedImagesResponse.list || [];
        const archivedFeedImageIndex = tempGetFeedImagesResponse.list.findIndex(modalDialog => (modalDialog?._id === action?.payload?._id));
        if (archivedFeedImageIndex >= 0) {
            tempGetFeedImagesResponse.list.splice(archivedFeedImageIndex, 1);
        }

        return {
            ...state,
            archiveFeedImageLoading: false,
            archiveFeedImageFulfilled: true,
            archiveFeedImageFailed: false,
            archiveFeedImageResponse: action?.payload,
            getFeedImagesResponse: tempGetFeedImagesResponse
        };
    }

    case ARCHIVE_FEED_MEDIA_FAILED:
        return {
            ...state,
            archiveFeedImageLoading: false,
            archiveFeedImageFulfilled: false,
            archiveFeedImageFailed: true,
            archiveFeedImageResponse: action?.payload,
        };

    case ARCHIVE_FEED_MEDIA_RESET:
        return {
            ...state,
            archiveFeedImageLoading: initialState.archiveFeedImageLoading,
            archiveFeedImageFulfilled: initialState.archiveFeedImageFulfilled,
            archiveFeedImageFailed: initialState.archiveFeedImageFailed,
            archiveFeedImageResponse: initialState.archiveFeedImageResponse
        };

    case GET_MEDIA_TASKS_LOADING:
        return {
            ...state,
            getMediaTasksLoading: true,
            getMediaTasksFulfilled: false,
            getMediaTasksFailed: false,
        };

    case GET_MEDIA_TASKS_FULFILLED: {
        const tempGetMediaTasksResponse = {...state.getMediaTasksResponse};
        tempGetMediaTasksResponse.offset = action?.payload?.offset;
        tempGetMediaTasksResponse.total = action?.payload?.total;
        tempGetMediaTasksResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetMediaTasksResponse.list = (tempGetMediaTasksResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetMediaTasksResponse.list = action?.payload?.list || [];
        }
        return {
            ...state,
            getMediaTasksLoading: false,
            getMediaTasksFulfilled: true,
            getMediaTasksFailed: false,
            getMediaTasksResponse: tempGetMediaTasksResponse
        };
    }

    case GET_MEDIA_TASKS_FAILED:
        return {
            ...state,
            getMediaTasksLoading: false,
            getMediaTasksFulfilled: false,
            getMediaTasksFailed: true,
            getMediaTasksResponse: action?.payload
        };

    case GET_MEDIA_TASKS_RESET:
        return {
            ...state,
            getMediaTasksLoading: initialState.getMediaTasksLoading,
            getMediaTasksFulfilled: initialState.getMediaTasksFulfilled,
            getMediaTasksFailed: initialState.getMediaTasksFailed,
            getMediaTasksResponse: initialState.getMediaTasksResponse
        };

    default:
        return state;
    }
};

export default FeedMediaReducer;
