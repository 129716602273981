import {
    GET_PRODUCTS_LOADING,
    GET_PRODUCTS_FULFILLED,
    GET_PRODUCTS_FAILED,
    CREATE_PRODUCT_LOADING,
    CREATE_PRODUCT_FULFILLED,
    CREATE_PRODUCT_FAILED,
    UPDATE_PRODUCT_LOADING,
    UPDATE_PRODUCT_FULFILLED,
    UPDATE_PRODUCT_FAILED,
    UPDATE_PRODUCT_RESET,
    ARCHIVE_PRODUCT_LOADING,
    ARCHIVE_PRODUCT_FULFILLED,
    ARCHIVE_PRODUCT_FAILED,
    ARCHIVE_PRODUCT_RESET,
    GET_PRODUCT_DETAILS_LOADING,
    GET_PRODUCT_DETAILS_FULFILLED,
    GET_PRODUCT_DETAILS_FAILED,
    CREATE_PRODUCT_RESET,

} from '../types';
  
const initialState = {
    getProductsLoading: false,
    getProductsFulfilled: false,
    getProductsFailed: false,
    getProductsResponse: {},
    createProductLoading: false,
    createProductFulfilled: false,
    createProductFailed: false,
    createProductResponse: {},
    updateProductLoading: false,
    updateProductFulfilled: false,
    updateProductFailed: false,
    updateProductResponse: {},
    archiveProductLoading: false,
    archiveProductFulfilled: false,
    archiveProductFailed: false,
    archiveProductResponse: {},
    getProductDetailsLoading: false,
    getProductDetailsFulfilled: false,
    getProductDetailsFailed: false,
    getProductDetailsResponse: {}
    
};
  
const productReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PRODUCTS_LOADING:
        return {
            ...state,
            getProductsLoading: true,
            getProductsFulfilled: false,
            getProductsFailed: false,
        };
  
    case GET_PRODUCTS_FULFILLED: {
        const tempGetProductsResponse = {...state.getProductsResponse};
        tempGetProductsResponse.offset = action?.payload?.offset;
        tempGetProductsResponse.total = action?.payload?.total;
        tempGetProductsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetProductsResponse.list = (tempGetProductsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetProductsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getProductsLoading: false,
            getProductsFulfilled: true,
            getProductsFailed: false,
            getProductsResponse: tempGetProductsResponse,
        };
    }
    case GET_PRODUCTS_FAILED:
        return {
            ...state,
            getProductsLoading: false,
            getProductsFulfilled: false,
            getProductsFailed: true,
            getProductsResponse: action.payload,
        };
    case CREATE_PRODUCT_LOADING:
        return {
            ...state,
            createProductLoading: true,
        };
  
    case CREATE_PRODUCT_FULFILLED:
        return {
            ...state,
            createProductLoading: false,
            createProductFulfilled: true,
            createProductResponse: action.payload,
        };
    case CREATE_PRODUCT_FAILED:
        return {
            ...state,
            createProductLoading: false,
            createProductFulfilled: false,
            createProductFailed: true, 
        };

    case CREATE_PRODUCT_RESET:
        return {
            ...state,
            createProductLoading: initialState.archiveProductLoading,
            createProductFulfilled: initialState.archiveProductFulfilled,
            createProductFailed: initialState.archiveProductFailed,
            createProductResponse: initialState.archiveProductResponse
        };

    case ARCHIVE_PRODUCT_LOADING:
        return {
            ...state,
            archiveProductLoading: true,
            archiveProductFulfilled: false,
            archiveProductFailed: false,
        };

    case ARCHIVE_PRODUCT_FULFILLED: {
        const tempGetProductsResponse = {...state.getProductsResponse};
        tempGetProductsResponse.list = tempGetProductsResponse.list || [];
        const archivedProductIndex = tempGetProductsResponse.list.findIndex(activity => (activity?._id===action?.payload?._id));
        if (archivedProductIndex>=0) {
            tempGetProductsResponse.list.splice(archivedProductIndex, 1);
        }

        return {
            ...state,
            archiveProductLoading: false,
            archiveProductFulfilled: true,
            archiveProductFailed: false,
            archiveProductResponse: action?.payload,
            getProductsResponse: tempGetProductsResponse
        };
    }

    case ARCHIVE_PRODUCT_FAILED:
        return {
            ...state,
            archiveProductLoading: false,
            archiveProductFulfilled: false,
            archiveProductFailed: true,
            archiveProductResponse: action?.payload,
        };

    case ARCHIVE_PRODUCT_RESET:
        return {
            ...state,
            archiveProductLoading: initialState.archiveProductLoading,
            archiveProductFulfilled: initialState.archiveProductFulfilled,
            archiveProductFailed: initialState.archiveProductFailed,
            archiveProductResponse: initialState.archiveProductResponse
        };


    case UPDATE_PRODUCT_LOADING:
        return {
            ...state,
            updateProductLoading: true,
            updateProductFulfilled: false,
            updateProductFailed: false,
        };

    case UPDATE_PRODUCT_FULFILLED:
        return {
            ...state,
            updateProductLoading: false,
            updateProductFulfilled: true,
            updateProductFailed: false,
            updateProductResponse: action?.payload,
        };

    case UPDATE_PRODUCT_FAILED:
        return {
            ...state,
            updateProductLoading: false,
            updateProductFulfilled: false,
            updateProductFailed: true,
            updateProductResponse: action?.payload,
        };

    case UPDATE_PRODUCT_RESET:
        return {
            ...state,
            updateProductLoading: initialState.updateProductLoading,
            updateProductFulfilled: initialState.updateProductFulfilled,
            updateProductFailed: initialState.updateProductFailed,
            updateProductResponse: initialState.updateProductResponse
        };


    case GET_PRODUCT_DETAILS_LOADING:
        return {
            ...state,
            getProductDetailsLoading: true,
            getProductDetailsFulfilled: false,
            getProductDetailsFailed: false,
        };

    case GET_PRODUCT_DETAILS_FULFILLED: {
        const tempGetProductDetailsResponse = state.getProductDetailsResponse || {};
        const activityId = action?.payload?._id;
        if (activityId) {
            tempGetProductDetailsResponse[activityId] = action?.payload;
        }

        return {
            ...state,
            getProductDetailsLoading: false,
            getProductDetailsFulfilled: true,
            getProductDetailsFailed: false,
            getProductDetailsResponse: tempGetProductDetailsResponse,
        };
    }

    case GET_PRODUCT_DETAILS_FAILED:
        return {
            ...state,
            getProductDetailsLoading: false,
            getProductDetailsFulfilled: false,
            getProductDetailsFailed: true,
        };

    default:
        return state;
    }
};
  
export default productReducer;
  