import {
    GET_PAGES_LOADING,
    GET_PAGES_FULFILLED,
    GET_PAGES_FAILED,

    CREATE_PAGE_LOADING,
    CREATE_PAGE_FULFILLED,
    CREATE_PAGE_FAILED,
    CREATE_PAGE_RESET,

    UPDATE_PAGE_LOADING,
    UPDATE_PAGE_FULFILLED,
    UPDATE_PAGE_FAILED,
    UPDATE_PAGE_RESET,

    ARCHIVE_PAGE_LOADING,
    ARCHIVE_PAGE_FULFILLED,
    ARCHIVE_PAGE_FAILED,
    ARCHIVE_PAGE_RESET,

    GET_PAGE_DETAILS_LOADING,
    GET_PAGE_DETAILS_FULFILLED,
    GET_PAGE_DETAILS_FAILED
} from '../types';

const initialState = {
    getPagesLoading: false,
    getPagesFulfilled: false,
    getPagesFailed: false,
    getPagesResponse: {},

    createPageLoading: false,
    createPageFulfilled: false,
    createPageFailed: false,
    createPageResponse: {},

    updatePageLoading: false,
    updatePageFulfilled: false,
    updatePageFailed: false,
    updatePageResponse: {},

    archivePageLoading: false,
    archivePageFulfilled: false,
    archivePageFailed: false,
    archivePageResponse: {},

    getPageDetailsLoading: false,
    getPageDetailsFulfilled: false,
    getPageDetailsFailed: false,
    getPageDetailsResponse: {}
};

const PagesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PAGES_LOADING:
        return {
            ...state,
            getPagesLoading: true,
            getPagesFulfilled: false,
            getPagesFailed: false,
        };

    case GET_PAGES_FULFILLED: {
        const tempGetPagesResponse = {...state.getPagesResponse};
        tempGetPagesResponse.offset = action?.payload?.offset;
        tempGetPagesResponse.total = action?.payload?.total;
        tempGetPagesResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetPagesResponse.list = (tempGetPagesResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetPagesResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getPagesLoading: false,
            getPagesFulfilled: true,
            getPagesFailed: false,
            getPagesResponse: tempGetPagesResponse,
        };
    }

    case GET_PAGES_FAILED:
        return {
            ...state,
            getPagesLoading: false,
            getPagesFulfilled: false,
            getPagesFailed: true,
        };


    case CREATE_PAGE_LOADING:
        return {
            ...state,
            createPageLoading: true,
            createPageFulfilled: false,
            createPageFailed: false,
        };

    case CREATE_PAGE_FULFILLED:
        return {
            ...state,
            createPageLoading: false,
            createPageFulfilled: true,
            createPageFailed: false,
            createPageResponse: action?.payload,
        };

    case CREATE_PAGE_FAILED:
        return {
            ...state,
            createPageLoading: false,
            createPageFulfilled: false,
            createPageFailed: true,
            createPageResponse: action?.payload,
        };

    case CREATE_PAGE_RESET:
        return {
            ...state,
            createPageLoading: initialState.createPageLoading,
            createPageFulfilled: initialState.createPageFulfilled,
            createPageFailed: initialState.createPageFailed,
            createPageResponse: initialState.createPageResponse
        };


    case ARCHIVE_PAGE_LOADING:
        return {
            ...state,
            archivePageLoading: true,
            archivePageFulfilled: false,
            archivePageFailed: false,
        };

    case ARCHIVE_PAGE_FULFILLED: {
        const tempGetPagesResponse = {...state.getPagesResponse};
        tempGetPagesResponse.list = tempGetPagesResponse.list || [];
        const archivedPageIndex = tempGetPagesResponse.list.findIndex(modalDialog => (modalDialog?._id===action?.payload?._id));
        if (archivedPageIndex>=0) {
            tempGetPagesResponse.list.splice(archivedPageIndex, 1);
        }

        return {
            ...state,
            archivePageLoading: false,
            archivePageFulfilled: true,
            archivePageFailed: false,
            archivePageResponse: action?.payload,
            getPagesResponse: tempGetPagesResponse
        };
    }

    case ARCHIVE_PAGE_FAILED:
        return {
            ...state,
            archivePageLoading: false,
            archivePageFulfilled: false,
            archivePageFailed: true,
            archivePageResponse: action?.payload,
        };

    case ARCHIVE_PAGE_RESET:
        return {
            ...state,
            archivePageLoading: initialState.archivePageLoading,
            archivePageFulfilled: initialState.archivePageFulfilled,
            archivePageFailed: initialState.archivePageFailed,
            archivePageResponse: initialState.archivePageResponse
        };


    case UPDATE_PAGE_LOADING:
        return {
            ...state,
            updatePageLoading: true,
            updatePageFulfilled: false,
            updatePageFailed: false,
        };

    case UPDATE_PAGE_FULFILLED:
        return {
            ...state,
            updatePageLoading: false,
            updatePageFulfilled: true,
            updatePageFailed: false,
            updatePageResponse: action?.payload,
        };

    case UPDATE_PAGE_FAILED:
        return {
            ...state,
            updatePageLoading: false,
            updatePageFulfilled: false,
            updatePageFailed: true,
            updatePageResponse: action?.payload,
        };

    case UPDATE_PAGE_RESET:
        return {
            ...state,
            updatePageLoading: initialState.updatePageLoading,
            updatePageFulfilled: initialState.updatePageFulfilled,
            updatePageFailed: initialState.updatePageFailed,
            updatePageResponse: initialState.updatePageResponse
        };


    case GET_PAGE_DETAILS_LOADING:
        return {
            ...state,
            getPageDetailsLoading: true,
            getPageDetailsFulfilled: false,
            getPageDetailsFailed: false,
        };

    case GET_PAGE_DETAILS_FULFILLED: {
        const tempGetPageDetailsResponse = state.getPageDetailsResponse || {};
        const _id = action?.payload?._id;
        if (_id) {
            tempGetPageDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getPageDetailsLoading: false,
            getPageDetailsFulfilled: true,
            getPageDetailsFailed: false,
            getPageDetailsResponse: tempGetPageDetailsResponse,
        };
    }

    case GET_PAGE_DETAILS_FAILED:
        return {
            ...state,
            getPageDetailsLoading: false,
            getPageDetailsFulfilled: false,
            getPageDetailsFailed: true,
            getPageDetailsResponse: action?.payload,
        };


    default:
        return state;
    }
};

export default PagesReducer;
