import {
    ARCHIVE_ACTIVITY_FAILED,
    ARCHIVE_ACTIVITY_FULFILLED,
    ARCHIVE_ACTIVITY_LOADING,
    ARCHIVE_ACTIVITY_RESET,
    CREATE_ACTIVITY_FAILED,
    CREATE_ACTIVITY_FULFILLED,
    CREATE_ACTIVITY_LOADING,
    CREATE_ACTIVITY_RESET,
    GET_ACTIVITIES_FAILED,
    GET_ACTIVITIES_FULFILLED,
    GET_ACTIVITIES_LOADING,
    GET_ACTIVITY_DETAILS_FAILED,
    GET_ACTIVITY_DETAILS_FULFILLED,
    GET_ACTIVITY_DETAILS_LOADING,
    UPDATE_ACTIVITY_FAILED,
    UPDATE_ACTIVITY_FULFILLED,
    UPDATE_ACTIVITY_LOADING,
    UPDATE_ACTIVITY_RESET
} from '../types';

const initialState = {
    getActivitiesLoading: false,
    getActivitiesFulfilled: false,
    getActivitiesFailed: false,
    getActivitiesResponse: {},

    createActivityLoading: false,
    createActivityFulfilled: false,
    createActivityFailed: false,
    createActivityResponse: {},

    updateActivityLoading: false,
    updateActivityFulfilled: false,
    updateActivityFailed: false,
    updateActivityResponse: {},

    archiveActivityLoading: false,
    archiveActivityFulfilled: false,
    archiveActivityFailed: false,
    archiveActivityResponse: {},

    getActivityDetailsLoading: false,
    getActivityDetailsFulfilled: false,
    getActivityDetailsFailed: false,
    getActivityDetailsResponse: {}
};

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITIES_LOADING:
            return {
                ...state,
                getActivitiesLoading: true,
                getActivitiesFulfilled: false,
                getActivitiesFailed: false,
            };

        case GET_ACTIVITIES_FULFILLED:
            const tempGetActivitiesResponse = {...state.getActivitiesResponse};
            tempGetActivitiesResponse.offset = action?.payload?.offset;
            tempGetActivitiesResponse.total = action?.payload?.total;
            tempGetActivitiesResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetActivitiesResponse.list = (tempGetActivitiesResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetActivitiesResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getActivitiesLoading: false,
                getActivitiesFulfilled: true,
                getActivitiesFailed: false,
                getActivitiesResponse: tempGetActivitiesResponse,
            };

        case GET_ACTIVITIES_FAILED:
            return {
                ...state,
                getActivitiesLoading: false,
                getActivitiesFulfilled: false,
                getActivitiesFailed: true,
            };


        case CREATE_ACTIVITY_LOADING:
            return {
                ...state,
                createActivityLoading: true,
                createActivityFulfilled: false,
                createActivityFailed: false,
            };

        case CREATE_ACTIVITY_FULFILLED:
            return {
                ...state,
                createActivityLoading: false,
                createActivityFulfilled: true,
                createActivityFailed: false,
                createActivityResponse: action?.payload,
            };

        case CREATE_ACTIVITY_FAILED:
            return {
                ...state,
                createActivityLoading: false,
                createActivityFulfilled: false,
                createActivityFailed: true,
                createActivityResponse: action?.payload,
            };

        case CREATE_ACTIVITY_RESET:
            return {
                ...state,
                createActivityLoading: initialState.createActivityLoading,
                createActivityFulfilled: initialState.createActivityFulfilled,
                createActivityFailed: initialState.createActivityFailed,
                createActivityResponse: initialState.createActivityResponse
            };


        case ARCHIVE_ACTIVITY_LOADING:
            return {
                ...state,
                archiveActivityLoading: true,
                archiveActivityFulfilled: false,
                archiveActivityFailed: false,
            };

        case ARCHIVE_ACTIVITY_FULFILLED: {
            const tempGetActivitiesResponse = {...state.getActivitiesResponse};
            tempGetActivitiesResponse.list = tempGetActivitiesResponse.list || [];
            const archivedActivityIndex = tempGetActivitiesResponse.list.findIndex(activity => (activity?._id === action?.payload?._id));
            if (archivedActivityIndex >= 0) {
                tempGetActivitiesResponse.list.splice(archivedActivityIndex, 1);
            }

            return {
                ...state,
                archiveActivityLoading: false,
                archiveActivityFulfilled: true,
                archiveActivityFailed: false,
                archiveActivityResponse: action?.payload,
                getActivitiesResponse: tempGetActivitiesResponse
            };
        }

        case ARCHIVE_ACTIVITY_FAILED:
            return {
                ...state,
                archiveActivityLoading: false,
                archiveActivityFulfilled: false,
                archiveActivityFailed: true,
                archiveActivityResponse: action?.payload,
            };

        case ARCHIVE_ACTIVITY_RESET:
            return {
                ...state,
                archiveActivityLoading: initialState.archiveActivityLoading,
                archiveActivityFulfilled: initialState.archiveActivityFulfilled,
                archiveActivityFailed: initialState.archiveActivityFailed,
                archiveActivityResponse: initialState.archiveActivityResponse
            };


        case UPDATE_ACTIVITY_LOADING:
            return {
                ...state,
                updateActivityLoading: true,
                updateActivityFulfilled: false,
                updateActivityFailed: false,
            };

        case UPDATE_ACTIVITY_FULFILLED: {
            const tempGetActivitiesResponse = {...state.getActivitiesResponse};
            tempGetActivitiesResponse.list = tempGetActivitiesResponse.list || [];
            const archivedActivityIndex = tempGetActivitiesResponse.list.findIndex(activity => (activity?._id === action?.payload?._id));
            if (archivedActivityIndex >= 0 && action?.payload) {
                tempGetActivitiesResponse.list.splice(archivedActivityIndex, 1, action?.payload);
            }


            return {
                ...state,
                updateActivityLoading: false,
                updateActivityFulfilled: true,
                updateActivityFailed: false,
                updateActivityResponse: action?.payload,
                getActivitiesResponse: tempGetActivitiesResponse
            };
        }

        case UPDATE_ACTIVITY_FAILED:
            return {
                ...state,
                updateActivityLoading: false,
                updateActivityFulfilled: false,
                updateActivityFailed: true,
                updateActivityResponse: action?.payload,
            };

        case UPDATE_ACTIVITY_RESET:
            return {
                ...state,
                updateActivityLoading: initialState.updateActivityLoading,
                updateActivityFulfilled: initialState.updateActivityFulfilled,
                updateActivityFailed: initialState.updateActivityFailed,
                updateActivityResponse: initialState.updateActivityResponse
            };


        case GET_ACTIVITY_DETAILS_LOADING:
            return {
                ...state,
                getActivityDetailsLoading: true,
                getActivityDetailsFulfilled: false,
                getActivityDetailsFailed: false,
            };

        case GET_ACTIVITY_DETAILS_FULFILLED: {
            const tempGetActivityDetailsResponse = state.getActivityDetailsResponse || {};
            const activityId = action?.payload?._id;
            if (activityId) {
                tempGetActivityDetailsResponse[activityId] = action?.payload;
            }

            return {
                ...state,
                getActivityDetailsLoading: false,
                getActivityDetailsFulfilled: true,
                getActivityDetailsFailed: false,
                getActivityDetailsResponse: tempGetActivityDetailsResponse,
            };
        }

        case GET_ACTIVITY_DETAILS_FAILED:
            return {
                ...state,
                getActivityDetailsLoading: false,
                getActivityDetailsFulfilled: false,
                getActivityDetailsFailed: true,
            };


        default:
            return state;
    }
};

export default activityReducer;
