import {ACTIVITY_TYPES} from './activityConstants';

export const CONTESTS_LISTING_LIMIT = 12;
export const CONTEST_LEADERBOARD_LIMIT = 50;
export const REVIEW_CONTESTS_LISTING_LIMIT = 12;

export const CREATION_PAGE_TYPES = {
    SINGLE_CREATION: 'singleCreation',
    BULK_CREATION: 'bulkCreation'
};

export const CONTEST_TEMPLATE_TYPE_OPTIONS = [
    {value: 1, label: 'Template 1'},
    {value: 2, label: 'Template 2'}
];

export const REG_END_TIME_DEFAULT_MINUTES_TO_SUBTRACT = 30;
export const REG_END_TIME_DEFAULT_MINUTES_TO_SUBTRACT_LARGE = 60;

export const DEFAULT_CONTEST_RULES = [
    {description: 'Famcoins used for joining the contest can only be refunded in case the contest is cancelled'},
    {description: 'Once joined, you will get limited free replay chances'},
    {description: 'You\'ll get an option to either watch an ad or pay with Famcoins for every additional replay'},
    {description: 'Any score made after contest end time will not be counted'},
    {description: 'You will be eligible for rewards only if you\'re in the winning range'},
    {description: 'All coin winnings will be auto credited while cash winnings will have to be claimed from \'All winnings\' tab on rewards page'},
    {description: 'FamPay has the right to restrict any user from participating in case of foul play'}
];

export const CONTESTS_FILTERS = [
    {
        title: 'Sort by',
        identifier: 'sortBy',
        type: 'radio',
        categories: [
            {title: 'Latest', value: 'latest', isSelected: false},
            {title: 'Earliest', value: 'earliest', isSelected: false}
        ]
    },
    {
        title: 'By time',
        identifier: 'time',
        type: 'checkbox',
        isMulti: true,
        categories: [
            {title: 'Upcoming', value: 'upcoming', isSelected: false},
            {title: 'Ending soon', value: 'endingSoon', isSelected: false},
            {title: 'Ended', value: 'ended', isSelected: false}
        ]
    },
    {
        title: 'By status',
        identifier: 'status',
        type: 'checkbox',
        isMulti: true,
        categories: [
            {title: 'Active', value: 'active', isSelected: false},
            {title: 'Building', value: 'build', isSelected: false},
            {title: 'Review', value: 'review', isSelected: false},
            {title: 'Active review', value: 'active_review', isSelected: false},
            {title: 'Active build', value: 'active_build', isSelected: false}
        ]
    }
];

export const CONTEST_STATES = {
    BUILD: 'build',
    REVIEW: 'review',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    ARCHIVE: 'archive',
    REQUEST_ARCHIVE: 'request_archive',
    REQUEST_INACTIVE: 'request_inactive',
    ACTIVE_REVIEW: 'active_review',
    ACTIVE_BUILD: 'active_build',
    DELETE: 'delete'
};

export const CONTEST_LISTING_VIEW_TYPES = {
    GRID: 'grid',
    LIST: 'list'
};

export const MAX_COIN_RANGE = 100000000;

export const emptyContestObj = {
    activity: ACTIVITY_TYPES.GAME,
    flags: {shareable: false},
    detailsToShow: {
        media: [], rules: {
            list: []
        }
    },
    operateOptions: {
        adsOptions: {
            disableAds: false,
            disableBannerAds: false,
            skipAdCost: [{range: {start: 1, end: MAX_COIN_RANGE}, currencyType: 'coins', currencyValue: 50}]
        },
        winningCondition: {},
        targets: [{type: 'max'}]
    },
    joinOptions: {
        costs: [{currencyType: 'coins', currencyValue: 0}],
        tags: [],
        groupingData: {maxGroups: 1}
    },
    reward: {
        disburseType: 'slab',
        slabType: 'rank',
        slabs: [{from: 1, to: 1, winnings: []}, {from: 2, to: 2, winnings: []}, {from: 3, to: 3, winnings: []}]
    }
};

export const REWARD_SLAB_TYPES = {
    RANK: 'rank',
    SCORE: 'score'
};

export const REWARD_SLAB_TYPE_OPTIONS = [
    {value: REWARD_SLAB_TYPES.RANK, label: 'Rank'},
    {value: REWARD_SLAB_TYPES.SCORE, label: 'Score'}
];

export const CONTEST_TYPES = {
    PLAYER_CHALLENGE: 'player_challenge',
    MULTIPLAYER: 'multiplayer',
    MISSION: 'player_mission'
}

export const CONTEST_TYPES_OPTIONS = [
    {value: CONTEST_TYPES.PLAYER_CHALLENGE, label: 'Single-player'},
    {value: CONTEST_TYPES.MULTIPLAYER, label: 'Multi-player'}
];

export const CONTEST_TEMPLATES_SYNC_OPERATION_TYPES = [
    {value: 'replace', label: 'Replace'},
    {value: 'replace', label: 'Add'}
];