import {
    GET_GLOBAL_REELS_FAILED,
    GET_GLOBAL_REELS_FULFILLED,
    GET_GLOBAL_REELS_LOADING,
    RESET_GLOBAL_REELS,
    SET_GLOBAL_REELS_FAILED,
    SET_GLOBAL_REELS_FULFILLED,
    SET_GLOBAL_REELS_LOADING
} from '../types';

const initialState = {
    setGlobalReelsLoading: false,
    setGlobalReelsFulfilled: false,
    setGlobalReelsFailed: false,
    setGlobalReelsResponse: null,
    getGlobalReelsLoading: false,
    getGlobalReelsFulfilled: false,
    getGlobalReelsFailed: false,
    getGlobalReelsResponse: {},
};

const FamHomeReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_GLOBAL_REELS_LOADING:
        return {
            ...state,
            setGlobalReelsLoading: true,
            setGlobalReelsFulfilled: false,
            setGlobalReelsFailed: false,
            setGlobalReelsResponse: null
        };

    case SET_GLOBAL_REELS_FULFILLED:
        return {
            ...state,
            setGlobalReelsLoading: false,
            setGlobalReelsFulfilled: true,
            setGlobalReelsFailed: false,
            setGlobalReelsResponse: action?.payload,
            getGlobalReelsResponse: action?.payload,
        };

    case SET_GLOBAL_REELS_FAILED:
        return {
            ...state,
            setGlobalReelsLoading: false,
            setGlobalReelsFulfilled: false,
            setGlobalReelsFailed: true,
            setGlobalReelsResponse: action?.payload,
        };

    case RESET_GLOBAL_REELS:
        return {
            ...state,
            setGlobalReelsLoading: false,
            setGlobalReelsFulfilled: false,
            setGlobalReelsFailed: false,
            setGlobalReelsResponse: null
        };

    case GET_GLOBAL_REELS_LOADING:
        return {
            ...state,
            getGlobalReelsLoading: true,
            getGlobalReelsFulfilled: false,
            getGlobalReelsFailed: false,
        };

    case GET_GLOBAL_REELS_FULFILLED:
        return {
            ...state,
            getGlobalReelsLoading: false,
            getGlobalReelsFulfilled: true,
            getGlobalReelsFailed: false,
            getGlobalReelsResponse: action?.payload,
        };

    case GET_GLOBAL_REELS_FAILED:
        return {
            ...state,
            getGlobalReelsLoading: false,
            getGlobalReelsFulfilled: false,
            getGlobalReelsFailed: true,
        };

    default:
        return state;
    }
};

export default FamHomeReducer;
