import {
    GET_MEMBERS_LOADING,
    GET_MEMBERS_FULFILLED,
    GET_MEMBERS_FAILED,

    INVITE_MEMBERS_LOADING,
    INVITE_MEMBERS_FULFILLED,
    INVITE_MEMBERS_FAILED,
    INVITE_MEMBERS_RESET,

    UPDATE_MEMBER_DETAILS_LOADING,
    UPDATE_MEMBER_DETAILS_FULFILLED,
    UPDATE_MEMBER_DETAILS_FAILED,
    UPDATE_MEMBER_DETAILS_RESET,

    ARCHIVE_MEMBER_LOADING,
    ARCHIVE_MEMBER_FULFILLED,
    ARCHIVE_MEMBER_FAILED,
    ARCHIVE_MEMBER_RESET,

    GET_MEMBER_DETAILS_LOADING,
    GET_MEMBER_DETAILS_FULFILLED,
    GET_MEMBER_DETAILS_FAILED,

} from '../types';

const initialState = {
    getMembersLoading: false,
    getMembersFulfilled: false,
    getMembersFailed: false,
    getMembersResponse: {},

    inviteMembersLoading: false,
    inviteMembersFulfilled: false,
    inviteMembersFailed: false,
    inviteMembersResponse: {},

    updateMemberDetailsLoading: false,
    updateMemberDetailsFulfilled: false,
    updateMemberDetailsFailed: false,
    updateMemberDetailsResponse: {},

    archiveMemberLoading: false,
    archiveMemberFulfilled: false,
    archiveMemberFailed: false,
    archiveMemberResponse: {},

    getMemberDetailsLoading: false,
    getMemberDetailsFulfilled: false,
    getMemberDetailsFailed: false,
    getMemberDetailsResponse: {}
};

const memberReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_MEMBERS_LOADING:
        return {
            ...state,
            getMembersLoading: true,
            getMembersFulfilled: false,
            getMembersFailed: false,
        };

    case GET_MEMBERS_FULFILLED: {
        const tempGetMembersResponse = {...state.getMembersResponse};
        tempGetMembersResponse.data = action?.payload;
        return {
            ...state,
            getMembersLoading: false,
            getMembersFulfilled: true,
            getMembersFailed: false,
            getMembersResponse: tempGetMembersResponse,
        };
    }
    case GET_MEMBERS_FAILED:
        return {
            ...state,
            getMembersLoading: false,
            getMembersFulfilled: false,
            getMembersFailed: true,
        };

    case INVITE_MEMBERS_LOADING:
        return {
            ...state,
            inviteMembersLoading: true,
            inviteMembersFulfilled: false,
            inviteMembersFailed: false,
        };

    case INVITE_MEMBERS_FULFILLED:
        return {
            ...state,
            inviteMembersLoading: false,
            inviteMembersFulfilled: true,
            inviteMembersFailed: false,
            inviteMembersResponse: action?.payload,
        };

    case INVITE_MEMBERS_FAILED:
        return {
            ...state,
            inviteMembersLoading: false,
            inviteMembersFulfilled: false,
            inviteMembersFailed: true,
            inviteMembersResponse: action?.payload,
        };

    case INVITE_MEMBERS_RESET:
        return {
            ...state,
            inviteMembersLoading: initialState.inviteMembersLoading,
            inviteMembersFulfilled: initialState.inviteMembersFulfilled,
            inviteMembersFailed: initialState.inviteMembersFailed,
            inviteMembersResponse: initialState.inviteMembersResponse
        };

    case ARCHIVE_MEMBER_LOADING:
        return {
            ...state,
            archiveMemberLoading: true,
            archiveMemberFulfilled: false,
            archiveMemberFailed: false,
        };

    case ARCHIVE_MEMBER_FULFILLED: {
        const tempGetMembersResponse = {...state.getMembersResponse};
        tempGetMembersResponse.data = tempGetMembersResponse.data || [];
        const archivedMemberIndex = tempGetMembersResponse.data.findIndex(member => (member?.id===action?.payload?.id));
        if (archivedMemberIndex>=0) {
            tempGetMembersResponse.data.splice(archivedMemberIndex, 1);
        }

        const tempGetMemberDetailsResponse = {...state.getMemberDetailsResponse};

        if(tempGetMemberDetailsResponse[action?.payload?.id]) {
            delete tempGetMemberDetailsResponse[action?.payload?.id];
        }

        return {
            ...state,
            archiveMemberLoading: false,
            archiveMemberFulfilled: true,
            archiveMemberFailed: false,
            archiveMemberResponse: action?.payload,
            getMembersResponse: tempGetMembersResponse,
            getMemberDetailsResponse: tempGetMemberDetailsResponse
        };
    }

    case ARCHIVE_MEMBER_FAILED:
        return {
            ...state,
            archiveMemberLoading: false,
            archiveMemberFulfilled: false,
            archiveMemberFailed: true,
            archiveMemberResponse: action?.payload,
        };

    case ARCHIVE_MEMBER_RESET:
        return {
            ...state,
            archiveMemberLoading: initialState.archiveMemberLoading,
            archiveMemberFulfilled: initialState.archiveMemberFulfilled,
            archiveMemberFailed: initialState.archiveMemberFailed,
            archiveMemberResponse: initialState.archiveMemberResponse
        };


    case UPDATE_MEMBER_DETAILS_LOADING:
        return {
            ...state,
            updateMemberDetailsLoading: true,
            updateMemberDetailsFulfilled: false,
            updateMemberDetailsFailed: false,
        };

    case UPDATE_MEMBER_DETAILS_FULFILLED: {
        const tempGetMembersResponse = {...state.getMembersResponse};
        tempGetMembersResponse.data = tempGetMembersResponse.data || [];
        const archivedMemberIndex = tempGetMembersResponse.data.findIndex(member => (member?.id === action?.payload?.id));
        if (archivedMemberIndex >= 0) {
            tempGetMembersResponse.data.splice(archivedMemberIndex, 1, action?.payload);
        }

        const tempGetMemberDetailsResponse = {...state.getMemberDetailsResponse};

        if (tempGetMemberDetailsResponse[action?.payload?.id]) {
            tempGetMemberDetailsResponse[action?.payload?.id] = action?.payload;
        }
        return {
            ...state,
            updateMemberDetailsLoading: false,
            updateMemberDetailsFulfilled: true,
            updateMemberDetailsFailed: false,
            updateMemberDetailsResponse: action?.payload,
            getMembersResponse: tempGetMembersResponse,
            getMemberDetailsResponse: tempGetMemberDetailsResponse
        };
    }
    case UPDATE_MEMBER_DETAILS_FAILED:
        return {
            ...state,
            updateMemberDetailsLoading: false,
            updateMemberDetailsFulfilled: false,
            updateMemberDetailsFailed: true,
            updateMemberDetailsResponse: action?.payload,
        };

    case UPDATE_MEMBER_DETAILS_RESET:
        return {
            ...state,
            updateMemberDetailsLoading: initialState.updateMemberDetailsLoading,
            updateMemberDetailsFulfilled: initialState.updateMemberDetailsFulfilled,
            updateMemberDetailsFailed: initialState.updateMemberDetailsFailed,
            updateMemberDetailsResponse: initialState.updateMemberDetailsResponse
        };


    case GET_MEMBER_DETAILS_LOADING:
        return {
            ...state,
            getMemberDetailsLoading: true,
            getMemberDetailsFulfilled: false,
            getMemberDetailsFailed: false,
        };

    case GET_MEMBER_DETAILS_FULFILLED: {
        const tempGetMemberDetailsResponse = state.getMemberDetailsResponse || {};
        const memberId = action?.payload?.id;
        if (memberId) {
            tempGetMemberDetailsResponse[memberId] = action?.payload;
        }

        return {
            ...state,
            getMemberDetailsLoading: false,
            getMemberDetailsFulfilled: true,
            getMemberDetailsFailed: false,
            getMemberDetailsResponse: tempGetMemberDetailsResponse,
        };
    }

    case GET_MEMBER_DETAILS_FAILED:
        return {
            ...state,
            getMemberDetailsLoading: false,
            getMemberDetailsFulfilled: false,
            getMemberDetailsFailed: true,
        };


    default:
        return state;
    }
};

export default memberReducer;
