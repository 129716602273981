// Libraries
import React from 'react';
import Link from 'next/link';

// Styles
import classes from '../elements/CustomInput.module.scss';

// Components
import CustomPopover from './CustomPopover';

// Configurations
const customInputPaddingX = '15px';
const customInputPaddingY = '10px';

export default function CustomInputStyleWrapper(props) {
    const {
        className,
        size,
        isSquare,
        topLeftRadius, topRightRadius, bottomLeftRadius, bottomRightRadius,
        borderTop, borderBottom, borderLeft, borderRight, borderHighlighted, borderBottomHighlighted,
        onAction,
        link,
        hoverState,
        rightPopover,
        rightPopoverIconClass,
        noPadding,
        showValidationError,
        autoHeight,
        paddingBottom,
        paddingTop,
        paddingLeft,
        paddingRight,
        isRounded,
        isDisabled,
        bgHighlight
    } = props;

    const renderContent = () => {
        return (
            <div
                className={
                    classes.inputWrapper + ' ' + (noPadding ? '' : classes.inputWrapperPadding) + ' ' + (className || '') + ' ' +
                    (isSquare ? classes.squareSize : '') + ' ' +
                    (size ? classes[size] : '') + ' ' +
                    (topLeftRadius && classes.topLeftRadius) + ' ' +
                    (topRightRadius && classes.topRightRadius) + ' ' +
                    (bottomLeftRadius && classes.bottomLeftRadius) + ' ' +
                    (bottomRightRadius && classes.bottomRightRadius) + ' ' +
                    (borderTop ? classes.border__top : '') + ' ' +
                    (borderBottom ? classes.border__bottom : '') + ' ' +
                    (borderLeft ? classes.border__left : '') + ' ' +
                    (borderRight ? classes.border__right : '') + ' ' +
                    (borderBottomHighlighted ? classes['border__bottom--highlighted'] : '') + ' ' +
                    (borderHighlighted ? classes['border--highlighted'] : '') + ' ' +
                    (onAction || link ? 'cursor--pointer' : '') + ' ' +
                    (hoverState ? classes.hoverState : '') + ' ' +
                    (bgHighlight ? classes.bgHightlight : '') + ' ' +
                    (autoHeight ? classes.autoHeight : '') + ' ' +
                    (isRounded ? classes.rounded : '')
                }
                onClick={(event) => {
                    if (onAction && !isDisabled) {
                        onAction(event);
                    }
                }}
                style={{
                    padding: (paddingTop || customInputPaddingY) + ' ' + (paddingRight || customInputPaddingX) + ' ' + (paddingBottom || customInputPaddingY) + ' ' + (paddingLeft || customInputPaddingX)
                }}
            >
                {props.children}


                {
                    rightPopover &&
                    <CustomPopover
                        content={rightPopover}
                    >
                        <span
                            className={classes.rightFloatingArrow + ' ' + (rightPopoverIconClass ? rightPopoverIconClass : 'icon-arrow-down') + ' cursor--pointer'}></span>
                    </CustomPopover>
                }

                {showValidationError &&
                    <div className={classes.validationError}>This field is required</div>}
            </div>
        );
    };

    return (
        link
            ?
            <Link href={link}>
                {renderContent()}
            </Link>
            :
            renderContent()
    );
}