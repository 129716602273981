import {
    ADD_EXCLUSION_COHORT_FAILED,
    ADD_EXCLUSION_COHORT_FULFILLED,
    ADD_EXCLUSION_COHORT_LOADING,
    ADD_EXCLUSION_COHORT_RESET,
    ARCHIVE_CONTEST_FAILED,
    ARCHIVE_CONTEST_FULFILLED,
    ARCHIVE_CONTEST_LOADING,
    ARCHIVE_CONTEST_RESET,
    CHANGE_CONTEST_STATE_FAILED,
    CHANGE_CONTEST_STATE_FULFILLED,
    CHANGE_CONTEST_STATE_LOADING,
    CHANGE_CONTEST_STATE_RESET,
    CREATE_CONTEST_FAILED,
    CREATE_CONTEST_FULFILLED,
    CREATE_CONTEST_LOADING,
    CREATE_CONTEST_RESET,
    GET_CONTEST_ARENAS_FAILED,
    GET_CONTEST_ARENAS_FULFILLED,
    GET_CONTEST_ARENAS_LOADING,
    GET_CONTEST_DETAILS_FAILED,
    GET_CONTEST_DETAILS_FULFILLED,
    GET_CONTEST_DETAILS_LOADING,
    GET_CONTEST_LEADERBOARD_FAILED,
    GET_CONTEST_LEADERBOARD_FULFILLED,
    GET_CONTEST_LEADERBOARD_LOADING,
    GET_CONTESTS_FAILED,
    GET_CONTESTS_FULFILLED,
    GET_CONTESTS_LOADING,
    GET_CONTESTS_TO_REVIEW_FAILED,
    GET_CONTESTS_TO_REVIEW_FULFILLED,
    GET_CONTESTS_TO_REVIEW_LOADING,
    REMOVE_CONTEST_PARTICIPANT_FAILED,
    REMOVE_CONTEST_PARTICIPANT_FULFILLED,
    REMOVE_CONTEST_PARTICIPANT_LOADING,
    REMOVE_CONTEST_PARTICIPANT_RESET,
    UPDATE_CONTEST_FAILED,
    UPDATE_CONTEST_FULFILLED,
    UPDATE_CONTEST_LOADING,
    UPDATE_CONTEST_RESET,
    SYNC_CONTEST_TEMPLATES_LOADING,
    SYNC_CONTEST_TEMPLATES_FULFILLED,
    SYNC_CONTEST_TEMPLATES_FAILED,
    SYNC_CONTEST_TEMPLATES_RESET,

} from '../types';

const initialState = {
    getContestsLoading: false,
    getContestsFulfilled: false,
    getContestsFailed: false,
    getContestsResponse: {},

    getContestArenasLoading: false,
    getContestArenasFulfilled: false,
    getContestArenasFailed: false,
    getContestArenasResponse: [],

    getContestLeaderboardLoading: false,
    getContestLeaderboardFulfilled: false,
    getContestLeaderboardFailed: false,
    getContestLeaderboardResponse: {},

    getContestsToReviewLoading: false,
    getContestsToReviewFulfilled: false,
    getContestsToReviewFailed: false,
    getContestsToReviewResponse: {},

    createContestLoading: false,
    createContestFulfilled: false,
    createContestFailed: false,
    createContestResponse: {},

    updateContestLoading: false,
    updateContestFulfilled: false,
    updateContestFailed: false,
    updateContestResponse: {},

    archiveContestLoading: false,
    archiveContestFulfilled: false,
    archiveContestFailed: false,
    archiveContestResponse: {},

    getContestDetailsLoading: false,
    getContestDetailsFulfilled: false,
    getContestDetailsFailed: false,
    getContestDetailsResponse: {},

    changeContestStateLoading: false,
    changeContestStateFulfilled: false,
    changeContestStateFailed: false,
    changeContestStateResponse: {},

    removeContestParticipantLoading: false,
    removeContestParticipantFulfilled: false,
    removeContestParticipantFailed: false,
    removeContestParticipantResponse: {},

    addToExclusionCohortLoading: false,
    addToExclusionCohortFulfilled: false,
    addToExclusionCohortFailed: false,
    addToExclusionCohortResponse: {},

    syncContestTemplatesLoading: false,
    syncContestTemplatesFulfilled: false,
    syncContestTemplatesFailed: false,
    syncContestTemplatesResponse: {},

};

const ContestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTESTS_LOADING:
            return {
                ...state,
                getContestsLoading: true,
                getContestsFulfilled: false,
                getContestsFailed: false,
            };

        case GET_CONTESTS_FULFILLED: {
            const tempGetContestsResponse = {...state.getContestsResponse};
            tempGetContestsResponse.offset = action?.payload?.offset;
            tempGetContestsResponse.total = action?.payload?.total;
            tempGetContestsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetContestsResponse.list = (tempGetContestsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetContestsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getContestsLoading: false,
                getContestsFulfilled: true,
                getContestsFailed: false,
                getContestsResponse: tempGetContestsResponse,
            };
        }

        case GET_CONTESTS_FAILED:
            return {
                ...state,
                getContestsLoading: false,
                getContestsFulfilled: false,
                getContestsFailed: true,
            };


        case GET_CONTEST_ARENAS_LOADING:
            return {
                ...state,
                getContestArenasLoading: true,
                getContestArenasFulfilled: false,
                getContestArenasFailed: false,
            };

        case GET_CONTEST_ARENAS_FULFILLED:
            return {
                ...state,
                getContestArenasLoading: false,
                getContestArenasFulfilled: true,
                getContestArenasFailed: false,
                getContestArenasResponse: action?.payload || [],
            };

        case GET_CONTEST_ARENAS_FAILED:
            return {
                ...state,
                getContestArenasLoading: false,
                getContestArenasFulfilled: false,
                getContestArenasFailed: true,
            };


        case GET_CONTEST_LEADERBOARD_LOADING:
            return {
                ...state,
                getContestLeaderboardLoading: true,
                getContestLeaderboardFulfilled: false,
                getContestLeaderboardFailed: false,
            };

        case GET_CONTEST_LEADERBOARD_FULFILLED: {
            const tempGetContestLeaderboardResponse = {...state.getContestLeaderboardResponse};
            if (action?.payload?.contestId && action?.payload?.arena) {
                if (tempGetContestLeaderboardResponse?.[action?.payload?.contestId] && tempGetContestLeaderboardResponse?.[action?.payload?.contestId]?.[action?.payload?.arena]) {
                    tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena] = {...tempGetContestLeaderboardResponse?.[action?.payload?.contestId]?.[action?.payload?.arena]};
                } else {
                    if (!tempGetContestLeaderboardResponse[action?.payload?.contestId]) {
                        tempGetContestLeaderboardResponse[action?.payload?.contestId] = {};
                    }
                    tempGetContestLeaderboardResponse[action?.payload?.contestId][action.payload.arena] = {};
                }
                tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].offset = action?.payload?.offset;
                tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].total = action?.payload?.total;
                tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].hasMore = action?.payload?.hasMore;

                if (action?.payload?.reqOffset) {
                    tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].list = (tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena]?.list || []).concat(action?.payload?.list || []);
                } else {
                    tempGetContestLeaderboardResponse[action?.payload?.contestId][action?.payload?.arena].list = action?.payload?.list || [];
                }
            }

            return {
                ...state,
                getContestLeaderboardLoading: false,
                getContestLeaderboardFulfilled: true,
                getContestLeaderboardFailed: false,
                getContestLeaderboardResponse: tempGetContestLeaderboardResponse,
            };
        }

        case GET_CONTEST_LEADERBOARD_FAILED:
            return {
                ...state,
                getContestLeaderboardLoading: false,
                getContestLeaderboardFulfilled: false,
                getContestLeaderboardFailed: true,
            };


        case GET_CONTESTS_TO_REVIEW_LOADING:
            return {
                ...state,
                getContestsToReviewLoading: true,
                getContestsToReviewFulfilled: false,
                getContestsToReviewFailed: false,
            };

        case GET_CONTESTS_TO_REVIEW_FULFILLED: {
            const tempGetContestsToReviewResponse = {...state.getContestsToReviewResponse};
            tempGetContestsToReviewResponse.offset = action?.payload?.offset;
            tempGetContestsToReviewResponse.total = action?.payload?.total;
            tempGetContestsToReviewResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetContestsToReviewResponse.list = (tempGetContestsToReviewResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetContestsToReviewResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getContestsToReviewLoading: false,
                getContestsToReviewFulfilled: true,
                getContestsToReviewFailed: false,
                getContestsToReviewResponse: tempGetContestsToReviewResponse,
            };
        }

        case GET_CONTESTS_TO_REVIEW_FAILED:
            return {
                ...state,
                getContestsToReviewLoading: false,
                getContestsToReviewFulfilled: false,
                getContestsToReviewFailed: true,
            };


        case CREATE_CONTEST_LOADING:
            return {
                ...state,
                createContestLoading: true,
                createContestFulfilled: false,
                createContestFailed: false,
            };

        case CREATE_CONTEST_FULFILLED:
            return {
                ...state,
                createContestLoading: false,
                createContestFulfilled: true,
                createContestFailed: false,
                createContestResponse: action?.payload,
            };

        case CREATE_CONTEST_FAILED:
            return {
                ...state,
                createContestLoading: false,
                createContestFulfilled: false,
                createContestFailed: true,
                createContestResponse: action?.payload,
            };

        case CREATE_CONTEST_RESET:
            return {
                ...state,
                createContestLoading: initialState.createContestLoading,
                createContestFulfilled: initialState.createContestFulfilled,
                createContestFailed: initialState.createContestFailed,
                createContestResponse: initialState.createContestResponse
            };


        case ARCHIVE_CONTEST_LOADING:
            return {
                ...state,
                archiveContestLoading: true,
                archiveContestFulfilled: false,
                archiveContestFailed: false,
            };

        case ARCHIVE_CONTEST_FULFILLED: {
            const tempGetContestsResponse = {...state.getContestsResponse};
            tempGetContestsResponse.list = tempGetContestsResponse.list || [];
            const archivedContestIndex = tempGetContestsResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedContestIndex >= 0) {
                tempGetContestsResponse.list.splice(archivedContestIndex, 1);
            }

            const tempGetContestsToReviewResponse = {...state.getContestsToReviewResponse};
            tempGetContestsToReviewResponse.list = tempGetContestsToReviewResponse.list || [];
            const archivedContestToReviewIndex = tempGetContestsToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedContestToReviewIndex >= 0) {
                tempGetContestsToReviewResponse.list.splice(archivedContestToReviewIndex, 1);
            }

            return {
                ...state,
                archiveContestLoading: false,
                archiveContestFulfilled: true,
                archiveContestFailed: false,
                archiveContestResponse: action?.payload,
                getContestsResponse: tempGetContestsResponse,
                getContestsToReviewResponse: tempGetContestsToReviewResponse
            };
        }

        case ARCHIVE_CONTEST_FAILED:
            return {
                ...state,
                archiveContestLoading: false,
                archiveContestFulfilled: false,
                archiveContestFailed: true,
                archiveContestResponse: action?.payload,
            };

        case ARCHIVE_CONTEST_RESET:
            return {
                ...state,
                archiveContestLoading: initialState.archiveContestLoading,
                archiveContestFulfilled: initialState.archiveContestFulfilled,
                archiveContestFailed: initialState.archiveContestFailed,
                archiveContestResponse: initialState.archiveContestResponse
            };


        case UPDATE_CONTEST_LOADING:
            return {
                ...state,
                updateContestLoading: true,
                updateContestFulfilled: false,
                updateContestFailed: false,
            };

        case UPDATE_CONTEST_FULFILLED:
            return {
                ...state,
                updateContestLoading: false,
                updateContestFulfilled: true,
                updateContestFailed: false,
                updateContestResponse: action?.payload,
            };

        case UPDATE_CONTEST_FAILED:
            return {
                ...state,
                updateContestLoading: false,
                updateContestFulfilled: false,
                updateContestFailed: true,
                updateContestResponse: action?.payload,
            };

        case UPDATE_CONTEST_RESET:
            return {
                ...state,
                updateContestLoading: initialState.updateContestLoading,
                updateContestFulfilled: initialState.updateContestFulfilled,
                updateContestFailed: initialState.updateContestFailed,
                updateContestResponse: initialState.updateContestResponse
            };


        case GET_CONTEST_DETAILS_LOADING:
            return {
                ...state,
                getContestDetailsLoading: true,
                getContestDetailsFulfilled: false,
                getContestDetailsFailed: false,
            };

        case GET_CONTEST_DETAILS_FULFILLED: {
            const tempGetContestDetailsResponse = state.getContestDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetContestDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getContestDetailsLoading: false,
                getContestDetailsFulfilled: true,
                getContestDetailsFailed: false,
                getContestDetailsResponse: tempGetContestDetailsResponse,
            };
        }

        case GET_CONTEST_DETAILS_FAILED:
            return {
                ...state,
                getContestDetailsLoading: false,
                getContestDetailsFulfilled: false,
                getContestDetailsFailed: true,
                getContestDetailsResponse: action?.payload,
            };


        case CHANGE_CONTEST_STATE_LOADING:
            return {
                ...state,
                changeContestStateLoading: true,
                changeContestStateFulfilled: false,
                changeContestStateFailed: false,
            };

        case CHANGE_CONTEST_STATE_FULFILLED:
            return {
                ...state,
                changeContestStateLoading: false,
                changeContestStateFulfilled: true,
                changeContestStateFailed: false,
                changeContestStateResponse: action?.payload,
            };

        case CHANGE_CONTEST_STATE_FAILED:
            return {
                ...state,
                changeContestStateLoading: false,
                changeContestStateFulfilled: false,
                changeContestStateFailed: true,
                changeContestStateResponse: action?.payload,
            };

        case CHANGE_CONTEST_STATE_RESET:
            return {
                ...state,
                changeContestStateLoading: initialState.changeContestStateLoading,
                changeContestStateFulfilled: initialState.changeContestStateFulfilled,
                changeContestStateFailed: initialState.changeContestStateFailed,
                changeContestStateResponse: initialState.changeContestStateResponse
            };

        case REMOVE_CONTEST_PARTICIPANT_LOADING:
            return {
                ...state,
                removeContestParticipantLoading: true,
                removeContestParticipantFulfilled: false,
                removeContestParticipantFailed: false,
            };
        case REMOVE_CONTEST_PARTICIPANT_FULFILLED:
            return {
                ...state,
                removeContestParticipantLoading: false,
                removeContestParticipantFulfilled: true,
                removeContestParticipantFailed: false,
                removeContestParticipantResponse: action?.payload
            };
        case REMOVE_CONTEST_PARTICIPANT_FAILED:
            return {
                ...state,
                removeContestParticipantLoading: false,
                removeContestParticipantFulfilled: false,
                removeContestParticipantFailed: true,
                removeContestParticipantResponse: action?.payload
            };
        case REMOVE_CONTEST_PARTICIPANT_RESET:
            return {
                ...state,
                removeContestParticipantLoading: initialState.removeContestParticipantLoading,
                removeContestParticipantFulfilled: initialState.removeContestParticipantFulfilled,
                removeContestParticipantFailed: initialState.removeContestParticipantFailed,
                removeContestParticipantResponse: initialState.removeContestParticipantResponse
            };
        case ADD_EXCLUSION_COHORT_LOADING:
            return {
                ...state,
                addToExclusionCohortLoading: true,
                addToExclusionCohortFulfilled: false,
                addToExclusionCohortFailed: false,
            };
        case ADD_EXCLUSION_COHORT_FULFILLED:
            return {
                ...state,
                addToExclusionCohortLoading: false,
                addToExclusionCohortFulfilled: true,
                addToExclusionCohortFailed: false,
                addToExclusionCohortResponse: action?.payload
            };
        case ADD_EXCLUSION_COHORT_FAILED:
            return {
                ...state,
                addToExclusionCohortLoading: false,
                addToExclusionCohortFulfilled: false,
                addToExclusionCohortFailed: true,
                addToExclusionCohortResponse: action?.payload
            };
        case ADD_EXCLUSION_COHORT_RESET:
            return {
                ...state,
                addToExclusionCohortLoading: false,
                addToExclusionCohortFulfilled: false,
                addToExclusionCohortFailed: false,
                addToExclusionCohortResponse: {}
            };

        case SYNC_CONTEST_TEMPLATES_LOADING:
            return {
                ...state,
                syncContestTemplatesLoading: true,
                syncContestTemplatesFulfilled: false,
                syncContestTemplatesFailed: false,
            };

        case SYNC_CONTEST_TEMPLATES_FULFILLED:
            return {
                ...state,
                syncContestTemplatesLoading: false,
                syncContestTemplatesFulfilled: true,
                syncContestTemplatesFailed: false,
                syncContestTemplatesResponse: action?.payload,
            };

        case SYNC_CONTEST_TEMPLATES_FAILED:
            return {
                ...state,
                syncContestTemplatesLoading: false,
                syncContestTemplatesFulfilled: false,
                syncContestTemplatesFailed: true,
                syncContestTemplatesResponse: action?.payload,
            };

        case SYNC_CONTEST_TEMPLATES_RESET:
            return {
                ...state,
                syncContestTemplatesLoading: initialState.syncContestTemplatesLoading,
                syncContestTemplatesFulfilled: initialState.syncContestTemplatesFulfilled,
                syncContestTemplatesFailed: initialState.syncContestTemplatesFailed,
                syncContestTemplatesResponse: initialState.syncContestTemplatesResponse,
            };
        default:
            return state;
    }
};

export default ContestsReducer;
