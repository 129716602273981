import {
    GET_SECTIONS_LOADING,
    GET_SECTIONS_FULFILLED,
    GET_SECTIONS_FAILED,

    CREATE_SECTION_LOADING,
    CREATE_SECTION_FULFILLED,
    CREATE_SECTION_FAILED,
    CREATE_SECTION_RESET,

    UPDATE_SECTION_LOADING,
    UPDATE_SECTION_FULFILLED,
    UPDATE_SECTION_FAILED,
    UPDATE_SECTION_RESET,

    ARCHIVE_SECTION_LOADING,
    ARCHIVE_SECTION_FULFILLED,
    ARCHIVE_SECTION_FAILED,
    ARCHIVE_SECTION_RESET,

    GET_SECTION_DETAILS_LOADING,
    GET_SECTION_DETAILS_FULFILLED,
    GET_SECTION_DETAILS_FAILED
} from '../types';

const initialState = {
    getSectionsLoading: false,
    getSectionsFulfilled: false,
    getSectionsFailed: false,
    getSectionsResponse: {},

    createSectionLoading: false,
    createSectionFulfilled: false,
    createSectionFailed: false,
    createSectionResponse: {},

    updateSectionLoading: false,
    updateSectionFulfilled: false,
    updateSectionFailed: false,
    updateSectionResponse: {},

    archiveSectionLoading: false,
    archiveSectionFulfilled: false,
    archiveSectionFailed: false,
    archiveSectionResponse: {},

    getSectionDetailsLoading: false,
    getSectionDetailsFulfilled: false,
    getSectionDetailsFailed: false,
    getSectionDetailsResponse: {}
};

const SectionsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_SECTIONS_LOADING:
        return {
            ...state,
            getSectionsLoading: true,
            getSectionsFulfilled: false,
            getSectionsFailed: false,
        };

    case GET_SECTIONS_FULFILLED: {
        const tempGetSectionsResponse = {...state.getSectionsResponse};
        tempGetSectionsResponse.offset = action?.payload?.offset;
        tempGetSectionsResponse.total = action?.payload?.total;
        tempGetSectionsResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetSectionsResponse.list = (tempGetSectionsResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetSectionsResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getSectionsLoading: false,
            getSectionsFulfilled: true,
            getSectionsFailed: false,
            getSectionsResponse: tempGetSectionsResponse,
        };
    }

    case GET_SECTIONS_FAILED:
        return {
            ...state,
            getSectionsLoading: false,
            getSectionsFulfilled: false,
            getSectionsFailed: true,
        };


    case CREATE_SECTION_LOADING:
        return {
            ...state,
            createSectionLoading: true,
            createSectionFulfilled: false,
            createSectionFailed: false,
        };

    case CREATE_SECTION_FULFILLED:
        return {
            ...state,
            createSectionLoading: false,
            createSectionFulfilled: true,
            createSectionFailed: false,
            createSectionResponse: action?.payload,
        };

    case CREATE_SECTION_FAILED:
        return {
            ...state,
            createSectionLoading: false,
            createSectionFulfilled: false,
            createSectionFailed: true,
            createSectionResponse: action?.payload,
        };

    case CREATE_SECTION_RESET:
        return {
            ...state,
            createSectionLoading: initialState.createSectionLoading,
            createSectionFulfilled: initialState.createSectionFulfilled,
            createSectionFailed: initialState.createSectionFailed,
            createSectionResponse: initialState.createSectionResponse
        };


    case ARCHIVE_SECTION_LOADING:
        return {
            ...state,
            archiveSectionLoading: true,
            archiveSectionFulfilled: false,
            archiveSectionFailed: false,
        };

    case ARCHIVE_SECTION_FULFILLED: {
        const tempGetSectionsResponse = {...state.getSectionsResponse};
        tempGetSectionsResponse.list = tempGetSectionsResponse.list || [];
        const archivedSectionIndex = tempGetSectionsResponse.list.findIndex(modalDialog => (modalDialog?._id===action?.payload?._id));
        if (archivedSectionIndex>=0) {
            tempGetSectionsResponse.list.splice(archivedSectionIndex, 1);
        }

        return {
            ...state,
            archiveSectionLoading: false,
            archiveSectionFulfilled: true,
            archiveSectionFailed: false,
            archiveSectionResponse: action?.payload,
            getSectionsResponse: tempGetSectionsResponse
        };
    }

    case ARCHIVE_SECTION_FAILED:
        return {
            ...state,
            archiveSectionLoading: false,
            archiveSectionFulfilled: false,
            archiveSectionFailed: true,
            archiveSectionResponse: action?.payload,
        };

    case ARCHIVE_SECTION_RESET:
        return {
            ...state,
            archiveSectionLoading: initialState.archiveSectionLoading,
            archiveSectionFulfilled: initialState.archiveSectionFulfilled,
            archiveSectionFailed: initialState.archiveSectionFailed,
            archiveSectionResponse: initialState.archiveSectionResponse
        };


    case UPDATE_SECTION_LOADING:
        return {
            ...state,
            updateSectionLoading: true,
            updateSectionFulfilled: false,
            updateSectionFailed: false,
        };

    case UPDATE_SECTION_FULFILLED:
        return {
            ...state,
            updateSectionLoading: false,
            updateSectionFulfilled: true,
            updateSectionFailed: false,
            updateSectionResponse: action?.payload,
        };

    case UPDATE_SECTION_FAILED:
        return {
            ...state,
            updateSectionLoading: false,
            updateSectionFulfilled: false,
            updateSectionFailed: true,
            updateSectionResponse: action?.payload,
        };

    case UPDATE_SECTION_RESET:
        return {
            ...state,
            updateSectionLoading: initialState.updateSectionLoading,
            updateSectionFulfilled: initialState.updateSectionFulfilled,
            updateSectionFailed: initialState.updateSectionFailed,
            updateSectionResponse: initialState.updateSectionResponse
        };


    case GET_SECTION_DETAILS_LOADING:
        return {
            ...state,
            getSectionDetailsLoading: true,
            getSectionDetailsFulfilled: false,
            getSectionDetailsFailed: false,
        };

    case GET_SECTION_DETAILS_FULFILLED: {
        const tempGetSectionDetailsResponse = state.getSectionDetailsResponse || {};
        const _id = action?.payload?._id;
        if (_id) {
            tempGetSectionDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getSectionDetailsLoading: false,
            getSectionDetailsFulfilled: true,
            getSectionDetailsFailed: false,
            getSectionDetailsResponse: tempGetSectionDetailsResponse,
        };
    }

    case GET_SECTION_DETAILS_FAILED:
        return {
            ...state,
            getSectionDetailsLoading: false,
            getSectionDetailsFulfilled: false,
            getSectionDetailsFailed: true,
            getSectionDetailsResponse: action?.payload,
        };


    default:
        return state;
    }
};

export default SectionsReducer;
