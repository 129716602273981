import {
    SHOW_LOADER,
    HIDE_LOADER,
    TOGGLE_LOADER,
    
    SHOW_TOAST,
    HIDE_TOAST,
    TOGGLE_TOAST,
    
    SHOW_CONFIRMATION_MODAL,
    HIDE_CONFIRMATION_MODAL
} from '../types';

const initialState = {
    isLoaderVisible: false,

    isToastVisible: false,
    toastStatus: 'success', //success, error, info, warning
    toastMessage: 'Challenge has been successfully created.',
    
    isConfirmationModalVisible: false,
    confirmationModalConfig: {}, // title, description, primaryCta, secondaryCta
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
    case SHOW_LOADER:
        return {
            ...state,
            isLoaderVisible: true,
        };

    case HIDE_LOADER:
        return {
            ...state,
            isLoaderVisible: false,
        };

    case TOGGLE_LOADER:
        return {
            ...state,
            isLoaderVisible: !state.isLoaderVisible,
        };

    case SHOW_TOAST:
        return {
            ...state,
            isToastVisible: true,
            toastStatus: action && action.payload && action.payload.status,
            toastMessage: action && action.payload && action.payload.message
        };

    case HIDE_TOAST:
        return {
            ...state,
            isToastVisible: false,
        };

    case TOGGLE_TOAST:
        return {
            ...state,
            isToastVisible: !state.isToastVisible,
        };

    case SHOW_CONFIRMATION_MODAL:
        return {
            ...state,
            isConfirmationModalVisible: true,
            confirmationModalConfig: action?.payload || {}
        };

    case HIDE_CONFIRMATION_MODAL:
        return {
            ...state,
            isConfirmationModalVisible: false,
        };

    default:
        return state;
    }
};

export default globalReducer;
