import {
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_FULFILLED,
    GET_USER_DETAILS_LOADING,
    GET_USER_DETAILS_RESET,
    UPDATE_USER_DETAILS_FAILED,
    UPDATE_USER_DETAILS_FULFILLED,
    UPDATE_USER_DETAILS_LOADING,
    UPDATE_USER_DETAILS_RESET,
    UPLOAD_PROFILE_PICTURE_FAILED,
    UPLOAD_PROFILE_PICTURE_FULFILLED,
    UPLOAD_PROFILE_PICTURE_LOADING,
    UPLOAD_PROFILE_PICTURE_RESET,
    USER_LOGOUT_FAILED,
    USER_LOGOUT_FULFILLED,
    USER_LOGOUT_LOADING,
} from '../types';

import {hokPanelAxios} from '../../utils/customAxios';
import {hasFirebaseTokenExpired, isApiResponseSuccess, rerunAction} from '../../utils/commonUtils';
import {panelApiBasePath} from '../../constants/apiConstants';

export const getUserDetails = (req = {}) =>
    async dispatch => {
        dispatch({type: GET_USER_DETAILS_LOADING});
        hokPanelAxios.get(panelApiBasePath + 'users/me').then((response) => {
            if (isApiResponseSuccess(response)) {
                dispatch({
                    type: GET_USER_DETAILS_FULFILLED,
                    payload: response?.data?.result || {}
                });
            } else {
                if (hasFirebaseTokenExpired(response)) {
                    rerunAction(dispatch, getUserDetails, req);
                } else {
                    dispatch({type: GET_USER_DETAILS_FAILED, payload: response?.data});
                }
            }
        }).catch(async (err) => {
            if (hasFirebaseTokenExpired(err?.response))
                rerunAction(dispatch, getUserDetails, req);
            else
                dispatch({type: GET_USER_DETAILS_FAILED, payload: err?.response?.data || err});
        });
    };

export const resetUserDetails = () =>
    async dispatch => {
        dispatch({type: GET_USER_DETAILS_RESET});
    };

export const updateUserDetails = (req = {}) =>
    async dispatch => {
        dispatch({type: UPDATE_USER_DETAILS_LOADING});
        hokPanelAxios.patch(panelApiBasePath + 'users/me', req).then((response) => {
            if (isApiResponseSuccess(response)) {
                dispatch({
                    type: UPDATE_USER_DETAILS_FULFILLED,
                    payload: response?.data?.result || {}
                });
            } else {
                if (hasFirebaseTokenExpired(response)) {
                    rerunAction(dispatch, updateUserDetails, req);
                } else {
                    dispatch({type: UPDATE_USER_DETAILS_FAILED, payload: response?.data});
                }
            }
        }).catch(async (err) => {
            if (hasFirebaseTokenExpired(err?.response))
                rerunAction(dispatch, updateUserDetails, req);
            else
                dispatch({type: UPDATE_USER_DETAILS_FAILED, payload: err?.response?.data || err});
        });
    };

export const updateUserDetailsReset = () =>
    async dispatch => {
        dispatch({type: UPDATE_USER_DETAILS_RESET});
    };

export const userLogout = () =>
    async dispatch => {
        dispatch({type: USER_LOGOUT_LOADING});
        hokPanelAxios.post(panelApiBasePath + 'auth/logout').then((response) => {
            if (isApiResponseSuccess(response)) {
                dispatch({
                    type: USER_LOGOUT_FULFILLED,
                });
            } else {
                if (hasFirebaseTokenExpired(response)) {
                    rerunAction(dispatch, userLogout, {});
                } else {
                    dispatch({type: USER_LOGOUT_FAILED, payload: response?.data});
                }
            }
        }).catch(async (err) => {
            if (hasFirebaseTokenExpired(err?.response))
                rerunAction(dispatch, userLogout, {});
            else
                dispatch({type: USER_LOGOUT_FAILED, payload: err?.response?.data || err});
        });
    };


export const uploadProfilePic = (req = {}) =>
    async dispatch => {
        dispatch({type: UPLOAD_PROFILE_PICTURE_LOADING});
        hokPanelAxios.post(panelApiBasePath + 'users/uploadImage', req).then((response) => {
            if (isApiResponseSuccess(response)) {
                dispatch({
                    type: UPLOAD_PROFILE_PICTURE_FULFILLED,
                    payload: response?.data?.result || {}
                });
            } else {
                if (hasFirebaseTokenExpired(response)) {
                    rerunAction(dispatch, uploadProfilePic, req);
                } else {
                    dispatch({type: UPLOAD_PROFILE_PICTURE_FAILED, payload: response?.data});
                }
            }
        }).catch(async (err) => {
            if (hasFirebaseTokenExpired(err?.response))
                rerunAction(dispatch, uploadProfilePic, req);
            else
                dispatch({type: UPLOAD_PROFILE_PICTURE_FAILED, payload: err?.response?.data || err});
        });
    };

export const uploadProfilePicReset = () =>
    async dispatch => {
        dispatch({type: UPLOAD_PROFILE_PICTURE_RESET});
    };