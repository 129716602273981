// Libraries
import React from 'react';

// Styles
import classes from './Loader.module.scss';

// Components
import FeedImage from '../components/element-builder/FeedImage';

export default function Loader() {
    return (
        <div className={classes.loaderWrapper}>
            <FeedImage feedMedia={{url: '/assets/images/fampay-logo.json'}} style={{width: 150, height: 150}}/>
        </div>
    );
}